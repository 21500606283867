<template>
  <modal name="PopupFilePreview" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :width="900" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">{{ file.name }}.{{ extension }}</span>

      <a :href="file.url" download class="btn btn-destruct" v-if="file.stageId && !file.isActual"><i class="fad fa-download"></i> Скачать устаревший файл</a>
      <a :href="file.url" download class="btn" v-else><i class="fad fa-download"></i> Скачать</a>

      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    
    <div class="content block-vcenter txt-c preview image" style="min-height: 20em" v-if="['jpg', 'jpeg', 'png', 'svg', 'bmp', 'gif', 'webp'].indexOf(extension) !== -1">
      <vue-element-loading :active="isLoading"/>
      <img style="max-height: 80vh;" :src="file.url" alt="" v-show="!isLoading" @load="isLoading=false"/>
    </div>

    <div class="content preview pdf" style="min-height: 20em" v-else-if="['pdf'].indexOf(extension) !== -1">
      <vue-element-loading :active="isLoading"/>
      <vue-pdf-embed :source="file.url" @rendered="isLoading=false" />
    </div>

    <div class="content block-vcenter txt-c preview unknown" style="min-height: 20em" v-else>
        Предпросмотр недоступен
    </div>

  </modal>
</template>

<style scoped>
.preview {
  width: 100%;
  height: 100%;
}

.btn.btn-destruct {
  color: #FFF;
}
</style>

<script>
import VueElementLoading from 'vue-element-loading'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'


export default {
  name: 'PopupFilePreview',
  components: {
    VueElementLoading,
    VuePdfEmbed
  },
  data() {
    return {
      isLoading: false,
      file: {
        name: '',
        url: ''
      }
    }
  },
  computed: {
    extension() {
        let parts = this.file.url.split('.')
        return parts[parts.length-1].toLowerCase()
    }
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.isLoading = true
      this.file = data.params

      this.$escStack.push(this.$options.name)
    },
	}
}
</script>