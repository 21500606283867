<template>
<div class="profile pure-g">
	<div class="pure-u-1-5 block-vcenter txt-c" @click.stop="$modal.show('PopupEditProfile', authId)">
		<avatar :userid="user.id" v-if="user && user.id" />
	</div>
	<div class="pure-u-4-5 padding-l">
		<a class="name" @click.stop="$modal.show('PopupEditProfile', authId)" v-if="user && user.id">{{ user.fullName() }}</a>
		<a class="name" href="#" v-else>Мой аккаунт</a>

		<popup-edit-roles v-if="manageRolesAllowed" />
		<div class="actions flex-row">
			<router-link class="btn flex-expand" :to="{name: 'Notifications'}" style="visibility: hidden">
				<i class="fad fa-bell"></i>
			</router-link>
			<!-- <router-link class="btn flex-expand" :to="{name: 'Notifications'}" v-if="manageUsersAllowed">
				<i class="fad fa-users"></i>
			</router-link> -->
			<a @click.stop="$modal.show('PopupEditRoles')" class="btn flex-expand" v-if="manageRolesAllowed" title="Изменение ролей пользователей">
				<i class="fad fa-list"></i>
			</a>
			<a @click.stop="$modal.show('PopupEditProfile', authId)" class="btn flex-expand"><i class="fad fa-id-card"></i></a>
		</div>
	</div>
	<vue-element-loading :active="!user || !user.id"/>
</div>
</template>

<style scoped>
	.profile a, .profile a:active, .profile a:visited {
		text-decoration: none;
		background: none;
	}
	.profile a.name {
		padding: .25rem;
		font-size: 1.1rem;
		color: #222;
	}
	.profile .actions a {
		display: inline-block;
		margin: .25rem;
		font-size: 1.1rem;
		padding: .15rem .5rem;
	}
</style>

<script>
import VueElementLoading from 'vue-element-loading'

import { mapGetters } from "vuex";
import { USERS_REQUEST } from "@/store/actions/users";
import Avatar from '@/components/Avatar.vue';
import PopupEditRoles from '@/components/PopupEditRoles.vue'
import {
    MANAGE_ROLES, MANAGE_USERS
} from "@/utils/permissions"

export default {
	name: 'AsideProfile',
	components: {
		VueElementLoading,
		PopupEditRoles,
		Avatar
	},
	computed: {
		//...mapGetters(["authId", "myProfile", "myProfileName", "myProfileColor", "myProfileAbbreviation", "myProfileState"]),
		...mapGetters(["authId", "authRoles", "allUsersKeyed"]),
		user() {
			return this.allUsersKeyed[this.authId]
		},
		manageRolesAllowed() {
			return this.authRoles.indexOf(MANAGE_ROLES) !== -1
		},
		manageUsersAllowed() {
			return this.authRoles.indexOf(MANAGE_USERS) !== -1
		},
	},
	mounted() {
		this.$store.dispatch(USERS_REQUEST)
	}
}
</script>