<template>
  <div class="flex-row user" @click.stop="openAboutUser(user)">
    <avatar :userid="id" />
    <div class="flex-expand txt-l padding-h block-vcenter" v-if="!short">
      <span>
        <b class="block no-wrap name">
          <span v-if="user.specialization" class="specialization" v-bind:style="{
            'background-color': user.bgColor(),
            'color': getTextColor(user.bgColor())
          }">{{ user.specialization.name }}</span>
          {{ user.fullName() }}
        </b>

        <span class="is-online online" v-if="user.id == authId || user.isOnline()">online</span>
        <span class="is-online" v-else>{{ offlineBeauty(user) }}</span>
      </span>
    </div>
    <button class="btn btn-transparent" @click="openAboutUser(user)" v-if="!short"><i class="fad fa-id-card"></i></button>
  </div>
</template>

<style scoped>
.is-online {
  color: #CCC;
}

.is-online.online {
  color: #509E2F;
}

.specialization {
  display: inline-block;
  position: relative;
  top: -.1em;
  font-size: .85em;
  padding: 0rem .5rem;
  margin-right: .25rem;
  border-radius: .5em;
  color: #FFF;
}

.user {
  padding: .5em 0;
  /* cursor: pointer; */

  /* transition: all .2s ease-in-out; */
}

.user:hover {
  /* background-color: #F1F2F3;*/
}

.user .name {
  font-size: .9em;
}

.btn.btn-transparent {
  min-width: 3rem;
}

.btn.btn-transparent i {
  color: #777;
  font-size: 1rem;
}

.btn.btn-transparent:hover i {
  color: #509E2F;
}
</style>

<script>
import { mapGetters } from "vuex";

import textColor from '@/utils/textColor'

import Avatar from '@/components/Avatar.vue';


export default {
  name: 'UserEntry',
  props: ['id', 'short'],
  components: {
    Avatar
  },
  data() {
    return {
      showAside: true
    }
  },
  computed: {
    ...mapGetters(["authId", "allUsersKeyed"]),
    user() { return this.allUsersKeyed[this.id] }
  },
  methods: {
    getTextColor(color) {
      return textColor(color)
    },
    offlineBeauty(user) {
      var lastvisit = new Date(user.lastVisitAt)
      if (!lastvisit || !lastvisit.getTime() || lastvisit.getFullYear() <= 2001) {
        return 'offline'
      }
      let prefix = user.gender == 102 ? 'была ' : 'был '
      return (prefix + this.$moment.utc(user.lastVisitAt).local().calendar()).toLowerCase()
    },
    openAboutUser(user) {
      document.body.click(); // скрываем все открытые tippy
      this.$modal.show('PopupAboutProfile', user.id)
    }
  }
}
</script>