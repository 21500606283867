<template>
  <modal name="PopupEditTask" @before-open="beforeOpen" @opened="opened" @before-close="beforeClose" draggable=".content" :adaptive="true" :width="550" :scrollable="true" height="auto" :shiftX="shiftX" :shiftY="shiftY">
    <button class="btn btn-small btn-transparent pull-right" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    <form class="content no-header with-overflow padding" @submit.prevent="saveTask" ref="form">
      <div class="pure-g">
        <vue-element-loading :active="isUpdatingTask"/>
        <div class="pure-u-1 form-row txt-l" style="width: 500px">
          <label>Название задачи</label>
          <input required v-model="task.name" type="text" placeholder="Название задачи" :readonly="!isEditTaskAllowed"/>
        </div>
          
        <div class="pure-u-1 form-row txt-l" v-show="isEditTaskAllowed">
          <label class="block-vcenter">
          <input v-model="task.isOutsource" type="checkbox" class="margin-half-r"/>
          Выполняется по стороннему договору
          </label>
          <input v-if="task.isOutsource" v-model="task.outsourceText" type="text" placeholder="Сторонний договор"/>
        </div>
          
        <div class="pure-u-1 form-row txt-l">
          <label><i class="fad fa-question text-green"></i> Описание</label>
          <textarea v-model="task.description" rows="5" placeholder="Описание задачи" :readonly="!isEditTaskAllowed"></textarea>
        </div>
        <div class="pure-u-1 form-row txt-l">
          <vue-element-loading :active="isDefaultFilesLoading"/>
          <div class="form-row margin-b padding-b">
            <stage-files-uploader
            :upload-url="uploadUrl"
            ref="descriptional_files_uploader" 
            input-name="descriptional_files"
            :multiple="true"
            :value="descriptionalFiles"
            v-on:input="task.descriptionalFiles = $event" 
            :headers="authHeaders"
            title="Прилагаемые файлы"
            background="#888888"
            foreground="#FFFFFF" 
            v-on:filesChanged="onDescriptionalFilesChanged"
            v-on:isUploading="descriptionalFilesUploadStatus"/>
          </div>
        </div>

        <div class="pure-u-17-24 form-row txt-l">
          <button v-if="isLinked" type="button" class="btn btn-destruct" @click="removeTaskLink"><i class="fal fa-unlink"></i> Удалить связь</button>
          <template v-else>
            <button v-if="task.isDeleted" type="button" class="btn btn-warning"  @click="restoreTask"><i class="fal fa-redo"></i> Восстановить</button>
            <button v-else-if="isRemoveTaskAlowed" type="button" class="btn btn-destruct" @click="removeTask"><i class="fal fa-trash-alt"></i> Удалить</button>

            <button v-show="canBeUnassigned" type="button" class="btn margin-half-l" @click="unassignTask"><i class="fal fa-trash-alt"></i> В нераспределенные</button>
          </template>
          
        </div>
        <div class="pure-u-7-24 form-row txt-r">
          <button type="submit" class="btn btn-primary" :disabled="isDefaultFilesLoading" v-show="isEditTaskAllowed"><i class="fal fa-check"></i> Сохранить</button>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import { mapGetters } from "vuex"
import VueElementLoading from 'vue-element-loading'

import { apiCall, apiAuthHeaders, API_PREFIX } from "@/utils/api"
import {
  FILE_TYPE_DESCRIPTIONAL
} from "@/utils/const"
import {
  DEL_TASK_ROOT, DEL_TASK_SPECIAL, EDIT_TASK_LAST_ASSIGNED, EDIT_TASK_ANY
} from "@/utils/permissions"
import { TASK_PENDING_DELETED } from "@/store/actions/project"
import StageFilesUploader from '@/components/StageFilesUploader.vue'


export default {
  name: 'PopupEditTask',
  components: {
    VueElementLoading,
    StageFilesUploader
  },
  data() {
    return {
      task: {},
      group: {},
      cells: [],
      isLinked: false,
      canBeUnassigned: false,
      linksGroup: null,
      contextRef: null,
      isUpdatingTask: false,
      isDefaultFilesLoading: false,
      isDescriptionalFilesUploading: false,
      isAllDescriptionalFilesChecked: true,
      descriptionalFiles: [],
      popupHeight: 332
    }
  },
  computed: {
    ...mapGetters(["authId", "authRoles", "allUsersKeyed", "customerRoles"]),
    uploadUrl() {return API_PREFIX + 'stages/upload/descriptioanal'},
    authHeaders() {return apiAuthHeaders()},
    user() {
			return this.allUsersKeyed[this.authId]
		},
    shiftX() {
      if (!this.contextRef) {
        return 0.5
      }
      let rect = this.contextRef.getBoundingClientRect()
      return rect.left/window.innerWidth
    },
    shiftY() {
      if (!this.contextRef) {
        return 0.5
      }
      let screenHeight = window.innerHeight
      let rect = this.contextRef.getBoundingClientRect()
      if (rect.bottom + this.popupHeight > screenHeight) {
        return (rect.top - this.popupHeight/2)/screenHeight
      } else {
        return (rect.top + this.popupHeight/2)/screenHeight
      }
    },
    isEditTaskAllowed() {
      if (!this.task.id || (this.task.createdById && this.task.createdById == this.user.id)) {
        return true
      }
      if (this.authRoles.indexOf(EDIT_TASK_ANY) !== -1) {
        return true
      }
      if (this.user && this.user.isCustomer) {
        return false
      }
      if (this.authRoles.indexOf(EDIT_TASK_LAST_ASSIGNED) !== -1 && this.cells.length) {
        let cellIndex = this.cells.length-1
        if (this.cells[cellIndex].column.rolesAssignable.some(r => this.customerRoles.includes(r))) {
          cellIndex--
        }
        return this.cells[cellIndex].assignedIds.indexOf(this.authId) !== -1
      }
      return false
    },
    isRemoveTaskAlowed() {
      if (this.authRoles.indexOf(DEL_TASK_ROOT) !== -1 && !this.task.parentTaskId) {
        return true
      } else if (this.authRoles.indexOf(DEL_TASK_SPECIAL) !== -1 && this.task.parentTaskId) {
        return true
      }
      return false
    },
  },
  methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.$set(this, 'task', data.params.task)
      this.$set(this, 'group', data.params.group)
      this.cells = data.params.cells
      this.$set(this, 'isLinked', data.params.isLinked)
      this.$set(this, 'linksGroup', data.params.linksGroup)
      this.$set(this, 'contextRef', data.params.contextRef)
      this.$escStack.push(this.$options.name)
      
      this.descriptionalFiles = []
      this.isAllDescriptionalFilesChecked = true
      this.loadDefaultFiles()

      this.canBeUnassigned = false
      if (this.task.id && this.task.parentTaskId) {
        apiCall({ url: "stages/" + this.task.id + "/has-moves" , method: "GET"})
          .then( (resp) => this.canBeUnassigned = !resp.moved )
      }
    },
    opened() {
      this.popupHeight = this.$refs.form.getBoundingClientRect().height
    },
    loadDefaultFiles() {
      if (!this.task.id) {
        return
      }
      this.isDefaultFilesLoading = true;
      apiCall({ url: 'tasks/' + this.task.id + '/files/' + FILE_TYPE_DESCRIPTIONAL, method: "GET"})
			.then(resp => {
        this.descriptionalFiles = resp
				this.isDefaultFilesLoading = false
			})
    },
    descriptionalFilesUploadStatus(isUploading) {
      this.isDescriptionalFilesUploading = isUploading
    },
    onDescriptionalFilesChanged(data) {
      this.isAllDescriptionalFilesChecked = data.allChecked
    },
    saveTask() {
      if (this.isDefaultFilesLoading) {
        return
      }

      this.isUpdatingTask = true
      let action = this.task.id ? "tasks/edit/"+this.task.id : "tasks/add"
      apiCall({ url: action, method: "POST", data: this.task})
        .then( (/*resp*/) => {
          this.isUpdatingTask = false
          this.$modal.hide(this.$options.name)
        })
    },
    removeTask() {
      if (this.task.id) {
        apiCall({ url: "tasks/remove/"+this.task.id, method: "POST"})
      } else {  // pending task (only local)
        this.$store.dispatch(TASK_PENDING_DELETED, {group: this.group, task: this.task})
      }
      this.$modal.hide(this.$options.name)
    },
    unassignTask() {
      if (!confirm("Задача будет перенесена в нераспределенные. История задачи сохранится, но связи с ячейками могут быть утеряны. Вы уверены? Отменить это действие будет нельзя.")) {
        return
      }
      if (this.task.id) {
        apiCall({ url: "tasks/unassign/"+this.task.id, method: "POST"})
      }
      this.$modal.hide(this.$options.name)
    },
    removeTaskLink() {
      apiCall({ url: "tasks/" + this.linksGroup.parentTaskId + "/depends-on/" + this.task.id + "/remove", method: "POST"})
      this.$modal.hide(this.$options.name)
    },
    restoreTask() {
      apiCall({ url: "tasks/restore/"+this.task.id, method: "POST"})
      this.$modal.hide(this.$options.name)
    },
    
  }
}
</script>
