<template>
  <div class="pure-g container padding-v">
    <div class="pure-u-1-5">
      <div class="header flex-row">
        <button @click="$modal.show('PopupEditGallery', {projectId: projectId, date: null, name: '', description: ''})" class="flex-expand btn btn-good" v-if="addGalleryAllowed">
          <i class="fal fa-plus"></i>
          Добавить папку
        </button>
      </div>
      <div class="galleries">
        <vue-element-loading :active="isGalleriesLoading"/>
        <a href="javascript:" class="gallery" @click.stop="selectGallery(g)" v-for="g in galleries" :key="g.id" v-bind:class="{'active': gallery.id == g.id}">
          <i class="fad" v-bind:class="{
            'fa-folder': !gallery.id || gallery.id != g.id,
            'fa-folder-open': gallery.id == g.id,
          }"></i>
          {{ g.name }}
          <span class="date" v-if="g.date">
            {{ $moment.utc(g.date).local().format('YYYY-MM-DD') }}
          </span>
        </a>
      </div>
    </div>
    <div class="pure-u-4-5 gallery" v-if="gallery.id">
      <div class="header flex-row">
        <file-upload
          class="btn btn-good"
          name="file"
          :post-action="uploadUrl"
          :multiple="true"
          :drop="true"
          :thread="3"
          :headers="authHeaders"
          accept="image/png,image/gif,image/jpeg,image/bmp"
          input-id="gallery_upload"
          v-model="uploadingImgs"
          v-if="addGalleryPhotoAllowed"
          ref="upload"
          @input-filter="inputFile">
          <i class="fal fa-upload"></i> Добавить фотографии
        </file-upload>
        <span class="flex-expand"></span>
        <button @click="$modal.show('PopupEditGallery', gallery)" class="btn btn-good" v-if="editGalleryAllowed">
          <i class="fal fa-cog"></i>
          Редактировать галерею
        </button>
      </div>

      <h1>{{ gallery.name }}</h1>
      <div class="padding-v nl2br" v-show="gallery.description">{{ gallery.description }}</div>

      <vue-gallery :items="galleryImages" :origin="origin" @close="origin = null">
        <template v-slot:toolbar>
          <a href="javascript:" class="control button close is-primary bigger" style="color: #EEE; display: inlin-block; padding: .5em">
            <i class="fal fa-times"></i>
          </a>
        </template>
      </vue-gallery>
      
      <div class="pure-g container">
        <div
          class="pure-u-1 pure-u-sm-1-2 pure-u-md-1-3 pure-u-lg-1-4 image margin-b"
          v-for="(image, index) in images"
          :key="index">
          <div
            class="photo-cover"
            @click="origin = index"
            style="padding-top: 75%"
            v-bind:style="{ backgroundImage: 'url(' + image.thumbUrl + ')' }">
            
            <span class="flex-row bottom">
              <span class="flex-expand block-vcenter name">{{ image.name }}</span>
              
              <button v-if="editGalleryImageAllowed(image)" @click.stop="removeImage(image, index)" class="btn"><i class="fas fa-trash-alt"></i></button>

              <button v-if="editGalleryImageAllowed(image)" @click.stop="$modal.show('PopupEditGalleryImage', image)" class="btn"><i class="fas fa-pencil"></i></button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.header {
  background-color: #EEE;
}
.header .btn {
  border-radius: 0;
  border: none
}

.galleries a, .galleries a:active, .galleries a.visited {
  display: block;
  text-decoration: none;
  padding: .5em 1em;
  color: #777;
  transition: all .2s ease-in-out;
}
.galleries a:hover {
  color: #000;
  background-color: #F1F2F3;
}
.galleries a.active {
  color: #FFF;
  background-color: #509E2F;
}
.galleries a .date {
  display: block;
  font-style: italic;
  font-size: .8em;
}

.gallery h1 {
  font-weight: 500;
  margin: .5rem;
}

.image .photo-cover {
  position: relative;
  cursor: pointer;
}
.image .photo-cover .bottom {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.15);
  color: #FFF;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 2rem;
}
.image .photo-cover .bottom .name {
  font-size: .75em;
  margin: 0 .5em
}
.image .photo-cover .bottom .btn {
  border-radius: 0;
  border: none;
  padding: .2em .75em;
  color: #EEE;
  background-color: rgba(0, 0, 0, 0.2);
}
.image .photo-cover .bottom .btn:hover {
  color: #FFF;
}
</style>

<script>
import "vue-blueimp-gallery/dist/vue-blueimp-gallery.css";
import VueGallery from "vue-blueimp-gallery";

import VueElementLoading from 'vue-element-loading'
import FileUpload from 'vue-upload-component'

import { mapGetters } from "vuex"
import { apiCall, apiAuthHeaders, API_PREFIX } from "@/utils/api"
import { ADD_GALLERY, EDIT_GALLERY, ADD_GALLERY_PHOTO, EDIT_GALLERY_PHOTO_OWN, EDIT_GALLERY_PHOTO_ANY } from "@/utils/permissions"

export default {
  name: 'Gallery',
  components: {
    FileUpload,
    VueGallery,
    VueElementLoading,
  },
  props: ['projectId'],
  watch: {
    projectId() {
      this.loadGalleries()
    },
    galleriesCount() {
      this.loadGalleries()
    }
  },
  data() {
    return {
      isGalleriesLoading: false,
      isGalleryPhotosLoading: false,
      isUploading: false,
      galleries: [],
      images: [],
      origin: null,
      uploadingImgs: [],
      gallery: {
        id: 0,
        name: "",
        description: "",
        date: ""
      }
    }
  },
  computed: {
    ...mapGetters(["authId", "authRoles", "galleriesCount"]),
    authHeaders() { return apiAuthHeaders() },
    uploadUrl() {
      return API_PREFIX + 'galleries/upload/' + this.gallery.id
    },
    galleryImages() {
      return this.images.map(i => ({
        'id': i.id,
        'href': i.url,
        'thumbnail': i.thumbUrl,
        'w': i.width,
        'h': i.height,
        'name': i.name,
        'description': "<b>" + i.name + "</b>\n" + i.description
      }))
    },
    addGalleryAllowed() {
      return this.authRoles.indexOf(ADD_GALLERY) !== -1
    },
    editGalleryAllowed() {
      return this.authRoles.indexOf(EDIT_GALLERY) !== -1
    },
    addGalleryPhotoAllowed() {
      return this.authRoles.indexOf(ADD_GALLERY_PHOTO) !== -1
    },
  },
  mounted() {
    if (this.projectId) {
      this.loadGalleries()
    }
    if (this.gallery) {
      this.loadImages()
    }
  },
  methods: {
    loadGalleries() {
      this.isGalleriesLoading = true
      let action = "projects/" + this.projectId + "/galleries"
      apiCall({ url: action, method: "GET"})
      .then((resp) => {
        this.galleries = resp
        this.isGalleriesLoading = false
      })
    },
    loadImages() {
      this.isGalleryPhotosLoading = true
      let action = "projects/" + this.projectId + "/galleries/" + this.gallery.id
      apiCall({ url: action, method: "GET"})
      .then((resp) => {
        this.images = resp
        this.isGalleryPhotosLoading = false
      })
    },
    selectGallery(gallery) {
      this.gallery = gallery
      this.loadImages()
    },
    removeImage: function(image, index) {
      if (!confirm("Действительно безвозвратно удалить выбранную фотографию? " + image.name)) {
        return
      }
      let action = "projects/" + image.projectId + "/galleries/image/" + image.id + "/remove"
      apiCall({ url: action, method: "POST"})
      this.$nextTick(() => {
        this.images.splice(index, 1)
      })
    },

    startUpload(file) {
      this.isUploading = true
      this.$refs.upload.update(file, {active: true})
    },
    // обновление статуса загружаемого файла
    inputFile(newFile, oldFile/*, prevent*/) {
      if (newFile && !oldFile) { // file added
        console.log(newFile)
        this.$nextTick(() => this.startUpload(newFile))
      }

      if (newFile && oldFile) {  // file updated
        if (newFile.success !== oldFile.success) {  // Uploaded successfully
          this.images.unshift(newFile.response)
          this.isUploading = this.uploadingImgs.filter(f => !f.success).length > 0
        }
      }
    },
    editGalleryImageAllowed(image) {
      if (this.authRoles.indexOf(EDIT_GALLERY_PHOTO_ANY) !== -1) {
        return true
      }
      if (this.authRoles.indexOf(EDIT_GALLERY_PHOTO_OWN) !== -1 && image.profileId == this.authId) {
        return true
      }
      return false
    }
  }
}
</script>