<template>
  <modal name="PopupAddStage" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :width="900" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">{{ task.visibleId }}: {{ task.name }} <i v-if="column" class="smaller">{{ column.name }}</i></span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <form class="content padding-h padding-half-v" @submit.prevent="onSubmit">
      <vue-element-loading :active="isLoading"/>
      <div class="pure-g container">
        <div class="pure-u-1 pure-u-md-2-3">
          <div class="pure-g container" v-if="task.groupId">
            <div class="pure-u-1 pure-u-sm-2-3 pure-u-md-1-2 pure-u-lg-11-24 form-row">
              <label>Обновить прогресс, %</label>
              <vue-slider class="margin-half-h" v-model="stage.progressAfter" v-on:input="checkErrors" :min="0" :max="100" :interval="5" :marks="[0,10,20,30,40,50,60,70,80,90,100]"></vue-slider>
            </div>
            <div class="pure-u-1 pure-u-sm-1-3 pure-u-md-1-2 pure-u-lg-7-24 form-row" style="visibility: hidden">
              <label>Учесть затраченное время</label>
              <hours-picker v-model="stage.hoursAdded" v-on:input="checkErrors" />
            </div>
            <div class="pure-u-1 pure-u-sm-1-3 pure-u-md-1-2 pure-u-lg-6-24 form-row">
              <label>Изменить срок</label>
              <button
                class="btn btn-reset pull-right"
                type="button"
                @click="stage.deadlineAfter = stage.deadlineBefore"
                v-show="isDeadlineChanged"
                title="Отменить изменение срока">
                <i class="fal fa-undo"></i>
              </button>
              <v-date-picker v-model="stage.deadlineAfter" 
              :min-date='new Date()'
              v-on:input="checkErrors">
                <template v-slot="{ inputValue, inputEvents }">
                  <input type="text" placeholder="Не задан" :value="inputValue" v-on="inputEvents" />
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="form-row">
            <textarea rows="3" v-model="stage.description" placeholder="Напишите, пожалуйста, сопроводительное письмо" @keyup="checkErrors"></textarea>
          </div>

          <div class="files-block margin-v">
            <vue-element-loading :active="isDefaultFilesLoading"/>
            <div class="form-row margin-b padding-b">
              <stage-files-uploader
              :upload-url="uploadUrl"
              ref="informational_files_uploader" 
              input-name="informational_files"
              :multiple="true"
              :value="informationalFiles"
              v-on:input="stage.informationalFiles = $event" 
              :headers="authHeaders"
              title="Информационные файлы"
              background="#888888"
              foreground="#FFFFFF" 
              v-on:filesChanged="onInformationalFilesChanged"
              v-on:isUploading="informationalFilesUploadStatus"/>
            </div>
            <div class="form-row margin-b padding-b">
              <stage-files-uploader
              :upload-url="user.isCustomer && cellIndex != 0 ? '' : uploadUrl"
              ref="working_files_uploader" 
              input-name="working_files"
              :multiple="true"
              :value="workingFiles"
              v-on:input="stage.workingFiles = $event" 
              :headers="authHeaders"
              title="Рабочие файлы"
              background="#2D70BD"
              foreground="#FFFFFF" 
              v-on:filesChanged="onWorkingFilesChanged"
              v-on:isUploading="workingFilesUploadStatus"/>
            </div>
            <div class="form-row">
              <stage-files-uploader
                :upload-url="user.isCustomer && cellIndex != 0 ? '' : uploadUrl"
                ref="final_files_uploader"
                input-name="final_files"
                accept="application/pdf"
                :multiple="false"
                :value="finalFiles"
                v-on:input="stage.finalFiles = $event"
                :headers="authHeaders"
                title="Итоговые файлы"
                background="#509E2F"
                foreground="#FFFFFF"
                v-on:filesChanged="onFinalFilesChanged"
                v-on:isUploading="finalFilesUploadStatus"/>
            </div>
          </div>
          <div class="margin-b">
            <label>Дополнительно уведомить</label>
            <users-picker v-model="stage.notifyIds" position="top" :maxHeight="250"/>
          </div>
          <div class="form-row">
            <label>Чеклист по задаче</label>
            <div class="flex-row check-list-entry" v-for="(elem, index) in stage.checkListAfter" :key="elem.id">
              <input type="checkbox" v-model="elem.completed" class="margin-half-r" />
              <span>{{ elem.number }}</span>
              <input v-show="checkListEntryEditing === elem.id" ref="editInput" class="flex-expand" type="text" v-model="elem.description" @blur="checkListEntryEditing = null"/>
              <span v-show="checkListEntryEditing != elem.id" @click="startCheckListEdit(index)" class="flex-expand">{{ elem.description }}</span>
              <button type="button" class="btn btn-destruct" @click="stage.checkListAfter.splice(index, 1)"><i class="fal fa-trash-alt"></i></button>
            </div>
            <button class="btn btn-checklist-new full-width form-row" type="button" @click="stage.checkListAfter.push(newCheckListEntry()); $nextTick(() => {startCheckListEdit(stage.checkListAfter.length - 1)})">Новый пункт чеклиста</button>
          </div>
        </div>

        <div class="pure-u-1 pure-u-md-1-3" style="display: flex; flex-direction: column;">
          <button @click="stage.statusAfter=stage.statusBefore; stage.direction=0" type="submit" class="btn btn-orange full-width form-row" v-bind:class="{
            'disabled': errors['hod_rabot'].length
          }">
          <span v-if="canUpdateDeadlineWithoutRequest && isDeadlineChanged">Изменить срок</span>
          <span v-else-if="isDeadlineChanged">Запросить изменение срока</span>
          <span v-else>Обновить ход работ</span>
        </button>
          
          <button @click="stage.statusAfter=20; stage.direction=0" type="submit" class="btn btn-warning full-width form-row" v-if="cellIndex >= 0 && task.groupId && cell.status != 20" v-bind:class="{
            'disabled': errors['start'].length
          }">
            <span v-if="cellIndex == 0">В работе</span>
            <span v-else>На рассмотрении</span>
          </button>

          <button @click="stage.statusAfter=-50; stage.direction=0" type="submit" class="btn btn-destruct full-width form-row" v-if="cellIndex >= 0 && task.groupId && cell.status == 20" v-bind:class="{
            'disabled': errors['stop'].length
          }">Работа приостановлена</button>
          
          <button @click="stage.statusAfter=90; stage.direction=(isNextCellCustomers ? 0 : 20)" type="submit" class="btn btn-primary full-width form-row" v-if="cellsToIdAllowed.length <= 1 && cellIndex >= 0 && task.groupId" v-bind:class="{
            'disabled': errors['success'].length
          }">
            <span v-if="cellIndex == 0">Отправить документацию</span>
            <span v-else>Принято без замечаний</span>
          </button>
          <div class="flex-row btn-group" v-else-if="cellIndex >= 0 && task.groupId">
            <button @click="stage.statusAfter=90; stage.direction=(isNextCellCustomers ? 0 : 20)" type="submit" class="btn btn-primary flex-expand form-row" v-bind:class="{
              'disabled': errors['success'].length
            }">
              <span v-if="cellIndex == 0">Отправить документацию</span>
              <span v-else>Принято без замечаний</span>
            </button>

            <tippy :interactive="true" placement="bottom-end" trigger="click" theme="light sodnp">
              <template v-slot:trigger>
                <button type="button" class="btn btn-primary last form-row" v-bind:class="{
                  'disabled': errors['success'].length
                }">
                  <i class="far fa-angle-down"></i>
                </button>
              </template>

              <span class="block">Передать напрямую на</span>
              <button v-for="c in cellsToIdAllowed" :key="'move_to_' + c.id" @click="stage.statusAfter=90; stage.direction=20; stage.cellToId = c.id; onSubmit()" type="button" class="btn btn-primary full-width form-row" v-bind:class="{
                'disabled': errors['success'].length
              }">
                {{ c.column.name }}
              </button>
            </tippy>
            
          </div>

          <button @click="stage.statusAfter=-50; stage.direction=-20" type="submit" class="btn btn-destruct full-width form-row" v-if="cellIndex > 0 && (isCurrentCellCustomers || (task.groupId && cellIndex <= 1))" v-bind:class="{
            'disabled': errors['fail'].length
          }">Вернуть на доработку</button>
          <div class="flex-row btn-group" v-else-if="cellIndex >= 2 && task.groupId">
            <button @click="stage.statusAfter=-50; stage.direction=-20" type="submit" class="btn btn-destruct flex-expand form-row" v-bind:class="{
              'disabled': errors['fail'].length
            }">
              Вернуть на доработку
            </button>

            <tippy :interactive="true" placement="bottom-end" trigger="click" theme="light sodnp" v-if="!user.isCustomer">
              <template v-slot:trigger>
                <button type="button" class="btn btn-destruct last form-row" v-bind:class="{
                  'disabled': errors['fail'].length
                }">
                  <i class="far fa-angle-down"></i>
                </button>
              </template>

              <button @click="stage.statusAfter=-50; stage.direction=-20; stage.cellToId = task.cells[0].id; onSubmit()" type="button" class="btn btn-destruct full-width form-row" v-bind:class="{
                'disabled': errors['fail'].length
              }" style="min-width: 250px">
                Вернуть в начало
              </button>
            </tippy>
            
          </div>



          <div class="errors" v-show="showErrorsKey && errors[showErrorsKey]" v-bind:class="{
            'scale-up-down-once': blinkErrors
          }">
            <b class="block margin-v smaller text-red" v-for="(e, index) in errors[showErrorsKey]" :key="index + 'error'">{{ e }}</b>
          </div>

          <div style="flex: 1 1 auto;"></div>

          <button @click="stage.statusAfter=90; stage.direction=20" type="submit" class="btn btn-primary full-width form-row"
          v-if="task.groupId && isNextCellCustomers"
          v-bind:class="{
            'disabled': errors['success'].length
          }">
            <i class="far fa-check"></i> Передать заказчику
          </button>
        </div>
      </div>
    </form>
  </modal>
</template>

<style scoped>
.btn-group .btn:not(.last) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}
.btn-group .btn + .btn,
.btn-group .btn + div .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.btn.btn-reset {
  padding: 0.2rem 0.4rem;
  font-size: .5rem;
}

.check-list-entry span {
  display: block;
  padding: .75em 1em .5em;
  font-size: .8em;
  line-height: 1.42857143;
  color: #555;

  transition: all .2s;
}

.check-list-entry span:hover {
  cursor: pointer;
  background-color: #EEE;
}

.check-list-entry .btn-destruct {
  align-self: center;
  background-color: transparent;
  border: none;
  color: #555;
  opacity: .4;
}
.check-list-entry .btn-destruct:hover {
  color: #D41B43;
}

.btn.btn-checklist-new {
  border: none;
  background-color: #EEE;
  border-radius: 0;
}
</style>

<script>
import uuidv4 from '@/utils/uuidv4'

import VueElementLoading from 'vue-element-loading'
import HoursPicker from '@/components/HoursPicker.vue'
import UsersPicker from '@/components/UsersPicker.vue'
import { apiCall, apiAuthHeaders, API_PREFIX } from '@/utils/api'
import StageFilesUploader from '@/components/StageFilesUploader.vue'
import {
  CONFIRM_DATES
} from "@/utils/permissions"

import { mapGetters } from "vuex";


export default {
  name: 'PopupAddStage',
  components: {
      VueElementLoading,
      HoursPicker,
      UsersPicker,
      StageFilesUploader
  },
  data() {
    return {
      errors: {
        "hod_rabot": [],
        "start": [],
        "stop": [],
        "success": [],
        "fail": []
      },
      checkListEntryEditing: null,

      showErrorsKey: '',
      blinkErrors: false,

      isLoading: false,
      isDefaultFilesLoading: false,
      isInformationalFilesUploading: false,
      isWorkingFilesUploading: false,
      isFinalFilesUploading: false,
      isAllFinalFilesChecked: true,
      isAllWorkingFilesChecked: true,
      isAllInformationalFilesChecked: true,
      cell: {},
      task: {},
      column: {},
      group: {},
      cellIndex: 0,
      informationalFiles: [],
      workingFiles: [],
      finalFiles: [],
      stage: {
        taskId: 0,
        cellFromId: 0,
        cellToId: 0,
        description: "",
        checkListBefore: [],
        checkListAfter: [],
        progressBefore: 0,
        progressAfter: 0,
        statusBefore: 0,
        statusAfter: 0,
        deadlineBefore: null,
        deadlineAfter: null,
        direction: 0,
        hoursAdded: 0,
        // нужны прям объекты т.к.  можем переименовать их и тд
        workingFiles: [],
        finalFiles: [],
        // дополнительно уведомить
        notifyIds: []
      }
    }
  },
  computed: {
    ...mapGetters(["authId", "groupsKeyed", "authRoles", "customerRoles", "allUsersKeyed"]),
    user() {
			return this.allUsersKeyed[this.authId]
		},
    uploadUrl() {return API_PREFIX + 'stages/upload/' + this.task.id},
    authHeaders() {return apiAuthHeaders()},
    isMeAssigned() {
      if (!this.cell) {
        return false
      }
      return  this.cell.assignedIds && this.cell.assignedIds.find(id => id == this.authId)
    },
    isProgressChanged() {return this.stage.progressBefore != this.stage.progressAfter},
    isDeadlineChanged() {return this.stage.deadlineBefore != this.stage.deadlineAfter},
    isLastCell() { return this.group.columns && this.cellIndex == this.group.columns.length - 1 },
    isNextCellCustomers() {
      if (!this.group || !this.group.columns || this.isLastCell) {
        return false
      }
      let nextColumn = this.group.columns[this.cellIndex + 1]
      return nextColumn.rolesAssignable.some((role) => this.customerRoles.includes(role))
    },
    isCurrentCellCustomers() {
      if (!this.group || !this.group.columns) {
        return false
      }
      let column = this.group.columns[this.cellIndex]
      if (column.rolesAssignable.indexOf(this.customerRole) !== -1) {
        return true
      }
      return false
    },
    cellsToIdAllowed() {
      if (!this.task || !this.task.cells || !this.cell || this.cellIndex+2 == this.task.cells.length) {
        return []
      }
      let cells = []
      for (var i=this.cellIndex+1; i<this.task.cells.length; i++) {
        if (this.task.cells[i].column.rolesAssignable.some((role) => this.customerRoles.includes(role))) {
          continue
        }
        cells.push(this.task.cells[i])
        if (!this.task.cells[i].completeAt) {
          break
        }
      }
      return cells
    },
    canUpdateDeadlineWithoutRequest() {
      if (this.isMeAssigned && !this.stage.deadlineBefore) {
        return true
      } else if (this.authRoles.indexOf(CONFIRM_DATES) !== -1) {
        return true
      }
      return false
    }
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.clearErrors()
      this.checkListEntryEditing = null
      this.cell = data.params.cell
      this.cellIndex = data.params.cellIndex
      this.task = data.params.task
      this.column = data.params.column
      this.group = data.params.group
      this.stage.description = ""
      this.stage.checkListBefore = [...data.params.task.checkList]
      this.stage.checkListAfter = [...data.params.task.checkList]
      this.stage.taskId = data.params.task.id
      this.stage.cellToId = 0
      if (data.params.cell) {
        this.stage.cellFromId = data.params.cell.id
        this.stage.progressBefore = data.params.cell.progress
        this.stage.progressAfter = data.params.cell.progress
        this.stage.statusBefore = data.params.cell.status
        this.stage.statusAfter = data.params.cell.status
        this.stage.deadlineBefore = data.params.cell.deadline
        this.stage.deadlineAfter = data.params.cell.deadline
      }
      this.stage.hoursAdded = 0
      this.stage.direction = 0
      this.stage.informationalFiles = []
      this.stage.workingFiles = []
      this.stage.finalFiles = []
      this.informationalFiles = []
      this.workingFiles = []
      this.finalFiles = []
      this.stage.notifyIds = []

      this.isAllFinalFilesChecked = true
      this.isAllWorkingFilesChecked = true
      this.isAllInformationalFilesChecked = true

      this.loadDefaultFiles()

      this.$escStack.push(this.$options.name)
    },
    informationalFilesUploadStatus(isUploading) {
      this.isInformationalFilesUploading = isUploading
      this.checkErrors()
    },
    workingFilesUploadStatus(isUploading) {
      this.isWorkingFilesUploading = isUploading
      this.checkErrors()
    },
    finalFilesUploadStatus(isUploading) {
      this.isFinalFilesUploading = isUploading
      this.checkErrors()
    },
    onInformationalFilesChanged(data) {
      this.isAllInformationalFilesChecked = data.allChecked
      this.checkErrors()
    },
    onWorkingFilesChanged(data) {
      this.isAllWorkingFilesChecked = data.allChecked
      this.checkErrors()
    },
    onFinalFilesChanged(data) {
      this.isAllFinalFilesChecked = data.allChecked
      this.checkErrors()
    },
    loadDefaultFiles() {
      this.isDefaultFilesLoading = true;
      apiCall({ url: 'tasks/' + this.task.id + '/files', method: "GET"})
			.then(resp => {
        this.workingFiles = resp.workingFiles
        this.finalFiles = resp.finalFiles
        this.stage.workingFiles = resp.workingFiles
        this.stage.finalFiles = resp.finalFiles
				this.isDefaultFilesLoading = false
			})
    },

    clearErrors() {
      this.blinkErrors = false
      this.showErrorsKey = ''
      for (let k in this.errors) {
        this.errors[k].splice(0, k.length)
      }
    },
    checkErrors() {
      this.clearErrors()
      if (this.isInformationalFilesUploading || this.isWorkingFilesUploading || this.isFinalFilesUploading) {
        for (let k in this.errors) {
          this.errors[k].push('Дождитесь завершения загрузки чтобы продолжить')
        }
      }

      if (!this.isAllFinalFilesChecked || !this.isAllWorkingFilesChecked) {
        for (let k in this.errors) {
          if (k !== 'hod_rabot') {
            this.errors[k].push('Необходимо выбрать: "оставить" или "удалить" для каждого файла)')
          }
        }
      }

      if (this.stage.description.trim() == '') {
        this.errors['success'].push('Необходимо заполнить сопроводительное письмо')
        this.errors['fail'].push('Необходимо заполнить сопроводительное письмо')
        this.errors['stop'].push('Необходимо заполнить сопроводительное письмо')

        if (!this.isProgressChanged && !this.isDeadlineChanged && this.stage.hoursAdded == 0) {
          this.errors['hod_rabot'].push('Необходимо заполнить сопроводительное письмо или обновить другие данные')
        }
      }

      if (this.isCurrentCellCustomers && this.cellIndex > 0 && this.stage.finalFiles == 0) {
        this.errors['start'].push('Необходимо загрузить итоговый файл')
        this.errors['hod_rabot'].push('Необходимо загрузить итоговый файл')
      }

      // TODO: учитывать final files только если они нужны в этой группе
      if (this.stage.finalFiles.length == 0 && !this.task.parentTaskId) {
        this.errors['success'].push('Необходимо загрузить итоговый файл')
      }
    },
		onSubmit() {
      if (this.isInformationalFilesUploading || this.isWorkingFilesUploading || this.isFinalFilesUploading) {
        return false  // files still uploading
      }
      let errorBefore = this.showErrorsKey
      this.checkErrors()
      this.showErrorsKey = "hod_rabot"
      if (this.stage.direction == 20) {
        this.showErrorsKey = "success"
      } else if (this.stage.direction == -20) {
        this.showErrorsKey = "fail"
      } else if (this.stage.statusBefore != this.stage.statusAfter) {
        if (this.stage.statusAfter == -50) {
          this.showErrorsKey = "stop"
        } else if (this.stage.statusBefore != 20 && this.stage.statusAfter == 20) {
          this.showErrorsKey = "start"
        }
      }
      
      this.$nextTick( () => {
        if (this.errors[this.showErrorsKey].length > 0) {
          if (errorBefore == this.showErrorsKey) {
            setTimeout(() => {
              this.blinkErrors = true
            }, 50);
          }
          return
        }

        this.isLoading = true
        apiCall({ url: 'stages/add', method: "POST", data: this.stage})
        .then(() => {
          this.$modal.hide(this.$options.name)
          this.isLoading = false
        })
      })
		},
    newCheckListEntry() {
      let number = 0
      if (this.stage.checkListAfter.length > 0) {
        number = this.stage.checkListAfter[this.stage.checkListAfter.length - 1].number
      }
      if (this.stage.checkListBefore.length > 0) {
        number = Math.max(number, this.stage.checkListBefore[this.stage.checkListBefore.length - 1].number)
      }
      return {
        id: uuidv4(),
        completed: false,
        number: number + 1,
        description: ''
      }
    },
    startCheckListEdit(index) {
      let el = this.$refs.editInput[index];
      this.checkListEntryEditing = this.stage.checkListAfter[index].id
      this.$nextTick(() => {
        el.focus();
      })
    }
	}
}
</script>