<template>
  <div class="pure-g">
    <div class="pure-u-1 pure-u-md-1-4 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="20" :true-value="[]">
        Свой профиль
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-4 form-row">
      <label class="text-red">
        <input type="checkbox" v-model="values" @change="change" value="920" :true-value="[]">
        Любой профиль
      </label>
    </div>
    <hr class="pure-u-1"/>


    <h4 class="pure-u-1 margin-half">Нераспределенные задачи</h4>
    <div class="pure-u-1 pure-u-md-1-4 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="9" :true-value="[]">
        Просмотр
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-4 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="10" :true-value="[]">
        Добавление
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-4 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="50" :true-value="[]">
        Распределение
      </label>
    </div>
    <hr class="pure-u-1"/>

    
    <h4 class="pure-u-1 margin-half">Задачи в проекте</h4>
    <div class="pure-u-1 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="11" :true-value="[]">
        Добавление основных
      </label>
    </div>
    <div class="pure-u-2-5 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="17" :true-value="[]">
        Редактирование если в "проверка итог"
      </label>
    </div>
    <div class="pure-u-3-5 form-row">
      <label class="text-red">
        <input type="checkbox" v-model="values" @change="change" value="18" :true-value="[]">
        Редактирование любых
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="12" :true-value="[]">
        Добавление в "В составе"
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="13" :true-value="[]">
        Добавление в "Подзадачи"
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="14" :true-value="[]">
        Добавление в "Заказчику"
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="15" :true-value="[]">
        Удаление основных
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="16" :true-value="[]">
        Удаление подзадач
      </label>
    </div>
    <hr class="pure-u-1"/>


    <h4 class="pure-u-1 margin-half">Работа над задачами</h4>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="21" :true-value="[]">
        Только в назначенных ячейках
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="26" :true-value="[]">
        В назначенных и левее
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label class="text-red">
        <input type="checkbox" v-model="values" @change="change" value="51" :true-value="[]">
        В любых ячейках
      </label>
    </div>

    <div class="pure-u-1 pure-u-md-1-2 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="31" :true-value="[]">
        Просмотр истории в назначенных задачах
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-2 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="32" :true-value="[]">
        Просмотр истории во всех задачах
      </label>
    </div>

    <div class="pure-u-1 pure-u-md-1-2 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="41" :true-value="[]">
        Доступ к чатам в назначенных задачах
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-2 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="42" :true-value="[]">
        Доступ к чатам во всех задачах
      </label>
    </div>

    <hr class="pure-u-1"/>


    <h4 class="pure-u-1 margin-half">Редактирование ячеек задачи</h4>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="25" :true-value="[]">
        Только в назначенных ячейках
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="27" :true-value="[]">
        В назначенных и левее
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label class="text-red">
        <input type="checkbox" v-model="values" @change="change" value="55" :true-value="[]">
        В любых ячейках
      </label>
    </div>

    <div  class="pure-u-1 pure-u-md-2-3"></div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="120" :true-value="[]">
        Подтверждение даты выполнения
      </label>
    </div>
    <hr class="pure-u-1"/>


    <h4 class="pure-u-1 margin-half">Просмотр и скачивание файлов</h4>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="108" :true-value="[]">
        Только в назначенных ячейках
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="109" :true-value="[]">
        Всех файлов
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label class="text-red">
        <input type="checkbox" v-model="values" @change="change" value="110" :true-value="[]">
        Скачивание группами
      </label>
    </div>
    <hr class="pure-u-1"/>


    <h4 class="pure-u-1 margin-half">Работа с галереями</h4>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="22" :true-value="[]">
        Создание
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="52" :true-value="[]">
        Редактирование галереи (не фото)
      </label>
    </div>
    <div class="pure-u-1"></div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="23" :true-value="[]">
        Загрузка фото
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label>
        <input type="checkbox" v-model="values" @change="change" value="24" :true-value="[]">
        Изменение/удаление своих фото
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label class="text-red">
        <input type="checkbox" v-model="values" @change="change" value="54" :true-value="[]">
        Изменение/удаление всех фото
      </label>
    </div>
    <hr class="pure-u-1"/>


    <h4 class="pure-u-1 margin-half">Администрирование</h4>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label class="text-red">
        <input type="checkbox" v-model="values" @change="change" value="900" :true-value="[]">
        Создание/Управление проектами
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label class="text-red">
        <input type="checkbox" v-model="values" @change="change" value="910" :true-value="[]">
        Изменение ролей пользователей
      </label>
    </div>
    <div class="pure-u-1 pure-u-md-1-3 form-row">
      <label class="text-red">
        <input type="checkbox" v-model="values" @change="change" value="930" :true-value="[]">
        Скрытие записей в истории
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionsList',
  emits: ['change'],
  props: [
    'selected'
  ],
  watch: {
    selected: {
      handler: function(newValue) {
        this.values = newValue
      },
      immediate: true
    }
  },
  data() {
    return {
      values: [],
      groupsRadio: [
        [17, 18],  // edit task
        [20, 920],  // edit profile
        [31, 32],  // view history
        [41, 42],  // view chats
        [21, 26, 51],  // add stage in tasks
        [25, 27, 55],  // edit task cell
        [108, 109],  // download files
        [24, 54],  // edit/remove gallery photo
      ]
    }
  },
  methods: {
    change(e) {
      let values = this.values.map(Number)
      let val = Number(e.target.value)
      if (e.target.checked) {
        let radioGroup = this.groupsRadio.find(g => g.indexOf(val) !== -1)
        if (radioGroup) {
          values = values.filter(v => radioGroup.indexOf(v) === -1)
        }
        if (values.indexOf(val) === -1) {
          values.push(val)
        }
      } else {
        values = values.filter(v => v != val)
      }
      this.values = values
      console.log(values)
      this.$emit('change', {selected: values})
    }
	}
}
</script>