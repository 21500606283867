<template>
  <td class="cell assignable" v-bind:class="cellClasses" @click="onAddStage">
    <i class="fas fa-check once-completed-check" title="Эта стадия однажды была помечена как выполненная"></i>
    <i class="fas fa-arrow-left once-returned-arrow" title="С этой стадии однажды возвращали на доработку"></i>
    <div class="cell-content txt-c" @contextmenu.prevent="onShowEditCell">
      <div v-if="cell.deadline && isExpired && forceShowDeadline && cellStatus < 90">
        <span class="deadline" :class="{
          'scale-up-down': cell.deadline && isExpired && cellStatus < 90
        }">{{ deadlineFormatted }}</span>
        <div v-if="cellStatus <= 0 && cell.expectedHours > 0" class="expected-hours">
          {{ hoursLeft }}
        </div>
      </div>
      <div v-else-if="cell.deadline && cellStatus < 90 && forceShowDeadline" class="deadline">
        {{ deadlineFormatted }}
      </div>

      <div class="unassigned-warning scale-up-down"
        v-else-if="isEditCellAlowed && isCreated && isActivated && column.assignRequired && !cell.assignedIds.length">НЕ НАЗНАЧЕНО</div>
      <div v-else-if="forceShowAssignee">
        <text-user-entry :id="cell.assignedIds[0]" v-if="cell.assignedIds.length == 1" />
        <user-entry :id="uid" :short="true" v-else v-for="uid in cell.assignedIds" :key="'assignee_ ' + cell.id + '_' + uid" class="user-entry" />
      </div>

      <div v-if="cellStatus >= 90 && !forceShowAssignee">
        {{ completedFormatted }}
      </div>
      <div v-else-if="cell.deadline && isExpired && !forceShowAssignee && !forceShowDeadline">
        <span class="deadline scale-up-down">{{ deadlineFormatted }}</span>
        <div v-if="cellStatus <= 0 && cell.expectedHours > 0" class="expected-hours">
          {{ hoursLeft }}
        </div>
      </div>
      <div v-else-if="cell.deadline && cellStatus > 0 && !forceShowAssignee && !forceShowDeadline" class="deadline">
        {{ deadlineFormatted }}
      </div>
      <div v-else-if="cellStatus <= 0 && cell.expectedHours > 0 && !forceShowAssignee && !forceShowDeadline" class="expected-hours">
        {{ hoursLeft }}
      </div>

      <span v-if="notificationsCount" class="notification">
        <span v-if="notificationsCount < 10">
          {{ notificationsCount }}
        </span>
        <span v-else><i class="fas fa-exclamation"></i></span>
      </span>
    </div>
    <div v-if="cellStatus < 90" class="progress" v-bind:style="{ 'width': cell.progress + '%' }"></div>

    <span v-if="isSelectingCells && isSelected" class="btn select-button deselect"><i class="fas fa-check"></i></span>
    <span v-else-if="isSelectingCells" class="btn select-button"><i class="fas fa-check"></i></span>
  </td>
</template>

<script>
import moment from 'moment'
import { mapGetters } from "vuex"
import { CELLS_SELECTION_ADD, CELLS_SELECTION_DEL } from "@/store/actions/project"
import {
  EDIT_CELL_OWN, EDIT_CELL_OWN_TASK_LEFT, EDIT_CELL_ANY,
  ADD_STAGE_OWN, ADD_STAGE_OWN_TASK_LEFT, ADD_STAGE_ANY
} from "@/utils/permissions"

import apiCall from "@/utils/api"

import UserEntry from '@/components/UserEntry.vue'
import TextUserEntry from '@/components/TextUserEntry.vue'


export default {
  name: 'TasksTableAssignableCell',
  props: ['cell', 'cellStatusOverride', 'group', 'task', 'column', 'isActivated', 'cellIndex'],
  components: {
    UserEntry, TextUserEntry
  },
  data() {
    return {
      isUpdatingCell: false
    }
  },
  computed: {
    ...mapGetters(["authId", "authRoles", "notifications", "isSelectingCells", "cellsSelected", "forceShowAssignee", "forceShowDeadline"]),
    isCreated() { return !!this.cell.id },
    isExpired() {
      if (!this.cell.deadline) {
        return false
      }
      return new Date(this.cell.deadline) < new Date()
    },
    isSelected() {
      return !!this.cellsSelected.find(c => c.id == this.cell.id)
    },
    cellClasses() {
      let classes = {
        'activated': this.isActivated && (this.isAddStageAlowed || this.isEditCellAlowed),
        'has-notification': this.notificationsCount > 0,
        'once-completed': !!this.cell.completeAt,
        'once-returned-back': !this.isActivated && this.cell.lastDirection < 0
      }
      classes[this.bgColor] = true
      return classes
    },
    notificationsCount() {
      if (!this.cell.id || !this.notifications.cells[this.cell.id]) {
        return 0
      }
      return this.notifications.cells[this.cell.id].length
    },
    bgColor() {
      if (this.cellStatusOverride == null && (!this.cell.assignedIds || !this.cell.assignedIds.length)) {
        return 'unassigned'
      }
      if (this.cellStatusOverride == null && !this.isActivated) {
        return ''
      }
      switch (this.cellStatus) {
        case 0: // only created, no actions done
        case -50: // stopped
        case -20: // paused
          return 'failed'
        case 20: // in progress
          return 'waiting'
        case 90: // completed
          return 'done'
        default:
          return ''
      }
    },
    cellStatus() {
      return this.cellStatusOverride ?? this.cell.status
    },
    expectedHoursMinusProgress() {
      let hours = parseFloat(this.cell.expectedHours)
      if (this.cell.progress > 0) {
        hours -= this.cell.expectedHours * (this.cell.progress / 100)
      }
      return hours
    },
    deadlineFormatted() {
      if (!this.cell.deadline) {
        return ''
      }
      return moment(this.cell.deadline).format('DD.MM.YYYY')
    },
    completedFormatted() {
      if (!this.cell.completeAt) {
        return ''
      }
      return moment(this.cell.completeAt).format('DD.MM.YYYY')
    },
    isMeAssigned() {
      return this.cell.assignedIds && this.cell.assignedIds.find(id => id == this.authId)
    },
    maxAssignedCellIndex() {
      let index = -1
      for (var i = 0; i < this.task.cells.length; i++) {
        if (this.task.cells[i].assignedIds.find(id => id == this.authId)) {
          index = i
        }
      }
      return index
    },
    isEditCellAlowed() {
      if (this.authRoles.indexOf(EDIT_CELL_ANY) !== -1) {
        return true
      } else if (this.authRoles.indexOf(EDIT_CELL_OWN) !== -1 && this.isMeAssigned) {
        return true
      } else if (this.authRoles.indexOf(EDIT_CELL_OWN_TASK_LEFT) !== -1 && this.cellIndex <= this.maxAssignedCellIndex) {
        return true
      }
      return false
    },
    isAddStageAlowed() {
      if (this.authRoles.indexOf(ADD_STAGE_ANY) !== -1) {
        return true
      } else if (this.authRoles.indexOf(ADD_STAGE_OWN) !== -1 && this.isMeAssigned) {
        return true
      } else if (this.authRoles.indexOf(ADD_STAGE_OWN_TASK_LEFT) !== -1 && this.cellIndex <= this.maxAssignedCellIndex) {
        return true
      }
      return false
    }
  },
  methods: {
    onShowEditCell() {
      const column = this.column
      let props = {
        'column': column,
        'group': this.group
      }
      if (!this.cellsSelected.length) {
        props = {
          ...props,
          'cell': this.cell,
          'cellIndex': this.cellIndex,
          'maxAssignedCellIndex': this.maxAssignedCellIndex,
          'task': this.task,
          'contextRef': this.$el
        }
      } else {
        for (var i=0; i<this.cellsSelected.length; i++) {
          if (this.cellsSelected[i].column.rolesAssignable.length) {
            if (!column.rolesAssignable.length) {
              column.rolesAssignable = this.cellsSelected[i].column.rolesAssignable
            } else {
              column.rolesAssignable = column.rolesAssignable.filter(v => this.cellsSelected[i].column.rolesAssignable.includes(v))
            }
          }

          if (this.cellsSelected[i].column.rolesInvisible.length) {
            if (!column.rolesInvisible.length) {
              column.rolesInvisible = this.cellsSelected[i].column.rolesInvisible
            } else {
              column.rolesInvisible = column.rolesInvisible.filter(v => this.cellsSelected[i].column.rolesInvisible.includes(v))
            }
          }
        }
        props = {
          ...props,
          'maxAssignedCellIndex': this.maxAssignedCellIndex,
          'cells': this.cellsSelected,
          'column': column,
          'contextRef': null
        }
      }

      this.$modal.show('PopupEditTaskCell', props)
    },
    saveCell: function () {
      if (!this.isCreated) {
        return
      }
      apiCall({ url: "tasks/cell/edit/", method: "POST", data: this.cell })
    },
    onAddStage() {
      if (this.isSelectingCells) {
        this.isSelected ? this.deselectCell() : this.selectCell()
        return
      }
      if (this.notificationsCount) {
        this.$modal.show('PopupAllStages', { 'task': this.task, 'group': this.group })
        return
      }
      if (!this.isCreated || !this.isActivated || !this.cell.assignedIds.length) {
        return
      }
      if (!this.isAddStageAlowed) {
        return
      }

      this.$modal.show('PopupAddStage', {
        cell: this.cell,
        cellIndex: this.cellIndex,
        task: this.task,
        column: this.column,
        group: this.group
      })
    },
    selectCell() {
      this.$store.dispatch(CELLS_SELECTION_ADD, this.cell)
    },
    deselectCell() {
      this.$store.dispatch(CELLS_SELECTION_DEL, this.cell)
    }
  }
}
</script>


<style>
.tasks-table .task .cell.assignable {
  position: relative;
  background-color: #FFF;
}

.tasks-table .task .cell.assignable.activated,
.tasks-table .task .cell.assignable.has-notification {
  cursor: pointer;
}

.tasks-table .task .cell.assignable.activated:hover,
.tasks-table .task .cell.assignable.has-notification:hover {
  z-index: 2;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3), inset 0px 0px 20em rgba(0, 0, 0, .12);
}

.tasks-table .task .cell.assignable.unassigned {
  background-color: #EEEEEE;
}

.tasks-table .task .cell.assignable.failed {
  background-color: #FF7D70;
}

.tasks-table .task .cell.assignable.waiting {
  background-color: #FEF604;
}

.tasks-table .task .cell.assignable.done {
  background-color: #90C14B;
}

.tasks-table .task .cell.assignable .progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: rgba(255, 255, 255, .35);
}

.tasks-table .task .cell.assignable.unassigned .progress,
.tasks-table .task .cell.assignable.waiting .progress {
  background-color: rgba(0, 0, 0, .2);
}

.tasks-table .task .cell.assignable .cell-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tasks-table .task .cell.assignable.unassigned .unassigned-warning {
  color: #ff2a2a;
  display: inline-block;
  font-size: .7rem;
  font-weight: 700;
}

.tasks-table .task .cell.assignable .deadline.scale-up-down {
  display: block;
  color: #ff2a2a;
  font-weight: 700;
}

.tasks-table .task .cell.assignable.failed .deadline.scale-up-down {
  color: #FEF604;
}

.tasks-table .task .cell.assignable .notification {
  position: absolute;
  top: .25em;
  right: .25em;
  display: block;
  text-align: center;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  font-weight: bold;
  border: 2.5px solid #FFF;
  background-color: #E40231;
  color: #FFF;
  border-radius: 50%;
}


.cell.assignable .select-button {
  position: absolute;
  top: .2rem;
  left: .2rem;
  border-radius: 50%;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.25rem;
  color: #509E2F;
  border: 2px solid;
  background-color: transparent;
  box-sizing: border-box;
}

.cell.assignable .select-button.deselect {
  background-color: #509E2F;
  color: #FFF;
  border-color: #509E2F;
}

.once-completed-check,
.once-returned-arrow {
  display: none;
  position: absolute;
  top: .25em;
  font-size: .8em;
  text-shadow: 0px 0px 2px #FFF;
}

.once-completed .once-completed-check {
  display: block;
  right: .25em;
  color: #509E2F;
}

.once-returned-back .once-returned-arrow {
  display: block;
  left: .25em;
  color: #E40231;
}

.user-entry {
  display: inline-block;
  padding: 0 !important;
}

.user-entry .avatar {
  width: 1.5rem !important;
  height: 1.5rem !important;
  font-size: .8rem;
  line-height: 1.5rem !important;
  margin-right: .5em;
}
</style>