export default class Task {
    nextId() {
        if (!this.visibleId) {
            return 1
        }
        let idParts = ('' + this.visibleId).split('.')
        let lastPart = idParts[idParts.length-1]
        idParts[idParts.length-1] = parseInt(lastPart) + 1
        return idParts.join('.')
    }
}