export const ROLES_REQUEST = "ROLES_REQUEST";
export const ROLES_SUCCESS = "ROLES_SUCCESS";
export const ROLES_ERROR = "ROLES_ERROR";

export const SPECIALIZATIONS_REQUEST = "SPECIALIZATIONS_REQUEST";
export const SPECIALIZATIONS_SUCCESS = "SPECIALIZATIONS_SUCCESS";
export const SPECIALIZATIONS_ERROR = "SPECIALIZATIONS_ERROR";

export const PERMISSIONS_REQUEST = "PERMISSIONS_REQUEST";
export const PERMISSIONS_SUCCESS = "PERMISSIONS_SUCCESS";
