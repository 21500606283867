<template>
  <modal name="PopupAboutTask" @before-open="beforeOpen" @before-close="beforeClose"  draggable=".popup-header" :adaptive="true" :scrollable="true" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">{{ task.visibleId }}: {{ task.name }}</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <div class="content padding nl2br" style="min-height: 10rem">{{ task.description }}</div>
  </modal>
</template>

<style scoped>

</style>

<script>
import Task from '@/models/task'

export default {
  name: 'PopupAboutTask',
  components: {
  },
  data() {
    return {
      task: Object.assign(new Task, {})
    }
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.task = data.params

      this.$escStack.push(this.$options.name)
    },
	}
}
</script>