<template>
  <div id="app">
    <Header v-on:showAside="showAsideBar = true" v-on:hideAside="showAsideBar = false" />

    <main class="pure-g">
      <aside class="pure-u-1" v-bind:class="{
        'pure-u-md-8-24 pure-u-lg-6-24 pure-u-xl-5-24': showAsideBar,
        'hidden': !showAsideBar
      }">
        <aside-bar/>
      </aside>

      <div class="pure-u-1 padding-half" v-bind:class="{
        'pure-u-md-16-24 pure-u-lg-18-24 pure-u-xl-19-24': showAsideBar
      }">
        <router-view/>
      </div>
    </main>
  </div>
</template>


<style>
#app {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

#app > main {
  -webkit-flex-flow: row;
  flex-flow: row;
  flex: 1 1 auto;
  max-width: 100%;
  overflow-x: hidden;
}
</style>

<script>
import Header from '@/components/Header.vue'
import AsideBar from './components/AsideBar.vue'


export default {
  name: 'App',
  components: {
  Header,
  AsideBar
  },
  data() {
    return {
      showAsideBar: true
    }
  }
}
</script>