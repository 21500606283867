import Vue from "vue";
import apiCall from "@/utils/api";
import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
    PERMISSIONS_REQUEST
  } from "../actions/auth";

const state = {
    uid: localStorage.getItem("uid") || "",
    roles: (localStorage.getItem("roles") || "").split(",").map(Number),
    accessToken: localStorage.getItem("tkn") || "",
    refreshToken: localStorage.getItem("rtkn") || "",
    status: ""
};

const getters = {
    isAuthenticated: state => !!state.accessToken,
    authId: state => parseInt(state.uid),
    authStatus: state => state.status,
    authRoles: state => state.roles,
};

const actions = {
    [AUTH_REQUEST]: ({ commit /*, dispatch*/ }, login_data) => {
      return new Promise((resolve, reject) => {
        commit(AUTH_REQUEST);
        apiCall({ url: "auth", data: login_data, method: "POST" })
          .then(resp => {
            if (!resp.access_token) {
              commit(AUTH_ERROR, resp);
              localStorage.removeItem("tkn");
              reject(resp);
              return;
            }
            localStorage.setItem("tkn", resp.access_token);
            localStorage.setItem("rtkn", resp.refreshToken);
            localStorage.setItem("uid", resp.uid);
            localStorage.setItem("roles", resp.roles.join(","));
            commit(AUTH_SUCCESS, resp);
            //dispatch(USER_REQUEST);
            resolve(resp);
          })
          .catch(err => {
            commit(AUTH_ERROR, err);
            localStorage.removeItem("tkn");
            reject(err);
          });
      });
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      return new Promise(resolve => {
        commit(AUTH_LOGOUT);
        localStorage.removeItem("uid");
        localStorage.removeItem("tkn");
        localStorage.removeItem("rtkn");
        localStorage.removeItem("roles");
        resolve();
      });
    },
    [PERMISSIONS_REQUEST]: ({ commit, state }) => {      
      apiCall({ url: "user/permissions/" + state.uid })
        .then(resp => {
          localStorage.setItem("roles", resp.join(","));
          commit(PERMISSIONS_REQUEST, resp);
        })
    }
};

const mutations = {
    [AUTH_REQUEST]: state => {
      state.status = "loading";
    },
    [AUTH_SUCCESS]: (state, resp) => {
      state.status = "success";
      state.uid = resp.uid;
      state.accessToken = resp.access_token;
      state.refreshToken = resp.refresh_token;
      state.roles = resp.roles
    },
    [AUTH_ERROR]: state => {
      state.status = "error";
    },
    [AUTH_LOGOUT]: state => {
      state.uid = "";
      state.accessToken = "";
      state.refreshToken = "";
      state.roles = [];
    },
    [PERMISSIONS_REQUEST]: (state, resp) => {
      Vue.set(state, "roles", resp);
    },
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };
  