var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"avatar-wrp"},[(_vm.user)?_c('span',{attrs:{"title":_vm.user.fullName()}},[(_vm.url)?_c('img',{staticClass:"avatar",style:({
			'background-color': _vm.color,
			'width': _vm.side,
			'height': _vm.side,
			'line-height': _vm.side,
		}),attrs:{"src":_vm.url}}):(_vm.user.avatarUrl)?_c('img',{staticClass:"avatar",style:({
			'background-color': _vm.color,
			'width': _vm.side,
			'height': _vm.side,
			'line-height': _vm.side,
		}),attrs:{"src":_vm.user.avatarUrl}}):_c('span',{staticClass:"avatar",style:({
			'background-color': _vm.color,
			'width': _vm.side,
			'height': _vm.side,
			'line-height': _vm.side,
		})},[_vm._v(_vm._s(_vm.user.abbreviation()))])]):_c('span',{staticClass:"avatar",style:({
			'background-color': '#EEE',
			'width': _vm.side,
			'height': _vm.side,
			'line-height': _vm.side,
		})}),(_vm.msgTop)?_c('span',{staticClass:"badge top"},[(_vm.msgTop == 'msg')?_c('span',[_c('i',{staticClass:"fas fa-comments"})]):(_vm.msgTop < 10)?_c('span',[_vm._v(_vm._s(_vm.msgTop))]):_c('span',[_c('i',{staticClass:"fas fa-exclamation"})])]):_vm._e(),(_vm.msgBottom)?_c('span',{staticClass:"badge bottom"},[(_vm.msgBottom == 'msg')?_c('span',[_c('i',{staticClass:"fas fa-comments"})]):(_vm.msgBottom < 10)?_c('span',[_vm._v(_vm._s(_vm.msgBottom))]):_c('span',[_c('i',{staticClass:"fas fa-exclamation"})])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }