export default class User {
    fullName() {
        var fullName = [];
        if (this.lastName) {
            fullName.push(this.lastName)
        }
        if (this.firstName) {
            fullName.push(this.firstName)
        }
        return fullName.join(' ')
    }

    shortName() {
        var fullName = [];
        if (this.lastName) {
            fullName.push(this.lastName)
        }
        if (this.firstName) {
            fullName.push(this.firstName[0] + '.')
        }
        return fullName.join(' ')
    }

    abbreviation() {
        let abbr = [];
        if (this.lastName) {
          abbr.push(this.lastName[0]);
        }
        if (this.firstName) {
          abbr.push(this.firstName[0]);
        }
        return abbr.join(" ").toUpperCase();
    }

    bgColor() {
        return this.specialization ? this.specialization.color : '#888888';
    }

    specializationName(append) {
        return this.specialization ? this.specialization.name + (append ?? '') : '';
    }

    isOnline() {
        var lastvisit = new Date(this.lastVisitAt)
        if (!lastvisit || !lastvisit.getTime()) {
            return false
        }
        const now = new Date()
        return (lastvisit.getTime() - now.getTime()) - (60 * 1000 * now.getTimezoneOffset()) > 0
    }
}