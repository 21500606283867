export const VIEW_TASK_UNASSIGNED = 9
export const ADD_TASK_UNASSIGNED = 10
export const ASSIGN_TASK = 50

export const ADD_TASK_ROOT = 11
export const ADD_TASK_SPECIAL1 = 12
export const ADD_TASK_SPECIAL2 = 13
export const ADD_TASK_SPECIAL3 = 14

export const DEL_TASK_ROOT = 15
export const DEL_TASK_SPECIAL = 16

export const EDIT_TASK_LAST_ASSIGNED = 17
export const EDIT_TASK_ANY = 18

export const EDIT_OWN_PROFILE = 20

export const ADD_STAGE_OWN = 21
export const ADD_STAGE_OWN_TASK_LEFT = 26
export const ADD_STAGE_ANY = 51

export const ADD_GALLERY = 22
export const EDIT_GALLERY = 52

export const ADD_GALLERY_PHOTO = 23
export const EDIT_GALLERY_PHOTO_OWN = 24
export const EDIT_GALLERY_PHOTO_ANY = 54

export const EDIT_CELL_OWN = 25
export const EDIT_CELL_OWN_TASK_LEFT = 27
export const EDIT_CELL_ANY = 55

export const VIEW_OWN_TASK_HISTORY = 31
export const VIEW_ANY_TASK_HISTORY = 32

export const VIEW_OWN_TASK_CHATS = 41
export const VIEW_ANY_TASK_CHATS = 42

export const DOWNLOAD_FILE_GROUPS = 110

export const CONFIRM_DATES = 120

export const MANAGE_PROJECTS = 900

export const MANAGE_ROLES = 910
export const MANAGE_USERS = 920
export const MANAGE_STAGES = 930