<template>
  <modal name="PopupEditTaskTheme" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :scrollable="true" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">Добавить тему</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <form class="pure-g content with-overflow padding-h padding-half-v" @submit.prevent="onSubmit">
      <vue-element-loading :active="isLoading"/>
      <div class="pure-u-1 form-row">
        <label>Название темы</label>
        <input required v-model="theme.name" type="text" placeholder="Название темы"/>
      </div>
      <div class="pure-u-1 form-row" v-if="isUsersEditVisible">
        <label>Участники темы</label>
        <users-picker v-model="theme.userIds"/>
      </div>
      <div class="pure-u-1 txt-r form-row">
        <button type="submit" class="btn btn-primary"><i class="fal fa-check"></i> Сохранить</button>
      </div>
    </form>
  </modal>
</template>

<style scoped>

</style>

<script>
import { mapGetters } from "vuex";

import VueElementLoading from 'vue-element-loading'
import UsersPicker from './UsersPicker.vue'
import apiCall from "@/utils/api"
import Task from "@/models/task"


export default {
  name: 'PopupEditTaskTheme',
  components: {
      VueElementLoading,
      UsersPicker,
  },
  data() {
    return {
      isLoading: false,
      task: Object.assign(new Task, {}),
      theme: {
        name: '',
        userIds: [],
        type: 1
      }
    }
  },
  computed: {
    ...mapGetters(["authId", "allUsersKeyed"]),
    isUsersEditVisible() {
      // for all-free to join themes it's not needed
      return this.theme.type > 0
    }
	},
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen: function(data) {
      this.task = data.params.task
      if (data.params.theme) {
        this.theme = data.params.theme
      } else {
        let assigns = [this.authId]
        for(var i=0; i<this.task.cells.length; i++) {
          assigns = assigns.concat(this.task.cells[i].assignedIds)
        }
        assigns = assigns.filter(a => !this.allUsersKeyed[a] || !this.allUsersKeyed[a].isCustomer )
        this.theme = {
          name: '',
          taskId: this.task.id,
          userIds : assigns,
          type: 1
        }
      }

      this.$escStack.push(this.$options.name)
    },
		onSubmit: function () {
      this.isLoading = true
      let action = "tasks/"+this.task.id+"/themes/" + (this.theme.id ? 'edit'  : "add")
      apiCall({ url: action, method: "POST", data: this.theme})
			.then(() => {
				this.$modal.hide(this.$options.name)
				this.isLoading = false
			})
		}
	}
}
</script>