import { USERS_REQUEST, USERS_ERROR, USERS_SUCCESS, USER_UPDATE } from "../actions/users";
import apiCall from "@/utils/api";
import User from '@/models/user';
import Vue from "vue";

const state = {
  status: "",
  users: [],
  usersKeyed: {}
};

const getters = {
  allUsers: state => state.users,
  allUsersKeyed: state => state.usersKeyed
};

const actions = {
  [USERS_REQUEST]: ({ commit, state }) => {
    if (state.status == "loading") {
      return
    }
    
    commit(USERS_REQUEST);
    apiCall({ url: "users" })
      .then(resp => {
        commit(USERS_SUCCESS, resp);
      })
      .catch(() => {
        commit(USERS_ERROR);
      });
  },

  [USER_UPDATE]: ({ commit, state }, userId) => {
    if (state.status == "loading") {
      return
    }
    apiCall({ url: "users/"+userId })
      .then(resp => {
        commit(USER_UPDATE, resp);
      })
  }
};

const mutations = {
  [USERS_REQUEST]: state => {
    state.status = "loading";
  },
  [USERS_SUCCESS]: (state, resp) => {
    state.status = "success";
    let users = resp.map(u => Object.assign(new User, u))
    
    let keyed = {}
    for (var i=0; i< users.length; i++) {
      keyed[users[i].id] = users[i]
    }

    Vue.set(state, "users", users);
    Vue.set(state, "usersKeyed", keyed);
  },
  [USERS_ERROR]: state => {
    state.status = "error";
  },

  [USER_UPDATE]: (state, resp) => {
    let user = Object.assign(new User, resp)

    let index = state.users.findIndex(u => u.id == user.id)
    if (index === -1) {
      Vue.set(state.users, state.users.length, user);
    } else {
      Vue.set(state.users, index, user);
    }
    Vue.set(state.usersKeyed, user.id, user);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
