<template>
  <span class="flex-row file added">
    <span class="block-vcenter extension" @click.prevent="$modal.show('PopupFilePreview', file)">
      <i v-if="['txt'].indexOf(extension) >= 0" class="fas fa-file-alt txt"></i>
      <i v-else-if="['doc', 'docx', 'odf'].indexOf(extension) >= 0" class="fas fa-file-word doc"></i>
      <i v-else-if="['xls', 'xlsx', 'csv'].indexOf(extension) >= 0" class="fas fa-file-excel xls"></i>
      <i v-else-if="['ppt', 'pptx'].indexOf(extension) >= 0" class="fas fa-file-powerpoint ppt"></i>
      <i v-else-if="['pdf'].indexOf(extension) >= 0" class="fas fa-file-pdf pdf"></i>
      <i v-else-if="['zip', 'rar', '7zip', 'gz', 'tar'].indexOf(extension) >= 0" class="fas fa-file-archive zip"></i>
      <i v-else-if="['jpg', 'jpeg', 'png', 'svg', 'bmp', 'gif', 'webp', 'tga'].indexOf(extension) >= 0" class="fas fa-file-image jpg"></i>
      <i v-else-if="['avi', 'mp4', 'mkv', 'mpeg', '3gp'].indexOf(extension) >= 0" class="fas fa-file-video avi"></i>
      <i v-else-if="['mp3', 'wav', 'ogg'].indexOf(extension) >= 0" class="fas fa-file-audio mp3"></i>
      <i v-else class="fas fa-file any"></i>
    </span>
    <span class="flex-expand file-name" @click.prevent="$modal.show('PopupFilePreview', file)">
      {{ nameWithoutExtension + '.' + extension }}
      <br/>
      <span class="smaller upload-at" :title="uploadedAt(file.createdAt)">
        <i class="fal fa-clock"></i> загружен {{ uploadedAtAgo(file.createdAt) }}
      </span>
    </span>
    <div class="block-vcenter">
      <slot name="right">
        <a href="javascript:" class="btn" :class="{'btn-pressed': isCopiedToClipboard}" @click.prevent="copyLink" title="Скопировать ссылку на файл">
          <i class="fad fa-link" v-if="!isCopiedToClipboard"></i>
          <span v-else>
            <i class="fad fa-check"></i>
            Скопировано!
          </span>
        </a>
        <a href="javascript:" class="btn" @click.prevent="$modal.show('PopupFilePreview', file)">
          <i class="fad fa-eye"></i>
        </a>
        <a :href="file.url" download class="btn" v-if="file.isActual">
          <i class="fad fa-download"></i>
        </a>
      </slot>
    </div>
  </span>
</template>


<script>
export default {
  name: 'FileEntry',
  props: ['file'],
  data() {
    return {
      isCopiedToClipboard: false
    }
  },
  computed: {
    extension() {
      let filename = this.file.url || this.file.name
      let parts = filename.split('.')
      return parts[parts.length-1].toLowerCase()
    },
    nameWithoutExtension() {
      let name = ""
      if (this.file.type == 10) {
        name = "(Штамп) "
      }
      if (this.file.name.endsWith('.' + this.extension)) {
        name += this.file.name.replace('.' + this.extension, '')
      } else {
        name += this.file.name
      }
      return name
    }
  },
  methods: {
    uploadedAt(datetime) {
      return this.$moment.utc(datetime).local().format('LLL');
    },
    uploadedAtAgo(datetime) {
      // lastUpdated - просто передается заменяющая каждую секунду стркоа чтобы функция пересчиталась и стала реактивной
      return this.$moment.utc(datetime).local().calendar().toLowerCase()
    },
    copyLink() {
      navigator.clipboard.writeText(document.location.origin + this.file.url)
      this.isCopiedToClipboard = true;
      setTimeout(() => {
        this.isCopiedToClipboard = false;
      }, 2000)
    }
  }
}
</script>

<style scoped>
.file {
  padding: .25em 0;
  transition: all .2s ease-in-out;
}
.file:hover {
  box-shadow: inset 0 0 20em rgba(255,255,255,0.3);
}

.file .file-name {
  font-size: .85em;
  word-break: break-word;
  cursor: pointer;
}

.file .extension {
  cursor: pointer;
}

.file .extension i {
  margin-right: .5rem;
  font-size: 1.4rem !important;
}

.extension .txt,
.extension .jpg,
.extension .avi,
.extension .mp3,
.extension .any {
  color: #BA68C8;
}
.extension .doc {
  color: #1976D2;
}
.extension .xls {
  color: #388E3C;
}
.extension .ppt {
  color: #9B341F;
}
.extension .pdf {
  color: #E53935;
}
.extension .zip {
  color: #f7b900;
}

.btn {
  box-shadow: none;
  border: none;
  background-color: transparent;
  border-radius: 0;
  color: #000;
}

.btn:hover {
  box-shadow: inset 0 0 20em rgba(0,0,0,0.05);
}

</style>
