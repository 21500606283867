<template>
  <div class="global-select flex-row" v-if="selectingFor || isSelectingCells">
      
      <div class="flex-expand block-vcenter padding-h" v-if="selectingFor">
        <span>
          <span v-if="isLinkingTask" class="smaller">Выберите цель, к которой в зависимости будет добавлена эта задача:</span>
          <span v-if="isSelectingUnassigned" class="smaller">Выберите папку в которую поместить задачу:</span>
          
          <br/>
          {{ selectingFor.name }}
        </span>
      </div>

      <div class="flex-expand block-vcenter padding-h" v-if="isSelectingCells">
        <span>
          <span class="smaller">Выбрано ячеек: {{ cellsSelected.length }}</span>
        </span>
      </div>

      <button class="btn" :class="{ pressed: isLinkingMultiple }" v-if="isLinkingTask" 
        @click="toggleLinkingMultiple"
        title="Режим добавления нескольких задач">
        <i class="fal fa-ballot-check"></i>
      </button>
      <button class="btn" @click="stopSelecting">
        <i class="fal fa-times"></i>
      </button>
  </div>
</template>

<style scoped>
  .global-select {
    position: absolute;
    background-color: #ffeb00;
    color: #000;
    font-size: 1.2em;
    box-sizing: border-box;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .btn {
    background-color: transparent;
    border-radius: 0;
    border: none;
    padding: 0 1.25rem;
    font-size: 1.5rem;
    color: #000;
  }
  .btn.pressed {
    background-color: rgba(0, 0, 0, .1);
  }
</style>

<script>
import { mapGetters } from "vuex";
import { STOP_SELECTING, CELLS_SELECTION_STOP, START_LINKING_MULTIPLE, STOP_LINKING_MULTIPLE } from "@/store/actions/project"

export default {
  name: 'GlobalSelecting',
  components: {
  },
  computed: {
    ...mapGetters(["selectingFor", "isSelectingUnassigned", "isLinkingTask", "isLinkingMultiple", "isSelectingCells", "cellsSelected"])
  },
  methods: {
    stopSelecting() {
      this.$store.dispatch(STOP_SELECTING)
      this.$store.dispatch(CELLS_SELECTION_STOP)
    },
    toggleLinkingMultiple() {
      if (this.isLinkingMultiple) {
        this.$store.dispatch(STOP_LINKING_MULTIPLE)
      } else {
        this.$store.dispatch(START_LINKING_MULTIPLE)
      }
    }
  }
}
</script>