import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth";
import user from "./modules/user";
import users from "./modules/users";
import roles from "./modules/roles";
import project from "./modules/project";
import notifications from "./modules/notifications";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    user,
    users,
    roles,
    project,    
    notifications
  },
})
