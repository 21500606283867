<template>
  <div class="flex-row user" @click.stop="openAboutUser(user)">
    <div class="flex-expand txt-l padding-h block-vcenter name">
      {{ user.shortName() }}
    </div>
  </div>
</template>

<style scoped>
.user .name {
  font-size: .9em;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'TextUserEntry',
  props: ['id'],
  computed: {
    ...mapGetters(["authId", "allUsersKeyed"]),
    user() { 
      console.log(this.allUsersKeyed[this.id])
      return this.allUsersKeyed[this.id]
    }
  },
  methods: {
    openAboutUser(user) {
      document.body.click(); // скрываем все открытые tippy
      this.$modal.show('PopupAboutProfile', user.id)
    }
  }
}
</script>