import { USER_REQUEST, USER_ERROR, USER_SUCCESS } from "../actions/user";
import apiCall from "@/utils/api";
import User from '@/models/user';
import Vue from "vue";
import { AUTH_LOGOUT } from "../actions/auth";

const state = { status: "", profile: Object.assign(new User, {}) };

const getters = {
  myProfile: state => state.profile,
  myProfileState: state => !!state.profile.id,
  myProfileColor: state => state.profile?.role?.color ?? "transparent"
};

const actions = {
  [USER_REQUEST]: ({ commit, rootState }) => {
    commit(USER_REQUEST);
    let uid = rootState.auth.uid;
    apiCall({ url: "users/" + uid })
      .then(resp => {
        commit(USER_SUCCESS, Object.assign(new User, resp));
      })
      .catch(() => {
        commit(USER_ERROR);
      });
  }
};

const mutations = {
  [USER_REQUEST]: state => {
    state.status = "loading";
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "profile", resp);
  },
  [USER_ERROR]: state => {
    state.status = "error";
  },
  [AUTH_LOGOUT]: state => {
    state.profile = Object.assign(new User, {});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
