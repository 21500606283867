<template>
  <div class="flex-row task">
    <div class="flex-expand txt-l padding-h block-vcenter">
      <span>
        <b class="block">
          {{ task.name }}
        </b>
      </span>
    </div>
    <button class="btn btn-transparent" @click="openTask()"><i class="far fa-external-link"></i></button>
  </div>
</template>

<style scoped>
.btn.btn-transparent {
  min-width: 3rem;
}
.btn.btn-transparent i {
  color: #777;
  font-size: 1rem;
}
.btn.btn-transparent:hover i {
  color: #509E2F;
}
</style>

<script>
import { TASK_SHOW_START, TASK_SHOW_STOP } from "@/store/actions/project"
import { mapGetters } from "vuex";

export default {
  name: 'TaskEntry',
  props: ['id'],
  data() {
    return {
      showAside: true
    }
  },
  computed: {
    ...mapGetters(["tasksKeyed"]),
    task() { return this.tasksKeyed[this.id] }
  },
  methods: {
    openTask() {
      this.$store.dispatch(TASK_SHOW_START, this.task)
      setTimeout(() => {
        this.$store.dispatch(TASK_SHOW_STOP)
      }, 3500);
    }
  }
}
</script>