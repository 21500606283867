<template>    
    <tr class="header" v-bind:style="{'background-color': group.color, 'color': groupColor}">
        <td :colspan="(group.specialType == -10 || group.tasks.length) ? 2 : 2+columnsCount" @click="toggleDir">
            <span class="subdir-square" v-bind:style="{'background-color': group.color}"></span>
            <span class="flex-row block-vcenter inline-actions-wrp">
                <span v-if="group.id && group.specialType != -10 && (group.parentGroupId || group.parentTaskId)" class="inline-actions">
                    <i class="children-folder-icon" v-bind:class="{
                        'fas fa-folder': !isDirOpened,
                        'fad fa-folder-open': isDirOpened,
                        'text-green': group.tasks && group.tasks.length,
                        'text-grey': !group.tasks || !group.tasks.length,
                    }"></i>
                </span>
                <span v-else-if="group.id && (group.parentGroupId || group.parentTaskId)" class="inline-actions">
                    <i class="children-folder-icon" v-bind:class="{
                        'far fa-link': !isDirOpened,
                        'fas fa-link': isDirOpened,
                        'text-green': group.tasks && group.tasks.length,
                        'text-grey': !group.tasks || !group.tasks.length,
                    }"></i>
                </span>

                <span class="inline-actions" v-if="group.id && group.parentTaskId && (group.specialType == 20 || group.specialType == 30)" v-show="showPlaceHereButton">
                    <button class="btn margin-h" type="button" @click.stop="onMoveTaskToGroup()">
                        <span class="scale-up-down text-red" style="display: inline-block">
                        <i class="fas fa-share"></i>
                        </span> поместить в эту папку
                    </button>
                </span>
                <span class="flex-expand margin-half-l">{{ group.name }}</span>
                <span class="inline-actions hover" v-if="group.id">
                    <button class="btn" type="button" v-if="group.id && !group.parentTaskId && isGroupManagementAllowed" @click.stop="$modal.show('PopupEditTaskGroup', group)"><i class="fal fa-cog"></i></button>
                    
                    <button class="btn" type="button" v-if="group.specialType != -10 && downloadAvailable" @click.stop="$modal.show('PopupDownloadGroup', {form: {includeFinal: true, includeWorking: false, includeSubdirs: false}, group: group})"><i class="fal fa-download"></i> скачать</button>
                    
                    <slot name="afterName"></slot>
                    
                    <button class="btn hidden" type="button" v-if="(!group.groups || group.groups.length == 0) && (!group.tasks || group.tasks.length == 0) && group.specialType != -10" @click.stop=""><i class="fal fa-trash-alt"></i></button>

                    <button class="btn" type="button" v-if="group.specialType != -10 && !group.parentTaskId && isGroupManagementAllowed" @click.stop="onAddSubGroup()"><i class="fal fa-folder-plus"></i> новая папка</button>
                </span>
                <span class="inline-actions">
                    <button class="btn" v-bind:class="{'btn-primary': !group.id || group.parentTaskId}" type="button" v-if="group.specialType != -10 && addTaskAllowed" @click.stop="onAddTask()"><i class="fal fa-plus-circle"></i> новая задача</button>
                </span>
            </span>
        </td>
        <template v-if="group.specialType == -10">
            <td v-for="col in columnsCount" :key="'column' + col" class="txt-c">
                <slot name="columnName"></slot>
            </td>
        </template>
        <template v-else-if="group.tasks.length">
            <td v-for="col in groupColumns" :key="col.id" class="txt-c">
                <slot name="columnName">{{ col.name }}</slot>
            </td>
        </template>
    </tr>
</template>

<style scoped>
    .header {
        position: relative;
    }
    .subdir-square {
        display: none;
    }
    .tasks-table .tasks-table .header .subdir-square {
        content: "";
        display: block;
        position: absolute;
        border: 1px solid #666;
        left: -1.1rem;
        top: .75rem;
        width: .5rem;
        height: .5rem;
    }
</style>

<script>
import { mapGetters } from "vuex"
import { DONE_SELECTING_UNASSIGNED, LOCAL_TASK_ADDED } from "@/store/actions/project"
import { ADD_TASK_UNASSIGNED, ADD_TASK_ROOT, ADD_TASK_SPECIAL1, ADD_TASK_SPECIAL2, ADD_TASK_SPECIAL3, DOWNLOAD_FILE_GROUPS, MANAGE_PROJECTS } from "@/utils/permissions"

import textColor from '@/utils/textColor'
import TaskGroup from '@/models/taskGroup'
import uuidv4 from '@/utils/uuidv4'

import Task from '@/models/task'
import TaskCell from '@/models/taskCell'

export default {
  name: 'TasksTableGroup',
  props: ['group', 'isOpened'],
  data() {
    return {
        isDirOpened: this.isOpened,
    }
  },
  watch: {
    isOpened(newVal) {
        this.isDirOpened = newVal
    },
    taskShowing() {
        if (this.taskShowing && this.taskShowing.id && this.taskShowing.group && this.taskShowing.group.id == this.group.id && !this.isDirOpened) {
            this.toggleDir()
        }
    }
  },
    computed: {
        ...mapGetters(["authId", "allUsersKeyed", "authRoles", "selectingFor", "isSelectingUnassigned", "groupsKeyed", "tasksKeyed", "taskShowing", "customerRoles"]),
		user() {
			return this.allUsersKeyed[this.authId]
		},
        isCustomer() {
            return this.customerRoles.includes(this.user.roleId)
        },
        groupColor: function() { return textColor(this.group.color) },
        groupColumns() {
            if (!this.group.columns) {
                return []
            }
            return this.group.columns.filter(c => c.rolesInvisible.length == 0 || !this.isCustomer)
        },
        columnsCount() {
            if (this.group.specialType != -10) {
                return this.groupColumns.length
            }
            let maxColumnsCount = 0
            let task = this.tasksKeyed[parseInt(this.group.parentTaskId)]
            if (!task) {
                return maxColumnsCount
            }
            for (var i=0; i<task.linksIn.length; i++) {
                let t = this.tasksKeyed[parseInt(task.linksIn[i])]
                let groupsInTask = this.groupsKeyed[t.groupId].columns.filter(c => c.rolesInvisible.length == 0 || !this.isCustomer)
                maxColumnsCount = Math.max(maxColumnsCount, groupsInTask.length)
            }
            return maxColumnsCount
            },
        downloadAvailable() {
            if (this.authRoles.indexOf(DOWNLOAD_FILE_GROUPS) === -1) {
                return false
            }
            if (this.group.tasks.some(t => t.finalFilesCount > 0 || t.workingFilesCount > 0)) {
                return true
            }
            return this.group.groups.some(g => g.tasks.some(t => t.finalFilesCount > 0 || t.workingFilesCount > 0))
        },
        addTaskAllowed() {
            if (this.group.specialType == 10 && this.authRoles.indexOf(ADD_TASK_SPECIAL1) !== -1) {
                return true
            }
            if (this.group.specialType == 20 && this.authRoles.indexOf(ADD_TASK_SPECIAL2) !== -1) {
                return true
            }
            if (this.group.specialType == 30 && this.authRoles.indexOf(ADD_TASK_SPECIAL3) !== -1) {
                return true
            }
            if (!this.group.parentTaskId && this.group.id && this.authRoles.indexOf(ADD_TASK_ROOT) !== -1) {
                return true
            }
            if (!this.group.id && this.authRoles.indexOf(ADD_TASK_UNASSIGNED) !== -1) {
                return true
            }
            return false
        },
        isGroupManagementAllowed() {
            return this.authRoles.indexOf(MANAGE_PROJECTS) !== -1
        },
        showPlaceHereButton() {
            if (!this.isSelectingUnassigned || !this.selectingFor) {
                return false
            }
            const isCreatedByCustomer = this.customerRoles.includes(this.allUsersKeyed[this.selectingFor.createdById].roleId)
            if (this.group.specialType == 30 && isCreatedByCustomer) {
                return true
            } else if (this.group.specialType == 20 && !isCreatedByCustomer) {
                return true
            }
            return false
        }
  },
  methods: {
    onAddSubGroup() {
        this.$modal.show('PopupEditTaskGroup', Object.assign(new TaskGroup(), this.group, {
            pendingId: uuidv4(), // temp id in order to understand what actual entry this is
            id: 0,
            name: "",
            color: '#f1f2f3',
            parentGroupId: this.group.id,
            parentTaskId: null, // тут всегда null и не null только когда будем добавлять из задачи
            managerIds: this.group.managerIds,
            columns: this.group.columns.map(c => Object.assign({}, c, {id: 0}))
        }))
    },
    onAddTask: function() {
        let prevTask = this.group.tasks && this.group.tasks.length ? Object.assign(new Task, this.group.tasks[this.group.tasks.length-1]) : null
        let task = Object.assign(new Task, {
            pendingId: uuidv4(), // temp id in order to understand what actual entry this is
            projectId: this.group.projectId,
            groupId: this.group.id,
            parentTaskId: null,
            visibleId: prevTask ? prevTask.nextId() : "1",
            name: "",
            order: this.group.tasks && this.group.tasks.length ? this.group.tasks.length : 0,
            cells: [],
            tasks: [],
            groups: []
        })
        for(var i=0; i<this.group.columns.length; i++) {
            task.cells.push(Object.assign(new TaskCell, {
                columnId: this.group.columns[i].id,
                assignedIds: []
            }))
        }
        this.$store.dispatch(LOCAL_TASK_ADDED, {groupId: this.group.id, task: task})
        //this.group.tasks.push(task)
        if (!this.isDirOpened) {
            this.toggleDir()
        }
        this.$nextTick(() => {
            //this.$store.dispatch(TASK_EDIT_NAME_START, task)
            this.$modal.show('PopupEditTask', {
                'task': task,
                'group': this.group,
                'cells': task.cells,
                'isLinked': false,
                'linksGroup': null,
                'contextRef': null
            })
        })
    },
    onMoveTaskToGroup() {
        this.$store.dispatch(DONE_SELECTING_UNASSIGNED, this.group)
        if (!this.isDirOpened) {
            this.toggleDir()
        }
    },
    toggleDir() {
        if (this.group.parentTaskId && this.isDirOpened) {
            return
        }
        this.isDirOpened = !this.isDirOpened
        let eventName = this.isDirOpened ? 'openDir' : 'closeDir'
        this.$emit(eventName, this.group.id)
    }
  }
}
</script>