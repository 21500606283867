import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import { SERVER_ADDRESS } from '@/utils/api'


export default {
  install (Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl(SERVER_ADDRESS + 'ws')
      .configureLogging(LogLevel.Information)
      .build()
 
    const sodnpHub = new Vue();
    Vue.prototype.$sodnpHub = sodnpHub;

    connection.on("ProjectAdded", (projectId) => {
        sodnpHub.$emit("project-added", { projectId });
    });

    connection.on("TaskAdded", (data) => {
      sodnpHub.$emit("task-added", data);
    });
    connection.on("TaskUpdated", (data) => {
      sodnpHub.$emit("task-updated", data);
    });

    connection.on("TaskThemeAdded", (data) => {
      sodnpHub.$emit("task-theme-added", data);
    });

    connection.on("TaskThemeMessageReceived", (data) => {
      sodnpHub.$emit("task-theme-msg-received", data);
    });
    connection.on("TaskThemeMessageTyping", (data) => {
      sodnpHub.$emit("task-theme-msg-typing", data);
    });

    connection.on("UserUpdated", (data) => {
      sodnpHub.$emit("user-updated", data);
    });

    // if connection closed, reopen it
    let startedPromise = null;
    function start() {
      startedPromise = connection.start().catch(err => {
        console.log(err)
        return new Promise((resolve, reject) =>
          setTimeout(
            () =>
              start()
                .then(resolve)
                .catch(reject),
            5000
          )
        );
      });
      return startedPromise;
    }

    connection.onclose(() => start());
    start()
  }
}