<template>
  <modal name="PopupEditProject" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :scrollable="true" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">Изменение проекта</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <form class="pure-g content with-overflow padding-h padding-half-v" @submit.prevent="onSubmit">
      <vue-element-loading :active="isLoading"/>
      <div class="pure-u-1 form-row">
        <label>Название проекта</label>
        <input required v-model="project.name" type="text" placeholder="Название проекта"/>
      </div>
      <div class="pure-u-1 form-row">
        <label class="block">Фотография</label>
        <input type="hidden" v-model="project.photoUrl"/>

        <file-upload
          class="padding-v"
          name="file"
          :post-action="uploadUrl"
          :multiple="false"
          :drop="false"
          :headers="authHeaders"
          input-id="profile_avatar_upload"
          v-model="projectImages"
          ref="upload"
          @input-filter="inputFile">
            <img v-if="project.photoUrl" :src="project.photoUrl" />
            <button v-else class="btn" type="button"><i class="fal fa-upload margin-r"></i> Загрузить</button>
        </file-upload>
      </div>
      <div class="pure-u-1 form-row">
        <label>Менеджеры проекта</label>
        <users-picker v-model="project.managerIds"/>
      </div>
      <div class="pure-u-1 form-row">
        <label>Заказчики проекта</label>
        <users-picker v-model="project.customerIds" :roles="customerRoles" :allCustomers="true"/>
      </div>
      <div class="pure-u-1 txt-r form-row">
        <button type="submit" class="btn btn-primary"><i class="fal fa-check"></i> Сохранить</button>
      </div>
    </form>
  </modal>
</template>

<style scoped>

</style>

<script>
import VueElementLoading from 'vue-element-loading'
import FileUpload from 'vue-upload-component'
import UsersPicker from './UsersPicker.vue'
import Project from '@/models/project'
import { apiCall, apiAuthHeaders, API_PREFIX } from "@/utils/api"

import { mapGetters } from "vuex";


export default {
  name: 'PopupEditProject',
  components: {
      VueElementLoading,
      FileUpload,
      UsersPicker
  },
  data() {
    return {
      isLoading: false,
      isUploading: false,
      project: Object.assign(new Project, {}),
      // нужен массив все-таки для этого плагина, но по сути там максимум один элемент
      projectImages: [],
    }
  },
  computed: {
    ...mapGetters(["customerRoles"]),
    authHeaders() { return apiAuthHeaders() },
    uploadUrl() {
      return API_PREFIX + 'projects/' + this.project.id + '/upload'
    },
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen: function(data) {
      this.project = data.params

      this.$escStack.push(this.$options.name)
    },

    startUpload(file) {
      this.isUploading = true
      this.$refs.upload.update(file, {active: true})
    },
    // обновление статуса загружаемого файла
    inputFile(newFile, oldFile/*, prevent*/) {
      if (newFile && !oldFile) { // file added
        this.$nextTick(() => this.startUpload(newFile))
      }

      if (newFile && oldFile) {  // file updated
        if (newFile.success !== oldFile.success) {  // Uploaded successfully
          this.$set(this.project, 'photoUrl', newFile.response.url)
        }
      }
    },

    onSubmit: function () {
      this.isLoading = true
      let action = this.project.id ? "projects/edit" : "projects/add"
      apiCall({ url: action, method: "POST", data: this.project})
      .then(resp => {
        console.log(resp)
        this.$modal.hide(this.$options.name)
        this.isLoading = false
      })
    }
	}
}
</script>