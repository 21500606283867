<template>
	<span class="dbl-edit" @dblclick="startEdit()">

		<input type="text" v-if="!isEditing" v-model="editingValue" class="non-editing" readonly @dblclick="startEdit()" v-bind:style="{
			'text-align': textAlign
		}">
		<input type="text" ref="input" v-else v-model="editingValue" class="editing"
			v-on:blur= "onConfirm()"
			:placeholder="placeholder"
			@keyup.enter="onConfirm()"
			@keyup.esc.prevent.stop="onDiscard()"
			@dblclick="startEdit()"
			v-bind:style="{
				'text-align': textAlign
			}">
	</span>
</template>

<style scoped>
.dbl-edit, .value {
	width: 100%;
	height: 100%;
}
.dbl-edit input[type=text],
.dbl-edit input[type=text][disabled] {
	display: inline-block;
	font-size: .9rem;
	line-height: 1;
	margin: 0;
	color: inherit;
	padding: 0;
	border: none;
	outline: none;
	box-shadow: none;
	background-color: transparent;
}
.dbl-edit input.non-editing[type=text],
.dbl-edit input.non-editing[type=text][disabled] {
	text-overflow: ellipsis;
	cursor: pointer;
}
.dbl-edit input.editing[type=text],
.dbl-edit input.editing[type=text][disabled] {
	font-size: .8rem;
	background-color: #FFF;
	border: 1px solid #EEE;
}
</style>

<script>
export default {
	name: 'DoubleClickEditText',
	props: {
		'value': String,
		'placeholder': String,
		'enabled': {
			type: Boolean,
			default: true
		},
		'textAlign': {
			type: String,
			default: 'left'
		}
	},
	data() {
		return {
			isEditing: false,
			editingValue: this.value,
			valBeforeEdit: null
		}
	},
	watch: {
		value: function(newVal) {
			this.editingValue = newVal
		}
	},
	methods: {
		startEdit() {
			if (!this.enabled) {
				return
			}
			this.valBeforeEdit = this.editingValue
			this.isEditing = true
			this.$nextTick(() => {
				this.$refs.input.focus()
				//this.$refs.input.select();
			})
		},
		onConfirm() {
			this.isEditing = false
			this.$nextTick( () => {
				if (this.valBeforeEdit != this.editingValue) {
					this.valBeforeEdit = this.editingValue
					this.$emit('input', this.editingValue)
				}
			})
		},
		onDiscard() {
			this.editingValue = this.valBeforeEdit
			this.isEditing = false
		}
	}
}
</script>