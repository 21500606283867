<template>
  <div class="home">
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'Home',
  computed: {
    ...mapGetters(["isAuthenticated", "authStatus"])
  }
}
</script>
