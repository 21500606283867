<template>
  <modal name="PopupEditTaskGroup" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :width="650" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">Изменение папки задач</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <form class="pure-g content padding-h padding-half-v" @submit.prevent="onSubmit">
      <vue-element-loading :active="isLoading"/>
      <div class="pure-u-2-24 form-row padding-half-t">
        <label>&nbsp;</label>
        <verte v-model="group.color" menu-position="left" model="hex" :enableAlpha="false" :showHistory="null"></verte>
      </div>
      <div class="pure-u-22-24 form-row">
        <label>Название папки</label>
        <input required v-model="group.name" type="text" placeholder="Название папки"/>
      </div>
      <div class="pure-u-1 form-row">
        <label class="block-vcenter">
          <input v-model="group.hasFinalFile" type="checkbox" class="margin-half-r"/>
          Задачи содержат итоговый файл
        </label>
      </div>
      <div class="pure-u-1 form-row">
        <label class="block-vcenter">
          <input v-model="group.isDefaultOpen" type="checkbox" class="margin-half-r"/>
          Открывать папку по умолчанию
        </label>
      </div>
      <div class="pure-u-1 form-row" v-show="group.specialType != -10">
        <label>Ответственные</label>
        <users-picker v-model="group.managerIds" />
      </div>
      <div class="pure-u-1 form-row">
        <vue-tabs>
          <v-tab v-for="(col, index) in group.columns" :key="index" :title="'edit_tab' + (col.name || 'Без имени') + index">
            <div slot="title">
              {{ col.name || 'Без имени' }}
              <span @click.stop="removeColumn(index)" class="fal fa-times padding-half-l"></span>
            </div>
            <edit-task-group-column :column="col"/>
          </v-tab>
        </vue-tabs>
      </div>

      <div class="pure-u-1-2 form-row">
        <button type="button" class="btn" v-show="group.specialType != -10" @click="addColumn()"><i class="fal fa-plus"></i> Столбец</button>
      </div>
      <div class="pure-u-1-2 txt-r form-row">
        <button type="submit" class="btn btn-primary"><i class="fal fa-check"></i> Сохранить</button>
      </div>
    </form>
  </modal>
</template>

<style scoped>

</style>

<script>
import VueElementLoading from 'vue-element-loading'
import {VueTabs, VTab} from 'vue-nav-tabs'
import Verte from 'verte';
import UsersPicker from './UsersPicker.vue'
import EditTaskGroupColumn from './EditTaskGroupColumn.vue'
import TaskGroup from '@/models/taskGroup'
import apiCall from "@/utils/api"

import { GROUPS_REQUEST } from "@/store/actions/project"

import 'verte/dist/verte.css';

export default {
  name: 'PopupEditTaskGroup',
  components: {
      VueElementLoading,
      Verte,
      VueTabs,
      VTab,
      EditTaskGroupColumn,
      UsersPicker
  },
  data() {
    return {
      isLoading: false,
      group: Object.assign(new TaskGroup, {})
    }
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.group = data.params

      this.$escStack.push(this.$options.name)
    },
    addColumn: function() {
      this.group.columns.push({
        order: this.group.columns.length + 1
      })
    },
    removeColumn(index){
      this.group.columns.splice(index, 1)
    },
		onSubmit: function () {
      this.isLoading = true
      let action = this.group.id ? "task-groups/edit" : "task-groups/add"
      apiCall({ url: action, method: "POST", data: this.group})
      .then(resp => {
        console.log(resp)
        this.$modal.hide(this.$options.name)
        this.$store.dispatch(GROUPS_REQUEST, { "projectId": this.group.projectId })
        this.isLoading = false
      })
		}
	}
}
</script>