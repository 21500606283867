<template>
    <div class="aside-bar">
        <div class="guest-block" v-if="!isAuthenticated">
            <div class="actions-title">
                <span>Аккаунт</span>
            </div>
            <router-link to="/login">Войти</router-link>
            <router-link to="/about">About</router-link>
        </div>
        <aside-profile class="padding-h padding-half-v" v-if="isAuthenticated"/>
        <aside-projects v-if="isAuthenticated"/>
    </div>
</template>

<style>
.aside-bar {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #F8F7F7;
}
.aside-bar .actions-title {
    background-color: #E8E7E7;
}

.aside-bar a,
.aside-bar a:active,
.aside-bar a:visited {
    display: block;
    text-decoration: none;
    padding: .25rem 1rem;
    color: #888;
    cursor: pointer;
}
.aside-bar a:hover {
    background-color: #EEE;
}
.aside-bar a.router-link-exact-active {
    color: #555;
    background-color: #EEE;
}

.aside-bar .actions-title a,
.aside-bar .actions-title a:active,
.aside-bar .actions-title a:visited,
.aside-bar .actions-title span {
    display: inline-block;
    font-size: 1.1em;
    padding: .2rem 1rem;
    font-weight: 300;
    text-decoration: none;
}
.aside-bar .actions-title i {
    color: #509E2F;
}

.aside-bar .actions-title a:hover,
.aside-bar .actions-title a.active {
    background-color: #D8D7D7;
}

.aside-bar .actions-title span {
    text-transform: uppercase;
    font-weight: 700;
    font-size: .7em;
    letter-spacing: 1px;
    line-height: 1.8;
    color: #888;
}
</style>

<script>
import { mapGetters } from "vuex";
import AsideProjects from '@/components/AsideProjects.vue'
import AsideProfile from './AsideProfile.vue';

export default {
  name: 'AsideBar',
  components: {
    AsideProjects,
    AsideProfile
  },
  computed: {
    ...mapGetters(["isAuthenticated", "authStatus"])
  }
}
</script>