<template>
	<span class="avatar-wrp">
		<span v-if="user" :title="user.fullName()">
			<img class="avatar" v-if="url" :src="url" v-bind:style="{
				'background-color': color,
				'width': side,
				'height': side,
				'line-height': side,
			}" />
			<img class="avatar" v-else-if="user.avatarUrl" :src="user.avatarUrl"  v-bind:style="{
				'background-color': color,
				'width': side,
				'height': side,
				'line-height': side,
			}" />
			<span class="avatar" v-else  v-bind:style="{
				'background-color': color,
				'width': side,
				'height': side,
				'line-height': side,
			}">{{ user.abbreviation() }}</span>
		</span>
		<span v-else class="avatar"  v-bind:style="{
				'background-color': '#EEE',
				'width': side,
				'height': side,
				'line-height': side,
			}"></span>
		<span class="badge top" v-if="msgTop">
			<span v-if="msgTop == 'msg'"><i class="fas fa-comments"></i></span>
			<span v-else-if="msgTop < 10">{{ msgTop }}</span>
			<span v-else><i class="fas fa-exclamation"></i></span>
		</span>
		<span class="badge bottom" v-if="msgBottom">
			<span v-if="msgBottom == 'msg'"><i class="fas fa-comments"></i></span>
			<span v-else-if="msgBottom < 10">{{ msgBottom }}</span>
			<span v-else><i class="fas fa-exclamation"></i></span>
		</span>
	</span>
</template>

<script>
import { mapGetters } from "vuex"

export default {
	name: 'Avatar',
	props: {
		'userid': Number,
		'url': String,
		msgTop: {
			default: 0
		},
		msgBottom: {
			default: 0
		},
		side: {
			default: '2.5rem'
		}
	},
	computed: {
		...mapGetters(["allUsersKeyed"]),
		user() {
			return this.allUsersKeyed[this.userid]
		},
		color() {
			return this.user?.role?.color ?? "transparent"
		}
	},
}
</script>

<style scoped>
.avatar-wrp {
	display: inline-block;
	position: relative;
}
.avatar {
    display: inline-block;
    border-radius: 50%;
    letter-spacing: -1px;
    text-align: center;
    color: #FFF;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}

.badge {
  display: inline-block;
  position: absolute;
  right: -.75em;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  background-color: #E40231;
  text-align: center;
  font-size: .75em;
  
  color: #FFF;
  font-weight: 700;
  border: 2px solid #FFF;
  border-radius: 50%;
}

.badge.top {
	background-color: #ff9900;
	top: -.5em;
}
.badge.bottom {
	bottom: -.5em;
}
</style>