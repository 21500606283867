<template>
  <div>
    <p class="no-margin-t" v-if="!group.id && addUnassignedTaskAllowed">
      <button class="btn" v-bind:class="{ 'btn-primary': !group.id || group.parentTaskId }" type="button"
        @click.stop="$refs.groupHeader.onAddTask()"><i class="fal fa-plus-circle"></i> новая задача</button>
    </p>
    <div class="tasks-table-wrapper" v-bind:class="{
      'children-opened': taskChildrenOpenedId,
      'this-children-opened': taskChildrenOpenedId == group.parentTaskId,
      'highlight-mine': highlightMyTasks,
    }">
      <table class="tasks-table" v-bind:class="{
        'margin-b': group.id == 0
      }">
        <col style="width: 5em; min-width: 5em;" /> <!-- id -->
        <col /> <!-- main -->
        <col v-for="col in columnsCount" :key="'column' + col" style="width: 7.5rem; min-width: 7.5rem;" />
        <!-- assigns -->

        <tr style="visibility: collapse;">
          <td></td>
          <td></td>
          <td v-for="col in columnsCount" :key="'invisible column' + col"></td>
        </tr>
        <tasks-table-group :group="group" :isOpened="isDirOpened" ref="groupHeader" v-show="isRenderHeader" v-on:openDir="dirOpened"
          v-on:closeDir="dirClosed" />

        <template v-for="(task, index) in orderedTasks">
          <!-- Строка в таблице -->
          <tasks-table-row v-show="isDirOpened && (!task.id || group.visibleTaskIds[task.id] || task.hasSubtasks)"
            :task="task" :group="group" :subgroups-opened="subgroupsOpened" :nesting="nesting + 1"
            :key="task.id + '' + index + 'row'" :is-linked="false" v-on:openChildren="childrenOpened"
            v-on:closeChildren="childrenClosed" v-on:taskDeleted="onTaskDeleted"
            v-on:openSubgroups="openAllSubgroupsForTask" />
          <!-- Подзадачи (подгруппы) у задачи -->
          <tr class="task-subgroups" :key="task.id + '' + index + 'subgroups'"
            v-show="task.id && isDirOpened && tasksChildrenOpened.indexOf(task.id) !== -1 && (group.visibleTaskIds[task.id] || task.hasSubtasks)">
            <td colspan="999" class="inner-table">

              <div class="padding-h groups-fast"
                v-bind:style="{ 'background-color': '#f1f2f3', 'border-left': '1px solid rgba(0,0,0,0.1)' }">
                <span class="btn" v-for="g in task.groups" :key="g.id + 'fast-group'" v-bind:class="{
                  'active pressed': subgroupsOpened.indexOf(g.id) !== -1,
                  'has-notifications': notifications.groups[g.id],
                  'has-chat-notifications': notifications.chatGroups[g.id]
                }" v-bind:style="{ 'background-color': g.color }" @click="toggleSubgroup(g.id)"
                v-show="!user.isCustomer || g.specialType == 30">

                  <i class="far fa-link margin-r text-green" v-if="g.specialType == -10"></i>
                  <i class="fad fa-folder-open margin-r" v-else-if="subgroupsOpened.indexOf(g.id) !== -1" v-bind:class="{
                    'text-green': g.tasks && g.tasks.length,
                    'text-grey': !g.tasks || !g.tasks.length,
                  }"></i>
                  <i class="fas fa-folder margin-r" v-else v-bind:class="{
                    'text-green': g.tasks && g.tasks.length,
                    'text-grey': !g.tasks || !g.tasks.length,
                  }"></i>
                  {{ g.name }}
                </span>

                <span class="btn" type="button" @click="onAddFastSubGroup(g, task)" v-for="(g, index) in defaultSubgroups"
                  :key="g.name + 'add-group' + index" v-show="!user.isCustomer && !task.groups.some(tg => tg.specialType == g.specialType)"
                  v-bind:style="{ 'background-color': g.color }"><i class="far fa-plus"></i> {{ g.name }}</span>
              </div>
              <template v-for="g in task.groups">
                <tasks-table :group="g" :root="root" :key="g.id" :nesting="nesting + 1" :parent="group"
                  :subdir-opened="subgroupsOpened.indexOf(g.id) !== -1" :show-empty-groups="showEmptyGroups" v-on:openDir="onSubdirOpened"
                  v-on:closeDir="onSubdirClosed" />
              </template>
            </td>
          </tr>
        </template>

        <!-- Зависимые задачи -->
        <template v-for="(task, index) in linkedTasks">
          <tasks-table-row
            v-show="isDirOpened && (!task.id || groupsKeyed[task.groupId].visibleTaskIds[task.id] || task.hasSubtasks)"
            :task="task" :group="groupsKeyed[task.groupId]" :links-group="group" :nesting="nesting + 1"
            :key="task.id + '' + index + 'row'" :is-linked="true" v-on:openChildren="childrenOpened"
            v-on:closeChildren="childrenClosed" v-on:taskDeleted="onTaskDeleted" />
        </template>
      </table>

      <!-- Подгруппы у группы -->
      <template v-for="g in group.groups">
        <tasks-table :group="g" :root="root" :key="g.id" :nesting="nesting + 1" :parent="group" :subdir-opened="true" :show-empty-groups="showEmptyGroups" />
      </template>
    </div>
  </div>
</template>


<script>
import uuidv4 from '@/utils/uuidv4'
import apiCall from "@/utils/api"
import { ADD_TASK_UNASSIGNED } from "@/utils/permissions"
import { mapGetters } from "vuex";

import TasksTableGroup from './TasksTableGroup.vue'
import TasksTableRow from './TasksTableRow.vue';

export default {
  name: 'TasksTable',
  components: {
    TasksTableGroup,
    TasksTableRow
  },
  props: ['group', 'root', 'parent', 'nesting', 'subdirOpened', 'showEmptyGroups'],
  data() {
    return {
      filtersLocal: this.filters,
      isDirOpened: this.subdirOpened,
      tasksChildrenOpened: [],
      subgroupsOpened: [],

      defaultSubgroups: [
        {
          id: 0,
          name: "В составе",
          color: "#E9FFE8",
          order: -99,
          pendingId: uuidv4(),
          specialType: 10,
          IsDefaultOpen: true,
          tasks: [],
          groups: [],
          columns: this.group.columns.map(c => Object.assign({}, c, { id: 0 })),
          managerIds: this.group.managerIds,
        },
        {
          id: 0,
          name: "Подзадачи",
          color: "#CCE4FF",
          order: -98,
          pendingId: uuidv4(),
          specialType: 20,
          isDefaultOpen: false,
          tasks: [],
          groups: [],
          columns: this.group.columns.map((c) => {
            let column = Object.assign({}, c, { id: 0 })
            column.rolesAssignable = [] // everyone can be initiator
            if (column.name == 'Заказчик') {
              column.columnType = 60
              column.name = 'Инициатор'
            }
            return column
          }),
          managerIds: this.group.managerIds,
        },
        {
          id: 0,
          name: "Заказчику",
          color: "#e2d68f",
          order: -97,
          pendingId: uuidv4(),
          specialType: 30,
          isDefaultOpen: false,
          tasks: [],
          groups: [],
          columns: this.group.columns.map(c => Object.assign({}, c, { id: 0 })),
          managerIds: this.group.managerIds,
        },
      ]
    }
  },
  watch: {
    subdirOpened(newVal) {
      this.isDirOpened = newVal
    }
  },
  mounted() {
    this.$sodnpHub.$on('task-updated', this.onTaskUpdated)

    let defaultOpenedDirs = this.group.groups.filter(g => g.isDefaultOpen == true).map(g => g.id)
    for (var i = 0; i < this.group.tasks.length; i++) {
      let t = this.group.tasks[i]
      defaultOpenedDirs = defaultOpenedDirs.concat(t.groups.filter(g => g.isDefaultOpen == true && (!this.user.isCustomer || g.specialType == 30)).map(g => g.id))
    }

    this.subgroupsOpened = defaultOpenedDirs
  },
  beforeDestroy: function () {
    this.$sodnpHub.$off('task-updated', this.onTaskUpdated)
  },
  computed: {
    ...mapGetters(["authId", "allUsersKeyed", "authRoles", "activeProject", "highlightMyTasks", "groupsKeyed", "tasksKeyed", "taskChildrenOpenedId", "notifications", "customerRoles"]),
    user() {
			return this.allUsersKeyed[this.authId]
		},
    isCustomer() {
      return this.customerRoles.includes(this.user.roleId)
    },
    bgColor() {
      return this.parent && this.parent.color ? this.parent.color : ''
    },
    orderedTasks() {
      return [...this.group.tasks].sort(this.tasksCompare)
    },
    isRenderHeader() {
      return (this.group.id == this.root.id && this.showEmptyGroups) || 
      (!this.group.parentTaskId && this.group.id != this.root.id && (this.group.tasks.length != 0 || this.showEmptyGroups)) || 
      (!this.group.parentGroupId && this.group.hasSubtasks) ||
      (!this.group.parentTaskId && this.group.hasSubtasks) ||
      (this.group.parentTaskId && this.isDirOpened) ||
      (this.group.groups.length == 0 && this.group.tasks.length == 0 && !this.group.specialType == -10)
    },
    linkedTasks() {
      let tasks = []
      if (this.group.specialType != -10) {
        return tasks
      }
      let task = this.tasksKeyed[parseInt(this.group.parentTaskId)]
      if (!task) {
        return tasks
      }
      for (var i = 0; i < task.linksIn.length; i++) {
        tasks.push(this.tasksKeyed[parseInt(task.linksIn[i])])
      }
      return tasks
    },
    columnsCount() {
      if (this.group.specialType != -10) {
        return this.group.columns.filter(c => c.rolesInvisible.length == 0 || !this.isCustomer).length
      }
      let maxColumnsCount = 0
      let task = this.tasksKeyed[parseInt(this.group.parentTaskId)]
      if (!task) {
        return maxColumnsCount
      }
      for (var i = 0; i < task.linksIn.length; i++) {
        let t = this.tasksKeyed[parseInt(task.linksIn[i])]
        let groupsInTask = this.groupsKeyed[t.groupId].columns.filter(c => c.rolesInvisible.length == 0 || !this.isCustomer)
        maxColumnsCount = Math.max(maxColumnsCount, groupsInTask.length)
      }
      return maxColumnsCount
    },
    addUnassignedTaskAllowed() {
      return this.authRoles.indexOf(ADD_TASK_UNASSIGNED) !== -1
    }
  },
  methods: {
    onTaskDeleted(pendingId) {
      let index = this.group.tasks.findIndex(t => t.pendingId == pendingId)
      this.group.tasks.splice(index, 1)
    },
    dirOpened() {
      this.isDirOpened = true
      this.$emit('openDir', this.group.id)
    },
    dirClosed() {
      this.isDirOpened = false
      this.$emit('closeDir', this.group.id)
    },
    onSubdirOpened(id) {
      this.subgroupsOpened.push(id)
    },
    onSubdirClosed(id) {
      let i = this.subgroupsOpened.indexOf(id)
      if (i >= 0) {
        this.subgroupsOpened.splice(i, 1)
      }
    },
    toggleSubgroup(groupId) {
      let i = this.subgroupsOpened.indexOf(groupId)
      if (i !== -1) {
        //this.subgroupsOpened.splice(i, 1)
      } else {
        for (var id of this.subgroupsOpened) {
          this.$emit('closeDir', id)
        }
        //this.subgroupsOpened.push(groupId)
        this.subgroupsOpened = [groupId]
      }
    },
    childrenOpened(taskId) {
      this.tasksChildrenOpened.push(taskId)
    },
    childrenClosed(taskId) {
      let index = this.tasksChildrenOpened.findIndex(id => id == taskId)
      this.tasksChildrenOpened.splice(index, 1)
    },
    openAllSubgroupsForTask(subgroupIds) {
      for (var i = 0; i < subgroupIds.length; i++) {
        this.toggleSubgroup(subgroupIds[i])
      }
    },

    onAddFastSubGroup(group, task) {
      group.parentGroupId = this.group.id
      group.parentTaskId = task.id
      group.projectId = task.projectId
      apiCall({ url: "task-groups/add", method: "POST", data: group })
        .then(() => {
          /*if (!this.isChildrenOpened) {
            this.toggleChildren()
          }*/
        })
    },
    onTaskUpdated(data) {
      if (data.openSubgroup && data.initiatorId == this.authId && this.group.id == data.groupId && this.subgroupsOpened.indexOf(data.groupId) === -1) {
        this.toggleSubgroup(data.openSubgroup)
      }
    },

    tasksCompare(t1, t2) {
      const ft1 = parseFloat(t1.visibleId),
        ft2 = parseFloat(t2.visibleId)
      if (ft1 && ft2) {
        return ft1 < ft2 ? -1 : 1
      }
      if (t1.visibleId.length < t2.visibleId.length) {
        return -1
      }
      if (t1.visibleId.length > t2.visibleId.length) {
        return 1
      }
      return t1.visibleId.localeCompare(t2.visibleId)
    }
  }
}
</script>


<style>
.tasks-table-wrapper {
  /*border-left: 1px solid transparent;*/
  box-sizing: border-box;
}

.tasks-table-wrapper .tasks-table-wrapper {
  /*border-color: rgba(0, 0, 0, .1);*/
}

.tasks-table-wrapper tr {
  transition: opacity .2s ease-in-out;
}

.tasks-table-wrapper.children-opened tr {
  opacity: 0.15;
}

.tasks-table-wrapper.children-opened tr.task-subgroups,
.tasks-table-wrapper.children-opened.this-children-opened tr,
.tasks-table-wrapper.children-opened tr.this-children-opened {
  opacity: 1;
}

/* 
.tasks-table .task.this-children-opened > td {
  border-top-width: 3px;
}
.tasks-table .task.this-children-opened > td:first-of-type {
  box-shadow: inset 3px -2px 0px -2px;
} */



.tasks-table-wrapper .tasks-table-wrapper>table {
  position: relative;
  /*left: 1px;*/
  /*top: -.5px;*/
}

.tasks-table {
  position: relative;
  overflow: auto;
  width: 100%;
  border-collapse: collapse;
}

.tasks-table,
.tasks-table tr,
.tasks-table td {
  box-sizing: border-box;
}

.tasks-table .header {
  z-index: 1;
  height: 2rem;
  font-size: .8em;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}

.tasks-table .header td {
  padding: .25em .5em;
  border: 1px solid #666;
  border-left-width: 2px;
  border-right-width: 2px;
  font-weight: 700;
}

.tasks-table .header .btn,
.tasks-table .inline-actions .btn {
  font-size: .8rem;
  padding: .15em .25em;
  margin: 0 .1rem;
  color: inherit;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.05);
}

.tasks-table .header .btn.active,
.tasks-table .inline-actions .btn.active {
  background-color: #daedde;
}

.tasks-table .header .btn.btn-primary {
  padding: .15em .5em .15em .25em;
  background-color: #509E2F;
  border-color: #509E2F;
  color: #FFF;
}

.tasks-table .inline-actions .btn i {
  position: relative;
  top: .1em;

  display: inline-block;
  width: 1.5rem;
  font-size: 1rem;
}

.tasks-table .inline-actions.hover {
  display: none;
}

.tasks-table .inline-actions.hover>* {
  display: inline-block;
}

.tasks-table .inline-actions-wrp:hover>.inline-actions.hover {
  display: block;
}

.tasks-table .inner-table {
  padding: 0 0 1em 0;
  border: 1px solid transparent;
  border-left: none;
  border-right: none;
  background-color: rgb(241, 242, 243);
}

.tasks-table .task {
  transition: all .2s ease-in-out;
}

.tasks-table .task .cell {
  position: relative;
}

.tasks-table .task .cell.name,
.tasks-table .task .cell.uid {
  cursor: pointer;
}

.tasks-table .task td {
  height: 2.2em;
  font-size: .9rem;
  border: 1px solid #666;
  border-left-width: 2px;
  border-right-width: 2px;
  background-color: #FFF;
  transition: all .2s;
}

.tasks-table .task.outsource td {
  background-color: #C1F1FF;
}

.tasks-table .task.deleted td {
  background-color: #FFF6E8;
}

.tasks-table-wrapper.highlight-mine .tasks-table .task.mine td.cell.uid,
.tasks-table-wrapper.highlight-mine .tasks-table .task.mine td.cell.name {
  background-color: #eae2ff;
}

.tasks-table .task.filtered-out td {
  background-color: #F7F7F7;
  color: rgba(0, 0, 0, .4);
}

.tasks-table .task.filtered-out:hover td {
  color: #000;
}

.tasks-table .task.not-saved td {
  background-color: #fffaba;
  color: #000;
}

.tasks-table .task.outsource td .outsource-name,
.tasks-table .task td .linked-name {
  display: block;
  font-size: .75em;
  font-style: italic;
}

.tasks-table .task .cell.name .flex-row {
  min-height: 1em;
}

.tasks-table .task:hover td {
  box-shadow: inset 0px 0px 20em rgba(146, 172, 255, .18);
}

.tasks-table .children-folder-icon {
  display: inline-block;
  width: 1.2em;
  color: #509E2F;
}

.groups-fast {
  padding-left: 5rem;
  display: flex;
  flex-direction: row;
}

.groups-fast .btn {
  border-radius: 0;
  padding: .33rem 1rem;
  font-size: .7em;
  font-weight: 600;
  opacity: .4;

  position: relative;
  bottom: -1px;

  border: 1px solid transparent;
  border-bottom: 0;
  position: relative;
  z-index: 2;
  -moz-border-radius: 5px 5px 0 0;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

.groups-fast .btn:first-of-type {
  /*border-bottom-left-radius: .75em;*/
}

.groups-fast .btn:last-of-type {
  /*border-bottom-right-radius: .75em;*/
}

.groups-fast .btn.active {
  opacity: 1;
}

.groups-fast .btn.active {
  border: 1px solid #000;
  border-bottom: 0;
}

.groups-fast .btn.active:after,
.groups-fast .btn.active:before {
  /*content:'';*/
  width: 10px;
  height: 5px;
  border: 1px solid #000;
  position: absolute;
  bottom: 2px;
  border-top: 0;
}

.groups-fast .btn.active:after {
  border-left: 0;
  -moz-border-radius: 0 0 5px 0;
  -webkit-border-radius: 0 0 5px 0;
  border-radius: 0 0 5px 0;
  left: -11px;
}

.groups-fast .btn.active:before {
  border-right: 0;
  -moz-border-radius: 0 0 0 5px;
  -webkit-border-radius: 0 0 0 5px;
  border-radius: 0 0 0 5px;
  right: -11px;
}

.groups-fast .btn.has-chat-notifications i,
.groups-fast .btn.has-chat-notifications i.text-green {
  color: #ff9900 !important;
}

.groups-fast .btn.has-notifications i,
.groups-fast .btn.has-notifications i.text-green {
  color: #E40231 !important;
}

.groups-fast .btn.has-notifications.has-chat-notifications i,
.groups-fast .btn.has-notifications.has-chat-notifications i.text-green {
  text-shadow: 0.5em -0.25em #ff9900;
}
</style>