<template>
  <modal name="PopupEditGalleryImage" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :scrollable="true" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">Изменение фотографии</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <form class="pure-g content with-overflow padding-h padding-half-v" @submit.prevent="onSubmit">
      <vue-element-loading :active="isLoading"/>
      <div class="pure-u-1-3 txt-c padding-r padding-t">
        <img class="rounded z-1" :src="image.thumbUrl" alt="" />
      </div>
      <div class="pure-u-2-3">
        <div class="form-row">
          <label>Название</label>
          <input required v-model="image.name" type="text" placeholder="Название фото"/>
        </div>
        <div class="form-row">
          <label>Описание</label>
          <textarea v-model="image.description" rows="5" placeholder="Описание"></textarea>
        </div>
        <div class="form-row">
          <label>Дата</label>
          <v-date-picker v-model="image.createdAt">
            <template v-slot="{ inputValue, inputEvents }">
              <input type="text" placeholder="Не задана" :value="inputValue" v-on="inputEvents"/>
            </template>
          </v-date-picker>
        </div>
      </div>
      <div class="pure-u-1 txt-r form-row">
        <button type="submit" class="btn btn-primary"><i class="fal fa-check"></i> Сохранить</button>
      </div>
    </form>
  </modal>
</template>

<style scoped>
.rounded {
  border-radius: 1em;
}
</style>

<script>
import VueElementLoading from 'vue-element-loading'
import apiCall from "@/utils/api"


export default {
  name: 'PopupEditGalleryImage',
  components: {
    VueElementLoading,
  },
  data() {
    return {
      isLoading: false,
      image: {
        projectId: 0,
        projectGalleryId: 0,
        name: "",
        description: "",
        createdAt: "",
        thumbUrl: ""
      }
    }
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen: function(data) {
      this.image = data.params

      this.$escStack.push(this.$options.name)
    },
		onSubmit: function () {
      this.isLoading = true
      let action = "projects/" + this.image.projectId + "/galleries/image/edit"
      apiCall({ url: action, method: "POST", data: this.image})
      .then(() => {
        this.$modal.hide(this.$options.name)
        this.isLoading = false
      })
		}
	}
}
</script>