<template>
  <modal name="PopupAboutProfile" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :width="450" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">Данные о пользователе</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    
    <div class="content padding pure-g" @submit.prevent="onSubmit">
      <div class="pure-u-5-24 form-row">
        <avatar :userid="userId" :url="user.avatarUrl" />
      </div>

      <div class="pure-u-19-24 padding-r">
        <div class="pure-g container contacts">
          <div class="pure-u-1-3" v-if="user && user.id">
            <b>ФИО</b>
          </div>
          <div class="pure-u-2-3">
            {{ user.lastName }}
            {{ user.firstName }}
            {{ user.middleName }}
          </div>

          <div class="pure-u-1-3" v-if="user.specialization">
            <b>Отдел</b>
          </div>
          <div class="pure-u-2-3" v-if="user.specialization">
           {{ user.specialization.name }}
          </div>

          <div class="pure-u-1-3" v-if="user.role">
            <b>Специализация</b>
          </div>
          <div class="pure-u-2-3" v-if="user.role">
           {{ user.role.name }}
          </div>

          <div class="pure-u-1 margin-t"></div>

          <div class="pure-u-1-3" v-if="user.email">
            <b>Email</b>
          </div>
          <div class="pure-u-2-3" v-if="user.email">
           <a :href="'mailto:' + user.email"><i class="fal fa-at"></i> {{ user.email }}</a>
          </div>

          <div class="pure-u-1-3" v-if="user.phone">
            <b>Телефон</b>
          </div>
          <div class="pure-u-2-3" v-if="user.phone">
           <a :href="'tel:' + user.phone"><i class="fal fa-phone-alt"></i> {{ user.phone }}</a>
          </div>

          <div class="pure-u-1-3" v-if="user.skype">
            <b>Skype</b>
          </div>
          <div class="pure-u-2-3" v-if="user.skype">
            <a :href="'skype:' + user.skype">
              <i class="fab fa-skype text-blue-skype"></i>
              {{ user.skype }}
            </a>
          </div>

          <div class="pure-u-1-3" v-if="user.whatsapp">
            <b>WhatsApp</b>
          </div>
          <div class="pure-u-2-3" v-if="user.whatsapp">
           <a target="_blank" :href="'https://api.whatsapp.com/send?phone=' + user.whatsapp">{{ user.whatsapp }}</a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<style scoped>
.contacts > div {
  padding: .25em 0;
}
.contacts b {
  font-size: .8rem;
}

.contacts a, .contacts a:active, .contacts a:visited {
  color: #005eaf;
  text-decoration: none;
}
.contacts a:hover {
  text-decoration: underline;
  color: #004886;
}

.contacts i {
  display: inline-block;
  width: 1.3rem;
}
</style>

<script>
import { mapGetters } from "vuex";
import Avatar from '@/components/Avatar.vue';

export default {
  name: 'PopupAboutProfile',
  components: {
    Avatar
  },
  data() {
    return {
      userId: null,
      user: {}
    }
  },
  computed: {
    ...mapGetters(["allUsersKeyed"]),
  },
  methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.userId = data.params
      this.$set(this, 'user', this.allUsersKeyed[this.userId])
      console.log(this.user)
      this.$escStack.push(this.$options.name)
    }
	}
}
</script>