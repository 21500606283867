<template>
  <div class="stage-wrp" v-bind:class="{'mine': stage.profileId == this.authId}">
    <avatar v-if="createdBy" :userid="createdBy.id" class="margin-half-h" />
    <div class="stage" v-bind:class="stageClass">
      <div class="header">
        <span class="title">
          <span class="user btn" v-if="createdBy" @click="$modal.show('PopupAboutProfile', createdBy.id)">
            <i class="fal fa-user margin-half-r"></i>
            {{ createdBy.fullName() }}
          </span>
          <span class="user btn" v-else>
            <i class="fal fa-desktop margin-half-r"></i>
            Системное сообщение
          </span>

          <button v-if="hiddenShown && canHideStages && !stage.isHidden" class="btn btn-small btn-trash" @click="toggleHidden">
            <i class="far fa-trash-alt"></i> Скрыть
          </button>
          <button v-else-if="hiddenShown && canHideStages" class="btn btn-small btn-trash-disable" @click="toggleHidden">
            <i class="far fa-trash-alt"></i> Показать
          </button>
        </span>

        <span class="margin-half-l smaller" v-if="showTask">
          <b>{{ project ? project.name + ' - ' : '' }}</b>
          <i v-if="group && group.parentTask">{{ group.parentTask.name }} - </i>
          <i>{{ group ? group.name + ' - ' : '' }}</i>
          <b>{{ task.name }}</b>
          <a class="line-inverse pull-right" href="javascript:" @click.prevent="startShowingTask()"><i class="far fa-external-link"></i> перейти к задаче</a>
        </span>
      </div>
      <div class="content" :class="{'content-80': !showTask}">
        <div class="actions">
          <span @click="confirmDeadline(true)" v-if="stage.isDeadlineChangeRequest && notifications.stages[stage.id] && notifications.stages[stage.id].length" class="notification btn btn-primary" title="Подтвердить новый срок">
            <i class="fal fa-check margin-half-h"></i>
          </span>
          <span @click="confirmDeadline(false)" v-if="stage.isDeadlineChangeRequest && notifications.stages[stage.id] && notifications.stages[stage.id].length" class="notification btn btn-destruct" title="Отклонить новый срок">
            <i class="fal fa-times margin-half-h"></i>
          </span>

          <span @click="readNotification(stage.id)" v-if="stage.type == 10 && notifications.stages[stage.id] && notifications.stages[stage.id].length" class="notification btn btn-primary" title="Подтвердить срок">
            <i class="fal fa-check margin-half-h"></i>
          </span>
          <span @click="$modal.show('PopupAddStage', {
            cell: cellFrom,
            cellIndex: -1,
            task: task,
            column: null,
            group: group
          }); readNotification(stage.id)" v-if="stage.type == 10 && notifications.stages[stage.id] && notifications.stages[stage.id].length" class="notification btn btn-destruct" title="Сообщить об изменении в сроках">
            <i class="fal fa-times margin-half-h"></i>
          </span>

          <span @click="readNotification(stage.id)" v-if="stage.type != 10 && !stage.isDeadlineChangeRequest && notifications.stages[stage.id] && notifications.stages[stage.id].length" class="notification btn btn-orange" title="Пометить прочитанным">
            <i class="far fa-envelope-open margin-half-h"></i>
          </span>
        </div>

        <div class="body">
          <div class="margin-half nl2br" :title="createdAt(stage.createdAt)">
            <i class="fad fa-clock text-blue"></i>
            <span class="content-after-icon">{{ createdAtBeauty(stage.createdAt) }}</span>
          </div>

          <div class="margin-half nl2br" v-if="stage.description">
            <i class="fad fa-comment-lines text-blue"></i>
            <span class="content-after-icon" v-linkified>{{ stage.description }}</span>
          </div>
          <div class="margin-half nl2br" v-else-if="stage.type == -80">
            <i class="fad fa-comment-lines text-blue"></i>
            <span class="content-after-icon">Создана новая задача</span>
          </div>
          <div class="margin-half nl2br" v-else-if="stage.type == 10">
            <i class="fad fa-calendar-alt calendar"></i>
            <span class="content-after-icon">
              Необходимо подтвердить срок выполнения задачи.
              <span v-if="stage.deadlineBefore">
                Текущая дата завершения:
                <b>{{ createdAt(stage.deadlineBefore, "DD.MM.YYYY") }}</b>.<br/>
                <span v-if="daysUntilDeadlineBefore > 0">То есть на текущий момент до завершения задачи осталось <b>{{ daysUntilDeadlineBefore|daysLeft }}</b></span>
                <span v-else>То есть на текущий момент задача уже просрочена</span>
              </span>
            </span>
          </div>
          <div class="margin-half nl2br" v-else-if="stage.actualHoursAfter - stage.actualHoursBefore">
            <i class="fad fa-comment-lines text-blue"></i>
            <span class="content-after-icon">Учтено время работы {{ stage.actualHoursAfter - stage.actualHoursBefore }}ч</span>
          </div>
          <div class="margin-half" v-if="stage.deadlineBefore != stage.deadlineAfter || stage.expectedHoursBefore != stage.expectedHoursAfter">
            <i class="fad fa-calendar-alt calendar"></i>
            <span class="content-after-icon">
              <span v-if="stage.deadlineBefore != stage.deadlineAfter && stage.isDeadlineChangeRequest && stage.deadlineBefore && stage.deadlineAfter">
                Запрос на изменение даты завершения c <b>{{ createdAt(stage.deadlineBefore, "DD.MM.YYYY") }}</b> на <b>{{ createdAt(stage.deadlineAfter, "DD.MM.YYYY") }}</b><br/>
              </span>
              <span v-else-if="stage.deadlineBefore != stage.deadlineAfter && stage.isDeadlineChangeRequest">
                Запрос установки даты завершения на <b>{{ createdAt(stage.deadlineAfter, "DD.MM.YYYY") }}</b><br/>
              </span>

              <span v-else-if="stage.deadlineBefore != stage.deadlineAfter && stage.deadlineBefore && stage.deadlineAfter">
                Дата завершения с <b>{{ createdAt(stage.deadlineBefore, "DD.MM.YYYY") }}</b> изменена на <b>{{ createdAt(stage.deadlineAfter, "DD.MM.YYYY") }}</b><br/>
              </span>
              <span v-else-if="stage.deadlineBefore != stage.deadlineAfter && stage.deadlineBefore">
                Дата завершения была <b>{{ createdAt(stage.deadlineBefore, "DD.MM.YYYY") }}</b>, а теперь <b>не указана</b><br/>
              </span>
              <span v-else-if="stage.deadlineBefore != stage.deadlineAfter && stage.deadlineAfter">
                Дата завершения назначена на <b>{{ createdAt(stage.deadlineAfter, "DD.MM.YYYY") }}</b><br/>
              </span>
              
              <span v-if="stage.expectedHoursBefore != stage.expectedHoursAfter && stage.expectedHoursBefore && stage.expectedHoursAfter">
                Планируемый срок на выполнение с <b>{{ stage.expectedHoursBefore|hoursLeft }}</b> был изменен на <b>{{ stage.expectedHoursAfter|hoursLeft }}</b><br/></span>
              <span v-else-if="stage.expectedHoursBefore != stage.expectedHoursAfter && stage.expectedHoursBefore">Планируемая срок выполнения был <b>{{ stage.expectedHoursBefore|hoursLeft }}</b>, а теперь <b>не указан</b><br/></span>
              <span v-else-if="stage.expectedHoursBefore != stage.expectedHoursAfter && stage.expectedHoursAfter">Планируемый срок выполнения установлен на <b>{{ stage.expectedHoursAfter|hoursLeft }}</b><br/></span>
            </span>
          </div>

          <!-- ЧЕКЛИСТ -->
          <div class="margin-half" v-if="isCheckListChanged">
            <i class="fas fa-tasks"></i>
            <span class="content-after-icon"> Чеклист по задаче был изменен:
              <div v-for="elem in fullCheckList" :key="'checkListAfter' + elem.id" class="checklist-entry" :class="{
                'created': checkListCreatedIds.includes(elem.id),
                'deleted': checkListDeletedIds.includes(elem.id),
                'checked': checkListCheckedIds.includes(elem.id),
                'unchecked': checkListUncheckedIds.includes(elem.id),
              }">
                <i :class="{
                  'fal fa-square': !elem.completed,
                  'fad fa-check-square': elem.completed
                }"></i>
                <span>
                  {{ elem.number }} - {{ elem.description }}
                </span>
                <span v-if="checkListCreatedIds.includes(elem.id)">(новый элемент)</span>
                <span v-if="checkListDeletedIds.includes(elem.id)">(элемент удален)</span>
                <span v-if="checkListCheckedIds.includes(elem.id)">(выполнено)</span>
                <span v-if="checkListUncheckedIds.includes(elem.id)">(отмена выполнения)</span>
              </div>
            </span>
          </div>

          <!-- ОТВЕТСТВЕННЫЕ -->
          <div class="margin-half" v-if="assignsAdded.length">
            <i class="fad fa-user-plus text-green"></i>
            <span class="content-after-icon">
              Новые ответственные:
              <span class="btn user margin-half-h" v-for="assign in assignsAdded" :key="assign.id + 'assign-added'"
                @click="$modal.show('PopupAboutProfile', assign.id)"
                v-bind:style="{'background-color': assign.bgColor(), 'color': getTextColor(assign.bgColor())}">
                {{ columnFrom.name }} - {{ assign.fullName() }}
              </span>
            </span>
          </div>
          <div class="margin-half" v-if="assignsRemoved.length">
            <i class="fad fa-user-minus text-red"></i>
            <span class="content-after-icon">
              Удалённые ответственные:
              <span class="btn user margin-half-h" v-for="assign in assignsRemoved" :key="assign.id + 'assign-removed'"
                @click="$modal.show('PopupAboutProfile', assign.id)"
                v-bind:style="{'background-color': assign.bgColor(), 'color': getTextColor(assign.bgColor())}">
                {{ columnFrom.name }} - {{ assign.fullName() }}
              </span>
            </span>
          </div>

          <!-- ФАЙЛЫ -->
          <div class="margin-half flex-row" v-if="addedFilesInformational.length">
            <i class="fad fa-folder-plus text-green"></i>
            <span class="content-after-icon flex-expand">
              Добавлены информационные файлы:
              <span class="pure-g container full-width">
                <span class="pure-u-1-2 margin-half-v" v-for="f in addedFilesInformational" :key="f.id + 'added-inform-file'">
                  <div class="file-wrapper" :title="f.name">
                    <file-entry :file="f" />
                  </div>
                </span>
              </span>
            </span>
          </div>
          <div class="margin-half flex-row" v-if="addedFiles.length">
            <i class="fad fa-folder-plus text-green"></i>
            <span class="content-after-icon flex-expand">
              Добавлены файлы:
              <span class="pure-g container full-width">
                <span class="pure-u-1-2 margin-half-v" v-for="f in addedFiles" :key="f.id + 'added-file'">
                  <div class="file-wrapper" :title="f.name">
                    <file-entry :file="f" />
                  </div>
                </span>
              </span>
            </span>
          </div>

          <div class="margin-half flex-row" v-if="approvedFiles.length">
            <i class="fad fa-folder-plus text-green"></i>
            <span class="content-after-icon flex-expand">
              Принятые файлы:
              <span class="pure-g container full-width">
                <span class="pure-u-1-2 margin-half-v" v-for="f in approvedFiles" :key="f.id + 'added-inform-file'">
                  <div class="file-wrapper" :title="f.name">
                    <file-entry :file="f" />
                  </div>
                </span>
              </span>
            </span>
          </div>

          <div class="margin-half flex-row" v-if="stage.removedFiles.length">
            <i class="fad fa-folder-minus text-red"></i>
            <span class="content-after-icon flex-expand">
              Удалены файлы:
              <span class="pure-g container full-width">
                <span class="pure-u-1-2 margin-half-v" v-for="f in stage.removedFiles" :key="f.id + 'removed-file'">
                  <div class="file-wrapper" :title="f.name">
                    <file-entry :file="f" />
                  </div>
                </span>
              </span>
            </span>
          </div>
        </div>

        <div class="footer pure-g">
          <span class="pure-u-1 pure-u-md-1-2 smaller block-vcenter">
            <span v-if="title != ''">
              <b v-if="columnTo && stage.direction == -20">{{ columnTo.name }} <i class="fal fa-long-arrow-left margin-half-h"></i></b>
              <b v-if="columnFrom">{{ columnFrom.name }}</b>
              <b v-if="columnTo && stage.direction == 20"><i class="fal fa-long-arrow-right margin-half-h"></i> {{ columnTo.name }}</b>
              <b v-if="columnFrom || columnTo">:</b>
              <span class="margin-half-l">{{ title }}</span>
            </span>
          </span>
          <span class="pure-u-1 pure-u-md-1-2 txt-r block-vcenter">
            <span class="progress margin-r" v-if="isHoursChanged" title="Временные затраты">
              <i class="fal fa-hourglass-half"></i>
              {{ stage.actualHoursAfter|hoursLeft }}
                <span v-if="cellFrom.expectedHours"> / {{ cellFrom.expectedHours|hoursLeft }}</span>
              <span class="up">
                <i class="fal fa-plus"></i> {{ stage.actualHoursAfter - stage.actualHoursBefore|hoursLeft }}
              </span>
            </span>
            <span class="progress margin-r" v-if="isProgressChanged" title="Процент выполнения задачи">
              <i class="fal fa-chart-line"></i>
              {{ stage.progressAfter }}%
              <span class="up" v-if="stage.progressAfter > stage.progressBefore">
                <i class="fad fa-chevron-double-up"></i> {{ stage.progressAfter - stage.progressBefore }}
              </span>
              <span class="down" v-else>
                <i class="fad fa-chevron-double-down"></i> {{ stage.progressBefore - stage.progressAfter }}
              </span>
            </span>
            
            <tippy :interactive="true" placement="bottom-end" trigger="click" :maxWidth="500" theme="light sodnp" ref="readUnreadTippy" :onShow="onShowWhoRead">
              <template v-slot:trigger>
                <span class="notification btn who-read"><i class="fad fa-eye margin-half-r"></i> инфо о прочтении</span>
              </template>

              <vue-element-loading :active="isLoadingWhoRead"/>
              <div class="team-users">
                <span class="read-unread-title read" v-if="notificationReadIds.length"><i class="far fa-eye text-green"></i> Прочитали</span>
                <user-entry :id="uid" v-for="uid in notificationReadIds" :key="'read' + uid" />
                <span class="read-unread-title unread" v-if="notificationUnreadIds.length"><i class="far fa-eye-slash text-red"></i> Еще не прочитали</span>
                <user-entry :id="uid" v-for="uid in notificationUnreadIds" :key="'unread' + uid" />
              </div>
            </tippy>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import apiCall from "@/utils/api"
import textColor from '@/utils/textColor'
import {TASK_SHOW_START, TASK_SHOW_STOP } from "@/store/actions/project"
import { NOTIFICATIONS_REQUEST } from "@/store/actions/notifications"
import { MANAGE_STAGES } from "@/utils/permissions"
import { FILE_TYPE_WORKING, FILE_TYPE_FINAL, FILE_TYPE_INFORMATIONAL } from "@/utils/const"
import VueElementLoading from 'vue-element-loading'
import FileEntry from '@/components/FileEntry.vue'
import UserEntry from '@/components/UserEntry.vue';
import Avatar from '@/components/Avatar.vue';

export default {
  name: 'StageEntry',
  props: ['stage', 'task', 'project', 'group', 'showTask', 'hiddenShown'],
  emits: ['hidden'],
  components: {
    VueElementLoading,
    FileEntry,
    UserEntry,
    Avatar
  },
  data() {
    return {
      interval: null,
      lastTimesUpdatedAt: null,
      isLoadingWhoRead: false,
      notificationReadIds: [],
      notificationUnreadIds: [],
    }
  },
  mounted() {
    this.interval = setInterval( () => {
      this.lastTimesUpdatedAt = Date();
    }, 30000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  computed: {
    ...mapGetters(["authId", "authRoles", "notifications", "allUsersKeyed"]),
    title() {
      if (this.stage.direction == 0) { // остались на самой ячейке
        if (this.stage.type == 0) { // updated
          return ''
        } else if (this.stage.type == 10) { // date confirm warning
          return 'Необходимо подтвердить срок'
        } else if (this.stage.type == 20) { // started
          return 'Работа начата'
        } else if (this.stage.type == -20) { // paused
          return 'Работа приостановлена'
        } else if (this.stage.type == -50) { // stopped
          return 'Работа остановлена'
        } else if (this.stage.type == -80) { // created
          return 'Задача создана'
        } else if (this.stage.type == -85) { // restored
          return 'Задача восстановлена из удаленных'
        } else if (this.stage.type == -90) { // remove
          return 'Задача удалена'
        }
      } else if (this.stage.direction == 20) { // forward
        return 'Задача выполнена и передана дальше' // completed and moved
      } else if (this.stage.direction == -20) { // backward
        return 'Задача возвращена на доработку' // returned
      }
      return ''
    },
    approvedFiles() {
      return this.stage.approvedFiles
    },
    addedFiles() {
      return this.stage.addedFiles.filter(f => f.type == FILE_TYPE_WORKING || f.type == FILE_TYPE_FINAL)
    },
    addedFilesInformational() {
      return this.stage.addedFiles.filter(f => f.type == FILE_TYPE_INFORMATIONAL)
    },
    createdBy() {
      return this.stage.profileId ? this.allUsersKeyed[parseInt(this.stage.profileId)] : null
    },
    cellFrom() {
      return this.task.cells.find(c => c.id == this.stage.cellFromId)
    },
    cellTo() {
      return this.task.cells.find(c => c.id == this.stage.cellToId)
    },
    columnFrom() {
      if (!this.cellFrom) {
        return null
      }
      if (this.task.group && this.task.group.columns) {
        return this.task.group.columns.find(c => c.id == this.cellFrom.columnId)
      }
      return this.group.columns.find(c => c.id == this.cellFrom.columnId)
    },
    columnTo() {
      if (!this.cellTo) {
        return null
      }
      if (this.task.group && this.task.group.columns) {
        return this.task.group.columns.find(c => c.id == this.cellTo.columnId)
      }
      return this.group.columns.find(c => c.id == this.cellTo.columnId)
    },
    stageClass() {
      let classes = []
      if (this.stage.profileId == this.authId) {
        classes.push('mine')
      }

      if (this.stage.type == 20) { // started
        classes.push('waiting')
      } else if (this.stage.type == 90 || this.stage.type == 91) { // completed
        classes.push('done')
      } else if (this.stage.type == -90 || this.stage.type == -50 || this.stage.type == -20) { // stopped
        classes.push('failed')
      }
      return classes
    },
    isProgressChanged() {
      return this.stage.progressAfter && this.stage.progressBefore != this.stage.progressAfter
    },
    isHoursChanged() {
      return this.stage.actualHoursAfter && this.stage.actualHoursBefore != this.stage.actualHoursAfter
    },
    assignsAdded() {
      let idsAdded = this.stage.assignedAfter.filter(id => this.stage.assignedBefore.indexOf(id) === -1)
      return idsAdded.map(id => this.allUsersKeyed[parseInt(id)])
    },
    assignsRemoved() {
      let idsRemoved = this.stage.assignedBefore.filter(id => this.stage.assignedAfter.indexOf(id) === -1)
      return idsRemoved.map(id => this.allUsersKeyed[parseInt(id)])
    },
    daysUntilDeadlineBefore() {
      if (!this.stage.deadlineBefore) {
        return 0
      }
      let days = parseInt((Date.parse(this.stage.deadlineBefore) - Date.now()) / (36e5*24))
      return days > 0 ? days : 0
    },
    canHideStages() {
      return this.authRoles.indexOf(MANAGE_STAGES) !== -1
    },
    checkListBeforeIds() {
      return this.stage.checkListBefore.map(x => x.id)
    },
    checkListAfterIds() {
      return this.stage.checkListAfter.map(x => x.id)
    },
    checkListCreatedIds() {
      return this.checkListAfterIds.filter(id => !this.checkListBeforeIds.includes(id))
    },
    checkListDeletedIds() {
      return this.checkListBeforeIds.filter(id => !this.checkListAfterIds.includes(id))
    },
    checkListCheckedIds() {
      return this.stage.checkListAfter.filter(elem => elem.completed && (this.checkListBeforeIds.includes(elem.id) && this.stage.checkListBefore.find(x => x.id == elem.id).completed == false)).map(x => x.id)
    },
    checkListUncheckedIds() {
      return this.stage.checkListAfter.filter(elem => !elem.completed && (this.checkListBeforeIds.includes(elem.id) && this.stage.checkListBefore.find(x => x.id == elem.id).completed == true)).map(x => x.id)
    },
    isCheckListChanged() {
      return this.checkListCreatedIds.length || this.checkListDeletedIds.length || this.checkListCheckedIds.length > 0 || this.checkListUncheckedIds.length
    },
    fullCheckList() {
      let checkList = [...this.stage.checkListBefore]
      for (var i=0; i<this.stage.checkListAfter.length; i++) {
        let found = checkList.findIndex(x => x.id == this.stage.checkListAfter[i].id)
        if (found !== -1) {
          checkList[found] = this.stage.checkListAfter[i]
        } else {
          checkList.push(this.stage.checkListAfter[i])
        }
      }
      return checkList
    }
  },
  filters: {
    getExtension: function(url) {
      let parts = url.split('.')
      return parts[parts.length-1]
    }
  },
	methods: {
    getTextColor(color) {
      return textColor(color)
    },
    createdAtBeauty(datetime) {
      return this.$moment.utc(datetime).local().calendar()
    },
    createdAt(datetime, format) {
      if (!format) {
        format = 'LLL'
      }
      return this.$moment.utc(datetime).local().format(format);
    },
    readNotification(stageId) {
      let notifications = this.notifications.stages[stageId]
      for (var i=0; i<notifications.length; i++) {
        apiCall({ url: "notifications/read/" + notifications[i].id, method: "POST"})
          .then(() => {
            this.$store.dispatch(NOTIFICATIONS_REQUEST)
          })
      }
    },
    confirmDeadline(isConfirmed) {
      apiCall({ url: "stages/" + this.stage.id + "/deadline-confirm/" + (isConfirmed ? "true" : "false"), method: "POST"})
      .then(() => {
        this.$store.dispatch(NOTIFICATIONS_REQUEST)
      })
    },
    startShowingTask() {
      this.$store.dispatch(TASK_SHOW_START, this.task)
      setTimeout(() => {
        this.$store.dispatch(TASK_SHOW_STOP)
      }, 3500);
    },

    onShowWhoRead() {
        document.body.click(); // скрываем все открытые tippy
        this.isLoadingWhoRead = true
        apiCall({ url: "stages/"+this.task.id+"/"+this.stage.id+"/notifications", method: "GET"})
        .then((resp) => {
          this.notificationReadIds = resp.readIds
          this.notificationUnreadIds = resp.unreadIds
          this.isLoadingWhoRead = false
        })
        return true
    },

    toggleHidden() {
      apiCall({ url: "stages/"+this.stage.id+"/hide", method: "POST"})
        .then(() => {
          this.$emit('hidden', !this.stage.isHidden)          
        })
    }
	}
}
</script>

<style scoped>
.stage-wrp {
  display: flex;
  flex-direction: row;
}
.stage-wrp.mine {
  flex-direction: row-reverse;
}

.stage-wrp .actions {
  float: right;
  height: 1.8rem;
}
.stage-wrp .notification.btn {
  display: inline-flex;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 3rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #FFF;
  border-radius: 0;
  background-color: rgba(0,0,0,0.1);
  border-color: transparent;
}

.stage-wrp .notification.btn.btn-destruct {
  background-color: rgba(255, 152, 152, 0.397);
  color: #da4b4b;
}
.stage-wrp .notification.btn.btn-primary {
  background-color: rgba(155, 255, 152, 0.397);
  color: #00af0f;
} 
.stage-wrp .notification.btn.btn-orange {
  background-color: rgba(255, 222, 152, 0.397);
  color: #585858;
} 


.stage-wrp .notification.btn.btn-destruct:hover {
  background-color: rgba(255, 152, 152, 0.397);
}
.stage-wrp .notification.btn.btn-primary:hover {
  background-color: rgba(155, 255, 152, 0.397);
} 
.stage-wrp .notification.btn.btn-orange:hover {
  background-color: rgba(255, 222, 152, 0.397);
} 
.stage-wrp .notification.btn.who-read {
  width: auto;
  color: #777;
  padding: 0 .25rem;
  font-size: .8rem;
  background-color: transparent;
}
.stage-wrp .notification.btn.who-read:hover {
  color: #000;
  box-shadow: none;
}


.stage-wrp .btn.btn-trash {
  background-color: rgba(255, 152, 152, 0.397);
  color: #da4b4b;
  padding: 0 .25em;
}

.stage-wrp .btn.btn-trash-disable {
  background-color: rgba(152, 255, 169, 0.397);
  color: #0aac0a;
  padding: 0 .25em;
}

.stage {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: .9rem;
}
.stage .footer {
  background-color: rgba(0,0,0,0.03);
  font-size: .9em;
  padding: .2em .5em;
}
.stage.mine {
  align-items: flex-end;
}

.stage .content {
  position: relative;
  background-color: #F8F7F7;
  border-radius: .5em;
  width: 100%;
}
.stage .content.content-80 {
  width: 80%;
}
.stage:not(.mine) .content {
  border-top-left-radius: 0;
}
.stage.mine .content {
  border-top-right-radius: 0;
}

.stage .header .title {
  align-self: flex-start;
  background-color: #EEEEEE;
  font-size: .8em;
  padding: .25em .5em .25em 0;
  border-radius: .25em;
}

.stage.failed .content {
  background-color: #FFECEC;
}
.stage.failed .header .title {
  background-color: #F9D1D1;
}

.stage.waiting .content {
  background-color: #FCFFC8;
}
.stage.waiting .header .title {
  background-color: #E9ECA6;
}

.stage.done .content {
  background-color: #E7F9E9;
}
.stage.done .header .title {
  background-color: #CFECD2;
}

.stage .progress > span {
  font-weight: 700;
  font-size: .7em;
}
.stage .progress > span.up {
  color: green;
  vertical-align: top;
}
.stage .progress > span.down {
  color: red;
}

.stage .body i {
  display: inline-block;
  width: 1.5em;
  font-size: 1.2em;
  vertical-align: top;
}

.stage .body .content-after-icon {
  display: inline-block;
  padding-left: 0.25em;
}

.stage .body .nl2br {
  display: flex;
  flex-direction: row;
}

.stage .calendar {
  color: #ff9900;
}

.stage .user.btn {
  background-color: transparent;
  position: relative;
  padding: 0em .5em;
  margin-right: 0;
  border: none;
}

.stage .body {
  font-size: .85em;
}

.stage .file.btn {
  display: block;
}
.stage .file.btn .upload-at {
  display: block;
}

.stage .file-wrapper {
  border: 1px solid rgba(0,0,0,.1);
  padding: .25em;
}

.read-unread-title {
  display: block;
  background-color: #EEE;
  color: #888;
  font-size: .8em;
  padding: .2em 1em;
  text-align: left;
}

.read-unread-title.read {
  background-color: #CFECD2;
  color: #00af0f;
}
.read-unread-title.unread {
  background-color: #F9D1D1;
  color: #da4b4b;
}

.checklist-entry {
  color: #999;
}
.checklist-entry.created {
  color: #00af0f;
}
.checklist-entry.deleted {
  color: #da4b4b;
  text-decoration: line-through;
}
.checklist-entry.checked {
  color: #00af0f;
  font-weight: bold;
}
.checklist-entry.unchecked {
  color: #000;
  font-weight: bold;
}
</style>