<template>
  <modal name="PopupDownloadGroup" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :scrollable="true" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">Скачать файлы группы</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <form class="content with-overflow padding-h padding-half-v" @submit.prevent="onSubmit">
      <vue-element-loading :active="isLoading"/>
      <div class="form-row">
        <label>
          <input v-model="form.includeWorking" type="checkbox"/>
          Скачать рабочие файлы
        </label>
      </div>

      <div class="form-row">
        <label>
          <input v-model="form.includeFinal" type="checkbox"/>
          Скачать итоговые файлы
        </label>
      </div>

      <div class="form-row">
        <label>
          <input v-model="form.includeSubdirs" type="checkbox"/>
          Включая подгруппы
        </label>
      </div>

      <div class="pure-u-1 txt-r form-row">
        <button type="submit" class="btn btn-primary"><i class="fal fa-check"></i> Сохранить</button>
      </div>
    </form>
  </modal>
</template>

<style scoped>

</style>

<script>
import VueElementLoading from 'vue-element-loading'
import {apiFile} from "@/utils/api"


export default {
  name: 'PopupDownloadGroup',
  components: {
    VueElementLoading,
  },
  data() {
    return {
      isLoading: false,
      form: {
        includeWorking: false,
        includeFinal: true,
        includeSubdirs: false,
      },
      group: {}
    }
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen: function(data) {
      this.form = data.params.form
      this.group = data.params.group
      this.$escStack.push(this.$options.name)
    },
		onSubmit: function () {
      //this.isLoading = true
      this.$modal.hide(this.$options.name)
      let action = "task-groups/" + this.group.projectId + "/" + this.group.id + "/download"
      apiFile({ url: action, method: "POST", data: this.form})
      .then(() => {
        //this.isLoading = false
      })
		}
	}
}
</script>