import {
  ROLES_REQUEST, ROLES_ERROR, ROLES_SUCCESS,
  SPECIALIZATIONS_REQUEST, SPECIALIZATIONS_SUCCESS,
  PERMISSIONS_REQUEST, PERMISSIONS_SUCCESS
} from "../actions/roles";
import apiCall from "@/utils/api";
import Role from '@/models/role';
import Vue from "vue";

const state = {
  status: "",
  permissionsLoading: [],
  roles: [],
  customerRoles: [],
  specializations: [],
  rolesPermissions: {}
};

const getters = {
  allRoles: state => state.roles,
  customerRoles: state => state.customerRoles,
  allSpecializations: state => state.specializations,
  rolesPermissions: state => state.rolesPermissions
};

const actions = {
  [ROLES_REQUEST]: ({ commit, state }) => {
    if (state.status == "loading") {
      return
    }

    commit(ROLES_REQUEST);
    apiCall({ url: "roles" })
      .then(resp => {
        commit(ROLES_SUCCESS, resp);
      })
      .catch(() => {
        commit(ROLES_ERROR);
      });
  },
  [SPECIALIZATIONS_REQUEST]: ({ commit }) => {
    apiCall({ url: "specializations" })
      .then(resp => {
        commit(SPECIALIZATIONS_SUCCESS, resp);
      })
  },
  [PERMISSIONS_REQUEST]: ({ commit, state }, roleId) => {
    if (!roleId || state.permissionsLoading.indexOf(roleId) !== -1) {
      return
    }

    state.permissionsLoading.push(roleId)
    apiCall({ url: "roles/" + roleId })
      .then(resp => {
        commit(PERMISSIONS_SUCCESS, { resp: resp, roleId: roleId });
      })
  }
};

const mutations = {
  [ROLES_REQUEST]: state => {
    state.status = "loading";
  },
  [ROLES_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "roles", resp.map(r => Object.assign(new Role, r)));
    Vue.set(state, "customerRoles", state.roles.filter(r => r.isCustomer).map(r => r.id));
  },
  [ROLES_ERROR]: state => {
    state.status = "error";
  },

  [SPECIALIZATIONS_SUCCESS]: (state, resp) => {
    Vue.set(state, "specializations", resp.map(r => Object.assign({}, r)));
  },

  [PERMISSIONS_SUCCESS]: (state, { resp, roleId }) => {
    state.permissionsLoading.filter(id => id != roleId);
    state.rolesPermissions[roleId] = resp
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
