const textColor = (hex) => {
    // array of ints [r, g, b]
    const rgba = hex.replace('#', '').match(/.{1,2}/g).map( i => parseInt('0x'+i))
    const limit = 255/1.5
    if (rgba[0] > limit && rgba[1] > limit && rgba[2] > limit) {
        return 'black';
    } else {
        return 'white';
    }
}

export default textColor;