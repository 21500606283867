<template>
  <modal name="PopupEditRoles" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :width="800" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">Изменение ролей пользователей</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    
    <div class="content padding">
      <form class="pure-g container" @submit.prevent="onSubmit">
        <vue-element-loading :active="isLoading"/>
        <div class="pure-u-1 pure-u-md-1-3 form-row">
          <label>Выберите роль</label>
          <select v-model="selectedIndex" @change="selectedRole = allRoles[selectedIndex]">
            <option v-for="o, index in allRoles" :value="index" :key="'role_'+o.id">{{ o.name  }}</option>
          </select>
        </div>

        <div class="pure-u-1 pure-u-md-2-3 form-row">
          <label>Новое название</label>
          <input type="text" v-model="selectedRole.name">
        </div>
        <div class="pure-u-1"></div>

        <div class="pure-u-1">
          <label>
            <input type="checkbox" v-model="selectedRole.isCustomer" />
            Роль принадлежит заказчику
          </label>
        </div>

        <h4 class="pure-u-1 margin-half">Ред. профилей пользователей</h4>
        <permissions-list :selected="selectedRole.values" @change="selectedRole.values = $event.selected"/>
        <hr class="pure-u-1"/>

        
        <div class="pure-u-1 form-row txt-r">
          <button type="submit" class="btn btn-primary"><i class="fal fa-check margin-r"></i> Сохранить</button>
        </div>
      </form>
    </div>
  </modal>
</template>

<style scoped>
</style>

<script>
import { apiCall, apiAuthHeaders } from "@/utils/api"

import VueElementLoading from 'vue-element-loading'
import PermissionsList from '@/components/PermissionsList.vue'

export default {
  name: 'PopupEditRoles',
  components: {
    VueElementLoading,
    PermissionsList
  },
  data() {
    return {
      isLoading: false,
      allRoles: [],
      selectedIndex: -1,
      selectedRole: {
        id: -1,
        name: '',
        values: [],
        isCustomer: false
      }
    }
  },
  computed: {
    authHeaders() { return apiAuthHeaders() }
  },
  methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen() {
      this.isLoading = true
      this.loadRoles()
      this.$escStack.push(this.$options.name)
    },
    loadRoles() {
      apiCall({ url: 'roles', method: "GET"})
      .then(resp => {
        this.allRoles = resp
        this.allRoles.unshift({
          name: "Новая роль",
          id: 0,
          values: [],
          isCustomer: false
        })
        this.isLoading = false
      })
    },
    onSubmit: function () {
      this.isLoading = true
      apiCall({ url: 'roles/update', method: "POST", data: {
        id: this.selectedRole.id,
        name: this.selectedRole.name,
        values: this.selectedRole.values,
        isCustomer: this.selectedRole.isCustomer
      }})
      .then(resp => {
        console.log(resp)
        this.$modal.hide(this.$options.name)
        this.loadRoles()
      })
    }
	}
}
</script>