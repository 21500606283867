import { NOTIFICATIONS_REQUEST, NOTIFICATIONS_SUCCESS, NOTIFICATIONS_ERROR } from "../actions/notifications";
import apiCall from "@/utils/api";
import Vue from "vue";

const state = {
  status: "",
  notifications: {
    projects: {},
    groups: {},
    tasks: {},
    cells: {},
    stages: {},

    chatProjects: {},
    chatGroups: {},
    chatTasks: {},
    chatThemes: {},
  },
};

const getters = {
  notifications: state => state.notifications,
};

const actions = {
  [NOTIFICATIONS_REQUEST]: ({ commit }) => {
    commit(NOTIFICATIONS_REQUEST);
    apiCall({ url: "notifications", method: "GET"})
      .then(resp => {
        commit(NOTIFICATIONS_SUCCESS, resp);
      })
      .catch(() => {
        commit(NOTIFICATIONS_ERROR);
      });      
  }
};

const mutations = {
  [NOTIFICATIONS_REQUEST]: state => {
    state.status = "loading";
  },
  [NOTIFICATIONS_SUCCESS]: (state, resp) => {
    state.status = "success";
    let parsed = {
      projects: {},
      groups: {},
      tasks: {},
      cells: {},
      stages: {},
      
      chatProjects: {},
      chatGroups: {},
      chatTasks: {},
      chatThemes: {},
    }
    for (var i=0; i<resp.notifications.length; i++) {
      if (resp.notifications[i].projectId) {
        if (!parsed.projects[resp.notifications[i].projectId]) {
          parsed.projects[resp.notifications[i].projectId] = []
        }
        parsed.projects[resp.notifications[i].projectId].push(resp.notifications[i])
      }

      if (resp.notifications[i].groupId) {
        if (!parsed.groups[resp.notifications[i].groupId]) {
          parsed.groups[resp.notifications[i].groupId] = []
        }
        parsed.groups[resp.notifications[i].groupId].push(resp.notifications[i])
      }

      if (resp.notifications[i].taskId) {
        if (!parsed.tasks[resp.notifications[i].taskId]) {
          parsed.tasks[resp.notifications[i].taskId] = []
        }
        parsed.tasks[resp.notifications[i].taskId].push(resp.notifications[i])
      }

      if (resp.notifications[i].taskCellId) {
        if (!parsed.cells[resp.notifications[i].taskCellId]) {
          parsed.cells[resp.notifications[i].taskCellId] = []
        }
        parsed.cells[resp.notifications[i].taskCellId].push(resp.notifications[i])
      }

      if (resp.notifications[i].taskStageId) {
        if (!parsed.stages[resp.notifications[i].taskStageId]) {
          parsed.stages[resp.notifications[i].taskStageId] = []
        }
        parsed.stages[resp.notifications[i].taskStageId].push(resp.notifications[i])
      }
    }

    for (var j=0; j<resp.chats.length; j++) {
      if (resp.chats[j].projectId) {
        if (!parsed.chatProjects[resp.chats[j].projectId]) {
          parsed.chatProjects[resp.chats[j].projectId] = []
        }
        parsed.chatProjects[resp.chats[j].projectId].push(resp.chats[j])
      }

      if (resp.chats[j].groupId) {
        if (!parsed.chatGroups[resp.chats[j].groupId]) {
          parsed.chatGroups[resp.chats[j].groupId] = []
        }
        parsed.chatGroups[resp.chats[j].groupId].push(resp.chats[j])
      }

      if (resp.chats[j].taskId) {
        if (!parsed.chatTasks[resp.chats[j].taskId]) {
          parsed.chatTasks[resp.chats[j].taskId] = []
        }
        parsed.chatTasks[resp.chats[j].taskId].push(resp.chats[j])
      }

      if (resp.chats[j].themeId) {
        if (!parsed.chatThemes[resp.chats[j].themeId]) {
          parsed.chatThemes[resp.chats[j].themeId] = []
        }
        parsed.chatThemes[resp.chats[j].themeId].push(resp.chats[j])
      }
    }
    console.log(parsed)
    Vue.set(state, "notifications", parsed);
  },
  [NOTIFICATIONS_ERROR]: state => {
    state.status = "error";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
