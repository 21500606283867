<template>
  <modal name="PopupTaskSubscribers" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :scrollable="true" :width="600" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">{{ task.name }} - упоминания задачи</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <div class="content padding subscribers" style="min-height: 20em">
      <vue-element-loading :active="isLoading"/>
      <div class="team-users margin-b" v-if="linksOut.length">
        <h4 class="title">Зависящие задачи</h4>
        <task-entry :id="id" v-for="id in linksOut" :key="' task ' + id" />
      </div>

      <div class="team-users margin-b" v-if="subscribers.length">
        <h4 class="title">Подписаны на уведомления</h4>
        <user-entry :id="uid" v-for="uid in subscribers" :key="' team ' + uid" />
      </div>
    </div>
  </modal>
</template>

<style scoped>
.title {
  padding: 0 0 .5rem 0;
  margin: 0;
  color: #888;
  font-size: .7rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
</style>

<script>
import VueElementLoading from 'vue-element-loading'
import Task from '@/models/task'
import apiCall from '@/utils/api'

import UserEntry from '@/components/UserEntry.vue'
import TaskEntry from '@/components/TaskEntry.vue'

export default {
  name: 'PopupTaskSubscribers',
  components: {
    VueElementLoading,
    UserEntry,
    TaskEntry
  },
  data() {
    return {
      isLoading: false,
      task: Object.assign(new Task, {}),
      linksOut: [],
      subscribers: []
    }
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.task = data.params.task
      this.loadSubscribers()

      this.$escStack.push(this.$options.name)
    },
    loadSubscribers() {
      this.isLoading = true
      apiCall({ url: 'tasks/' + this.task.id + '/subscribers', method: "GET"})
			.then((resp) => {
        this.subscribers = resp.subscribers
        this.linksOut = resp.linksOut
				this.isLoading = false
			})
    }
	}
}
</script>