<template>
  <div class="pure-g container">
    <div class="pure-u-1 form-row">
      <label>Название столбца</label>
      <input required v-model="column.name" type="text" placeholder="Название столбца"/>
    </div>
    <div class="pure-u-1 form-row">
      <label class="block-vcenter">
        <input v-model="column.fileRequired" type="checkbox" class="margin-half-r"/>
        Для передачи на следующий столбец необходим файл
      </label>
    </div>
    <div class="pure-u-1 form-row">
      <label class="block-vcenter">
        <input v-model="column.assignRequired" type="checkbox" class="margin-half-r"/>
        Ячейка требует назначения ответственных
      </label>
    </div>

    <div class="pure-u-1 form-row">
      <label>Скрывать столбец для</label>
      <roles-picker v-model="column.rolesInvisible" />
    </div>

    <div class="pure-u-1 form-row">
      <label>Можно назначить:</label>
      <roles-picker v-model="column.rolesAssignable" />
    </div>

  </div>
</template>


<script>
import RolesPicker from './RolesPicker.vue'

export default {
  name: 'EditTaskGroupColumn',
  components: {
    RolesPicker
  },
  props: ['column'],
  data() {
    return {

    }
  },
	methods: {
	}
}
</script>