<template>
  <modal name="PopupWorkingFiles" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :width="600" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">{{ task.name }}</span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <div class="content padding stages" style="min-height: 20em">
      <vue-element-loading :active="isLoading"/>
      <div class="margin-half-b">{{ subtitle }}</div>
      <file-entry v-for="f in files" :key="f.id + 'file'" :file="f" />
    </div>
  </modal>
</template>

<style scoped>

</style>

<script>
import VueElementLoading from 'vue-element-loading'
import apiCall from '@/utils/api'
import FileEntry from '@/components/FileEntry.vue'

import {
  FILE_TYPE_WORKING
} from '@/utils/const'

export default {
  name: 'PopupWorkingFiles',
  components: {
    VueElementLoading,
    FileEntry
  },
  data() {
    return {
      isLoading: false,
      fileType: FILE_TYPE_WORKING, // or 'final'
      subtitle: '',
      task: {},

      files: []
    }
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen(data) {
      this.task = data.params.task
      this.fileType = data.params.fileType
      this.subtitle = data.params.subtitle
      this.loadFiles()

      this.$escStack.push(this.$options.name)
    },
    loadFiles() {
      this.isLoading = true
      apiCall({ url: 'tasks/' + this.task.id + '/files/' + this.fileType, method: "GET"})
			.then((files) => {
        this.files = files
				this.isLoading = false
			})
    }
	}
}
</script>