import './assets/css/grids.css'
import 'vue-nav-tabs/themes/vue-tabs.css'
import "tippy.js/themes/light.css";
import './assets/css/fw.css'
import './assets/css/fonts.css'
import './assets/css/controls.css'
import './assets/css/site.css'


import Vue from 'vue'
import VModal from 'vue-js-modal'
import vuescroll from 'vuescroll';
import VueTippy, { TippyComponent } from "vue-tippy";
import App from './App.vue'
import router from './router'
import store from './store'

import SodnpHub from './sodnpHub'


Vue.config.productionTip = false

Vue.use(VModal)

import VueSlideoutPanel from 'vue2-slideout-panel';
Vue.use(VueSlideoutPanel);

Vue.use(vuescroll, {
  ops: {
    bar: {
      background: '#AAA'
    }
  },
});

Vue.use(VueTippy, {
  directive: "tippy", // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false
      }
    }
  }
});
Vue.component("tippy", TippyComponent);

import Moment from 'moment' 
Moment.locale('ru')
Vue.prototype.$moment = Moment

import VueTextareaAutosize from "vue-textarea-autosize";
Vue.use(VueTextareaAutosize)


import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
Vue.component('VueSlider', VueSlider)

import TaskThemes from '@/components/chat/TaskThemes.vue'
Vue.component('TaskThemes', TaskThemes)
import TaskThemeChat from '@/components/chat/TaskThemeChat.vue'
Vue.component('TaskThemeChat', TaskThemeChat)

/*// gallery
import VuePreview from 'vue-preview'
Vue.use(VuePreview, {
  mainClass: 'pswp--minimal--dark',
  barsSize: {top: 0, bottom: 0},
  captionEl: true,
  fullscreenEl: false,
  shareEl: false,
  bgOpacity: 0.85,
  tapToClose: true,
  tapToToggleControls: false
})
*/

import VCalendar from 'v-calendar'
Vue.use(VCalendar, {})

// заменять ссылки в plain text на <a href="..">...</a> черех v-linkified
import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)

Vue.prototype.$escStack = [];
Vue.use(SodnpHub)

Vue.filter('hoursLeft', function(hours) {
  let hoursInDay = 8
  // если <= 12 часов - то выводим в часах
  if (hours < 1) {
    return 'менее часа'
  } else if (hours == 1) {
      return '1 час'
  } else if (hours <= 4) {
      return (Number.isInteger(hours) ? parseInt(hours) : hours.toFixed(1)) + ' часа'
  } else if (hours < 16) {
      return (Number.isInteger(hours) ? parseInt(hours) : hours.toFixed(1)) + ' часов'
  }
  // иначе - в днях
  let days = (hours/hoursInDay).toFixed(1);
  if (days - parseInt(days) === 0.0) {
      days = parseInt(days)
  }
  let lastDigit = (days + '')[(days + '').length-1]
  if (days >= 11 && days <= 19 && Number.isInteger(days)) {
    return parseInt(days) + ' дней';
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return days + ' дня';
  }
  if (lastDigit == 1) {
    return days + ' день';
  }
  return days + ' дней';
})
Vue.filter('daysLeft', function(days) {
  days = days.toFixed(1);
  if (days - parseInt(days) === 0.0) {
      days = parseInt(days)
  }
  let lastDigit = (days + '')[(days + '').length-1]
  if (days >= 11 && days <= 19 && Number.isInteger(days)) {
    return parseInt(days) + ' дней';
  }
  if (lastDigit >= 2 && lastDigit <= 4) {
    return days + ' дня';
  }
  if (lastDigit == 1) {
    return days + ' день';
  }
  return days + ' дней';
})
Vue.filter('fileExtension', function(path) {
    let parts = path.split('.')
    return parts[parts.length-1].toLowerCase()
})

import { NOTIFICATIONS_REQUEST } from "@/store/actions/notifications"

new Vue({
  router,
  store,
  mounted() {
    this.$sodnpHub.$on('task-updated', this.onTaskUpdated)
    this.$sodnpHub.$on('task-theme-msg-received', this.onMessageReceived)
    this.$store.dispatch(NOTIFICATIONS_REQUEST)
  },
  beforeDestroy: function() {
    this.$sodnpHub.$off('task-updated', this.onTaskUpdated)
    this.$sodnpHub.$off('task-theme-msg-received', this.onMessageReceived)
  },
  methods: {
    onMessageReceived() {
      this.$store.dispatch(NOTIFICATIONS_REQUEST)
    },
    onTaskUpdated() {
      this.$store.dispatch(NOTIFICATIONS_REQUEST)
    },
  },
  render: h => h(App),
}).$mount('#app')
