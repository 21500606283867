<template>
  <header class="pure-g">
    <aside class="pure-u-1 pure-u-md-8-24 pure-u-lg-6-24 pure-u-xl-5-24">
      <div class="logo-block flex-row">
        <router-link to="/" class="logo flex-expand">
          <template v-if="activeProject && activeProject.name">
            {{ activeProject.name }}
          </template>
          <template v-else>
            Портал
          </template>
        </router-link>
        <button class="btn btn-transparent" type="button" @click="toggleAside" title="Свернуть/развернуть боковую панель">
          <i v-show="showAside" class="fal fa-arrow-to-left"></i>
          <i v-show="!showAside" class="fal fa-arrow-to-right"></i>
        </button>
      </div>
    </aside>

    <div class="pure-u-1 pure-u-md-16-24 pure-u-lg-18-24 pure-u-xl-19-24" style="position: relative">
      <div class="flex-row block-vcenter" style="height: 100%" v-if="activeProject && activeProject.name">
        <div class="flex-expand h1 txt-r block-vcenter margin-r" v-if="activeProject && activeProject.name">
          <span class="hidden">{{ activeProject.name }}</span>
        </div>

        <button class="btn btn-transparent" :class="{ pressed: forceShowDeadline }" v-if="!isSelectingCells"
          @click="toggleForceShowDeadline" title="Переключить отображение сроков завершения в ячейках">
          <i class="fal fa-calendar-check"></i>
        </button>

        <button class="btn btn-transparent" :class="{ pressed: forceShowAssignee }" v-if="!isSelectingCells"
          @click="toggleForceShowAssignee" title="Переключить отображение назначенных пользователей в ячейках">
          <i class="fal fa-users"></i>
        </button>

        <button class="btn btn-transparent" v-if="!isSelectingCells" @click="startSelectingCells()"
          title="Режим выбора нескольких ячеек">
          <i class="fal fa-ballot-check"></i>
        </button>
      </div>
      <global-selecting />
    </div>
  </header>
</template>

<style scoped>
header aside,
header div {
  background-color: #509E2F;
  color: #FFF;
}

header aside {
  border-right: 1px solid #74b659;
}

header aside .logo-block {
  display: flex;
  flex-direction: row;
}

header .h1 {
  height: 100%;
  font-size: 2rem;
}

header aside .logo-block a,
header aside .logo-block a:active,
header aside .logo-block a:visited,
header .logo-block aside a:hover {
  display: flex;
  align-self: center;
  color: #FFF;
  font-size: 1.3em;
  padding: 0 .5em;
  text-decoration: none;
}

aside .logo-block a.logo,
aside .logo-block a.logo:active,
aside .logo-block a.logo:visited,
aside .logo-block a.logo:hover {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  box-sizing: border-box;

  padding: .6em 1em .6em 0;
  margin-left: .5em;

  font-size: 1.3em;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.btn.pressed {
  background-color: rgba(0, 0, 0, .2);
}
</style>

<script>
import { mapGetters } from "vuex";
import { CELLS_SELECTION_START, FORCE_SHOW_ASSIGNEE, FORCE_SHOW_DEADLINE } from "@/store/actions/project"

import GlobalSelecting from '@/components/GlobalSelecting.vue'


export default {
  name: 'Header',
  components: {
    GlobalSelecting
  },
  data() {
    return {
      showAside: true
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "authStatus", "activeProject", "isSelectingCells", "forceShowAssignee", "forceShowDeadline"])
  },
  methods: {
    toggleAside() {
      if (this.showAside) {
        this.$emit('hideAside')
      } else {
        this.$emit('showAside')
      }
      this.showAside = !this.showAside
    },
    startSelectingCells() {
      this.$store.dispatch(CELLS_SELECTION_START)
    },
    toggleForceShowAssignee() {
      this.$store.dispatch(FORCE_SHOW_ASSIGNEE, {
        force: !this.forceShowAssignee
      })
    },
    toggleForceShowDeadline() {
      this.$store.dispatch(FORCE_SHOW_DEADLINE, {
        force: !this.forceShowDeadline
      })
    }
  }
}
</script>