<template>
  <div class="aside-projects" @click="closeAll">
    <popup-edit-project />
    <div class="actions-title flex-row">
        <span class="flex-expand">Проекты</span>
        <a class="animated tooltip" v-bind:class="{'active': isCompactViewEnabled}" @click="isCompactViewEnabled = !isCompactViewEnabled" title="Вкл/Выкл компактный вид"><i class="fad fa-th-list"></i></a>
        <a @click="onAddProject" class="animated tooltip" title="Добавить проект" v-if="isGroupManagementAllowed"><i class="far fa-plus"></i></a>
    </div>
    <div class="projects" v-bind:class="{'as-images': !isCompactViewEnabled, 'as-list': isCompactViewEnabled}">
      <vue-element-loading :active="isProjectsLoading"/>
      <vuescroll>
        <router-link class="project-card-wrapper" v-for="project in projects" v-bind:key="project.Id" :to="{name: 'ViewProject', params: { projectId: project.id }}">
            <span class="project-card photo-cover" v-bind:style="{'background-image': 'url('+project.photoUrl+')'}">
                <span v-if="notifications.projects[project.id] && notifications.projects[project.id].length" class="badge events-badge">
                    <span v-if="notifications.projects[project.id].length < 10">
                        {{ notifications.projects[project.id].length }}
                    </span>
                    <span v-else><i class="fas fa-exclamation"></i></span>
                </span>
                <span v-if="notifications.chatProjects[project.id] && notifications.chatProjects[project.id].length" class="badge chat-badge"><i class="fas fa-comments"></i></span>
                <span class="bottom">
                    <span @click.prevent="onEditProject(project)" class="edit tooltip" title="Редактировать проект" v-if="isGroupManagementAllowed"><i class="fa fa-pencil"></i></span>
                    <span class="name">{{ project.name }}</span>
                    <span class="description"></span>
                </span>
            </span>
        </router-link>
      </vuescroll>
    </div>
  </div>
</template>

<style scoped>
.aside-projects {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: auto;
}
.projects {
  flex-grow: 1;
  flex: 1 1 auto;
  overflow: auto;
}


.project-card-wrapper {
    position: relative;
    opacity: .5;
    transition: all .2s;
}

.project-card-wrapper:hover {
    opacity: .9;
}

.always-visible .project-card-wrapper {
    opacity: 1;
}

.as-images .project-card-wrapper {
    padding: .33em 1em;
}
.as-list .project-card-wrapper {
    padding: .5em 1em;
}

.project-card-wrapper.router-link-active:before {
    content: "";
    display: block;
    position: absolute;
    left:  0;
    margin-top: -8px;
    top: 50%;
    border-radius: 50%;

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7.5px 0 7.5px 13.0px;
    border-color: transparent transparent transparent #509e2f;
}

.as-images .project-card-wrapper.router-link-active {
    opacity: 1;
    background-color: #d4e8cc;
}

.as-list .project-card-wrapper.router-link-active {
    opacity: 1;
    background-color: #509e2f;
}
.as-list .project-card-wrapper.router-link-active:before {
    border-color: transparent transparent transparent #FFF;
}

.project-card-wrapper .edit {
    position: absolute;
    right: 0;
    bottom: .5em;
    
    opacity: 0.4;
    transition: all .2s;
}
.project-card-wrapper .edit:hover {
    opacity: 1;
}

.project-card, .project-card:active, .project-card:visited {
    position: relative;
    display: block;
    box-sizing: border-box;
    text-decoration: none;
    transition: all .2s;
}

.as-list .project-card, .as-list .project-card:active, .as-list .project-card:visited {
    background-image: none !important;
    color: #74777b;
}

.as-list .project-card-wrapper.router-link-active .project-card,
.as-list .project-card-wrapper.router-link-active .project-card:active,
.as-list .project-card-wrapper.router-link-active .project-card:visited {
    color: #FFF;
}

.as-images .project-card, .as-images .project-card:active, .as-images .project-card:visited {
    padding: 1em;
    padding-top: 60%;
    color: #FFF;
    border-radius: 1em;
    box-shadow: inset 0 -5em 2em rgba(0,0,0,.5);
}
.as-images .project-card:hover {
    box-shadow: inset 0 -5em 2em rgba(0,0,0,.6);
}

.as-images .project-card .bottom {
    position: absolute;
    bottom: .5em;
    left: 1em;
    right: 1em;
}

.project-card .name {
    font-size: 1.2em;
}


.project-card .description {
    font-size: .85em;
    display: block;
}
.project-card .badge {
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    background-color: #E40231;
    text-align: center;
    font-size: .85rem;
    
    color: #FFF;
    font-weight: 700;
    border: 2px solid #FFF;
    border-radius: 50%;
}
.project-card .badge.badge.chat-badge {
  background-color: #ff9900;
  font-size: .75em;
  line-height: 1.2rem;
}
.as-images .project-card .badge {
  display: block;
  position: absolute;
  top: -.2em;
  right: -.2em;
}
.as-list .project-card .badge {
  position: relative;
  top: -.2em;
  margin-right: .5em;
}

.as-images .project-card .badge.chat-badge {
    right: 1.1rem;
}
</style>

<script>
import VueElementLoading from 'vue-element-loading'
import vuescroll from 'vuescroll';
import { mapGetters } from "vuex"
import { NOTIFICATIONS_REQUEST } from "@/store/actions/notifications"
import { TASK_CHILDREN_CLOSE } from "@/store/actions/project"
import apiCall from "@/utils/api";
import { MANAGE_PROJECTS } from "@/utils/permissions"
import Project from '@/models/project'
import PopupEditProject from './PopupEditProject.vue'

export default {
  name: 'AsideProjects',
  components: {
    VueElementLoading,
    vuescroll, 
    PopupEditProject
  },
  data() {
    return {
      projects: [],
      isCompactViewEnabled: false,
      isProjectsLoading: false
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated", "authStatus", "authRoles", "notifications"]),
    isGroupManagementAllowed() {
      return this.authRoles.indexOf(MANAGE_PROJECTS) !== -1
    }
  },
  mounted() {
    this.$sodnpHub.$on('project-added', this.onProjectAdded)
    this.$sodnpHub.$on('project-updated', this.onProjectUpdated)

    this.$store.dispatch(NOTIFICATIONS_REQUEST)

    this.loadProjects()
  },
  beforeDestroy: function() {
    this.$sodnpHub.$off("project-added", this.onProjectAdded);
    this.$sodnpHub.$off('project-updated', this.onProjectUpdated)
  },
  methods: {
    loadProjects() {
      this.isProjectsLoading = true
      apiCall({ url: "projects", method: "GET" })
			.then(resp => {
				this.projects = resp.map(p => Object.assign(new Project, p))
        this.isProjectsLoading = false
			})
    },
    onAddProject() {
      this.$modal.show('PopupEditProject', Object.assign(new Project, {
        managerIds: []
      }))
    },
    onEditProject(project) {
      this.$modal.show('PopupEditProject', project)
    },
    onProjectAdded(/*data*/) {
      this.loadProjects()
      //console.log(data.projectId)
    },
    onProjectUpdated() {
      this.loadProjects()
    },
    closeAll() {
      this.$store.dispatch(TASK_CHILDREN_CLOSE)
    }
  }
}
</script>