<template>
  <div class="notifications" style="min-height: 20rem">
    <popup-file-preview />
    <popup-about-profile />

    <h2 class="no-margin">Все уведомления</h2>
    <div class="pure-g container filters margin-half-t">
      <div class="pure-u-1 h2 margin-half-b">
        Фильтры
        <button class="btn btn-small" @click="reset"><i class="fa fa-undo margin-half-r"></i> Сбросить</button>
      </div>
      <div class="pure-u-1 pure-u-md-1-4">
        <users-picker v-model="filterFrom" :ids="allUserIds" placeholder="От кого"/>
      </div>
      <div class="pure-u-1 pure-u-md-1-4">
        <select v-model="filterProjectId">
          <option value="">Все проекты</option>
          <option :value="p.id" v-for="p in projects" :key="'project_' + p.id">{{ p.name }}</option>
        </select>
      </div>
      <div class="pure-u-1 pure-u-md-1-4">
        <v-date-picker v-model="filterDates" is-range>
          <template v-slot="{ inputEvents }">
            <input readonly :value="filterDatesText" type="text" v-on="inputEvents.start"/>
          </template>
        </v-date-picker>
      </div>
      <div class="pure-u-1 form-row">
        <label class="block-vcenter">
          <input v-model="unreadOnly" type="checkbox" class="margin-half-r"/>
          Только непрочитанные
        </label>
      </div>
    </div>

    <div class="txt-r">
      <button @click="readAllAssignments" class="btn">Прочитать все с назначениями</button>
    </div>

    <vue-element-loading :active="isNotificationsLoading"/>
    <vuescroll style="min-height: 50vh">
      <template v-for="(n, index) in notificationsFiltered">
        <div class="header" v-if="index == 0 || (notificationsFiltered[index-1].project.id != n.project.id)" :key="'project-thead-' + n.id">
            <h3>{{ n.project.name }}</h3>
        </div>
        <div class="padding-h" v-if="n.theme" :key="'theme_' + n.id">
          <stage-entry-theme :tuser="n" :task="n.task" :project="n.project" :showTask="true" />
        </div>
        <div class="padding-h" v-else :key="'notifications_' + n.id">
          <stage-entry :stage="n.taskStage" :group="n.task.group" :task="n.task" :project="n.project" :showTask="true" />
        </div>
      </template>
    </vuescroll>
  </div>
</template>

<style scoped>
:not(.vm--modal) .scroll-wrp {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.notifications {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
}

table {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-collapse: collapse;
}
table tr, table td {
  box-sizing: border-box;
}

table .header {
  z-index: 1;
  height: 2rem;
  font-size: .8em;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}

table .header td {
  padding: .25em .5em;
  border: 1px solid #666;
  border-left-width: 2px;
  border-right-width: 2px;
  font-weight: 700;
}

table td {
  height: 2.2em;
  font-size: .9rem;
  border: 1px solid #666;
  border-left-width: 2px;
  border-right-width: 2px;
  background-color: #FFF;
  transition: all .2s;
}
</style>

<script>
import VueElementLoading from 'vue-element-loading'
import vuescroll from 'vuescroll'

import PopupFilePreview from '@/components/PopupFilePreview.vue'
import PopupAboutProfile from '@/components/PopupAboutProfile.vue'
import StageEntry from '@/components/StageEntry.vue'
import StageEntryTheme from '@/components/StageEntryTheme.vue'
import UsersPicker from '@/components/UsersPicker.vue'

import { mapGetters } from "vuex"
import apiCall from '@/utils/api'
import { NOTIFICATIONS_REQUEST } from "@/store/actions/notifications"

export default {
  name: 'Notifications',
  components: {
    VueElementLoading,
    UsersPicker,
    StageEntry,
    StageEntryTheme,
    PopupFilePreview,
    PopupAboutProfile,
    vuescroll
  },
  data() {
    return {
      isNotificationsLoading: false,
      filterFrom: [],
      filterProjectId: '',
      filterDates: {start: null, end: null},
      unreadOnly: true,
      projects: [],
      notifications: [],
      chatNotifications: []
    }
  },
  computed: {
    ...mapGetters(["authId", "groupsKeyed", "activeGroup"]),
    notificationsFiltered() {
      let filtered = [...this.notifications, ...this.chatNotifications].sort(function(a,b){
        const dateA = new Date(a.theme ? a.theme.lastMessageAt : a.visibleSince)
        const dateB = new Date(b.theme ? b.theme.lastMessageAt : b.visibleSince)
        if (dateA >= dateB)
          return -1;
        return 1;
      });
      if (this.unreadOnly) {
        filtered = filtered.filter(n => n.theme || n.status == 0)
      }
      if (this.filterProjectId) {
        filtered = filtered.filter(n => n.projectId == this.filterProjectId)
      }
      if (this.filterFrom && this.filterFrom.length) {
        filtered = filtered.filter(n => !n.theme && this.filterFrom.indexOf(n.taskStage.profileId) != -1)
      }
      if (this.filterDates.start && this.filterDates.end) {
        filtered = filtered.filter(n => n.theme ? 
        // chat notifications
          this.filterDates.start <= new Date(n.theme.lastMessageAt) && new Date(n.theme.lastMessageAt) <= this.filterDates.end :
        // task notifications
          this.filterDates.start <= new Date(n.visibleSince) && new Date(n.visibleSince) <= this.filterDates.end)
      }
      return filtered
    },
    allUserIds() {
      return Array.from(new Set(this.notifications.map(n => n.taskStage.profileId)))
    },
    filterDatesText() {
      let text = ""
      if (this.filterDates.start) {
        text += " от " + this.filterDates.start.toLocaleDateString("ru-RU")
      }
      if (this.filterDates.end) {
        text += " до " + this.filterDates.end.toLocaleDateString("ru-RU")
      }
      return text.trim()
    }
  },
  mounted() {
    this.loadNotifications()
    this.loadProjects()
    
    this.$sodnpHub.$on('task-added', this.onTaskAdded)
    this.$sodnpHub.$on('task-updated', this.onTaskUpdated)
  },
  beforeDestroy: function() {
    this.$sodnpHub.$off('task-added', this.onTaskAdded)
    this.$sodnpHub.$off('task-updated', this.onTaskUpdated)
  },
  methods: {
    loadNotifications() {
      this.isNotificationsLoading = true
      apiCall({ url: 'projects/notifications', method: "GET"})
      .then((resp) => {
        this.chatNotifications = resp.chatNotifications
        this.notifications = resp.notifications
        this.notifications.map(n => n._tabId = this.groupsKeyed[n.groupId] ? this.groupsKeyed[n.groupId].tabId : null)
        this.isNotificationsLoading = false
      })
    },
    loadProjects() {
      apiCall({ url: "projects", method: "GET" })
			.then(resp => {
				this.projects = resp
			})
    },
    onTaskUpdated() {
      this.loadNotifications()
    },
    onTaskAdded() {
      this.loadNotifications()
    },
    readAllAssignments() {
      apiCall({ url: "notifications/read-all/assigns", method: "POST"})
        .then(() => {
          this.$store.dispatch(NOTIFICATIONS_REQUEST)
          this.loadNotifications()
        })
    },
    reset() {
      this.filterFrom.splice(0)
      this.filterDates = {start: null, end: null}
      this.unreadOnly = true
    }
  }
}
</script>
