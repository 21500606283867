<template>
  <div class="themes-block">
    <div class="header flex-row">
      <span class="flex-expand block-vcenter padding-half-h smaller">
        {{ task.name }}
      </span>
      <button class="btn btn-transparent" style="background-color: rgba(255,255,255,.2)" @click="$modal.show('PopupEditTaskTheme', {task: task})">
        <i class="fal fa-plus margin-r"></i> Новая тема
      </button>
    </div>
    <div class="themes" v-if="isLoading || themes.length">
      <vue-element-loading :active="isLoading"/>
      <div class="pure-g theme" v-for="t in themes"
        :key="t.id"
        :class="{customer: isCustomerInTheme(t)}"
        @click.stop="openChat(t)">
        <div class="pure-u-3-24 txt-c">
          <avatar :userid="getLastUserId(t)" :msg-top="notifications.chatThemes[t.id] && notifications.chatThemes[t.id].length ? 'msg' : 0 " />
        </div>
        <div class="pure-u-20-24">
          <span class="title">{{ t.name }}</span>
          <p v-if="t.lastMessage">
            <span class="text-blue" v-if="t.lastMessage.filesCount > 0">[файлы: {{ t.lastMessage.filesCount }}]</span>
            {{ t.lastMessage.text }}
          </p>
          <p v-else>Нет сообщений</p>
        </div>
      </div>
    </div>
    <div v-else class="themes block-vcenter txt-c text-grey" style="padding: 10%">
        Нажмите на кнопку "+" в правом верхнем углу чтобы добавить новую тему
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { NOTIFICATIONS_REQUEST } from "@/store/actions/notifications"

import VueElementLoading from 'vue-element-loading'
import Avatar from '@/components/Avatar.vue';
import apiCall from "@/utils/api"

export default {
  name: 'TaskThemes',
  components: {
    VueElementLoading,
    Avatar
  },
  props: ['task'],
  data() {
    return {
      isLoading: false,
      themes: [],
    }
  },
  computed: {
    ...mapGetters(["notifications", "authId", "allUsersKeyed"]),
  },
  mounted() {
    this.$sodnpHub.$on('task-theme-added', this.onThemeAdded)
    //this.$sodnpHub.$on('task-theme-updated', this.onTaskUpdated)

    this.$sodnpHub.$on('task-theme-msg-received', this.onMessageReceived)

    this.isLoading = true
    this.loadThemes()
  },
  beforeDestroy: function() {
    this.$sodnpHub.$off('task-theme-added', this.onThemeAdded)
    //this.$sodnpHub.$off('task-theme-updated', this.onTaskUpdated)

    this.$sodnpHub.$off('task-theme-msg-received', this.onMessageReceived)
  },
  methods: {
    loadThemes() {
      apiCall({ url: 'tasks/'+this.task.id+'/themes', method: "GET"})
			.then((resp) => {
				this.themes = resp
        this.isLoading = false
			})
    },
    onThemeAdded(data) {
      if (this.task.id != data.taskId) {
        return
      }
      this.loadThemes()
    },
    getLastUserId(theme) {
      if (theme.lastMessage) {
        return theme.lastMessage.profileId
      }
      let creator = theme.users.find(u => u.isCreator)
      if (creator) {
        return creator.profileId
      }
      if (theme.users.length) {
        return theme.users[0].profileId
      }
      return this.authId
    },
    isCustomerInTheme(theme) {
      return theme.userIds.some(uid => this.allUsersKeyed[uid].isCustomer)
    },
    onMessageReceived(data) {
      this.$store.dispatch(NOTIFICATIONS_REQUEST)
      var index = this.themes.findIndex(t => t.id == data.themeId)
      if (index === -1) {
        return
      }
      this.$set(this.themes[index], 'lastMessage', data)
    },
    openChat(theme) {
      this.$showPanel({component: 'TaskThemeChat', openOn: 'right', width: 600, props: {'theme': theme, 'task': this.task}})
    }
  }
}
</script>

<style scoped>
.themes-block {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #509E2F;
  color: #FFF;
  box-sizing: border-box;
  font-size: 1.39em;
  font-weight: 700;
}

.header .flex-expand {
  word-break: break-all;
}


.header .btn {
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.themes {
  flex: 1 1 auto;
  overflow: auto;
}

.theme {
  padding: 1em 1em 1em 0;
  cursor: pointer;
  transition: all .2s ease-in-out;
}
.theme:hover {
  background-color: #F5F6F6;
}

.theme.customer {
  background-color: #f9f3ff;
}
.theme.customer:hover {
  background-color: #f1e2ff;
}

.theme .title {
  font-weight: 700;
}
.theme p {
  margin: .5rem 0;
  font-size: .9em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>