export const SERVER_ADDRESS = "https://portal.iron-pro.ru/" // "http://91.240.84.43/" //"https://localhost:5001/"
export const API_PREFIX = SERVER_ADDRESS + "api/"

export const apiAuthHeaders = () => {
  let token = localStorage.getItem("tkn");
  if (token) {
    return {"Authorization": "Bearer " + token}
  }
  return {}
}

function getFilename(response) {
  const header = decodeURIComponent(response.headers.get('Filename').replace(/\+/g, '%20'))
  return header
}

export const apiCall = ({ url, data, method }) =>
new Promise((resolve, reject) => {
  let headers = Object.assign({
    'Content-Type': 'application/json'
  }, apiAuthHeaders())
  fetch(API_PREFIX + url, {
      method: method,
      body: JSON.stringify(data),
      headers,
  })
  .then((response) => {
    return response.json();
  })
  .then((respData) => {
    console.log(`${method} ${url}:`, respData);
    resolve(respData);
  })
  .catch(reason => {
    console.log(`ERROR ${method} ${url}:`, reason);
    reject(reason);
  })
});

export const apiFile = ({ url, data, method }) =>
new Promise((resolve, reject) => {
  let filename = "files.zip"
  let headers = Object.assign({
    'Content-Type': 'application/json'
  }, apiAuthHeaders())
  fetch(API_PREFIX + url, {
      method: method,
      body: JSON.stringify(data),
      headers,
  })
  .then( res => {
    filename = getFilename(res)
    return res.blob()
  })
  .then( blob => {
    var file = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = file;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);

    // window.location.assign(file);
  })
  .catch(reason => {
    console.log(`ERROR ${method} ${url}:`, reason);
    reject(reason);
  })
});

export default apiCall;
