<template>
  <div class="stage-wrp">
    <avatar v-if="createdBy" :userid="createdBy.id" class="margin-half-h" />
    <div class="stage">
      <div class="header">
        <span class="title">
          <span class="user btn">
            <i class="fal fa-comments margin-half-r"></i>
            Новые сообщения в чате
          </span>
        </span>
        
        <span class="margin-half-l smaller" v-if="showTask">
          <b>{{ project ? project.name + ' - ' : '' }}</b>
          {{ task.name }}
          <a class="line-inverse pull-right" href="javascript:" @click.prevent="startShowingTask()"><i class="far fa-external-link"></i> перейти к задаче</a>
        </span>
      </div>
      <div class="content" :class="{'content-80': !showTask}">
        <div class="actions">
          <span @click.stop="openChat" class="notification btn btn-primary" title="Открыть обсуждение задачи">
            <i class="fas fa-comments margin-half-h"></i>
          </span>
        </div>

        <div class="body">
          <div class="margin-half nl2br" :title="createdAt(tuser.theme.lastMessageAt)">
            <i class="fad fa-clock text-blue"></i>
            <span class="content-after-icon">{{ createdAtBeauty(tuser.theme.lastMessageAt) }}</span>
          </div>

          <div class="margin-half nl2br">
            <i class="fad fa-comment-lines text-blue"></i>
            <span class="content-after-icon" v-linkified>Новые сообщения в чате</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import {TASK_SHOW_START, TASK_SHOW_STOP } from "@/store/actions/project"
import Avatar from '@/components/Avatar.vue';

export default {
  name: 'StageEntryTheme',
  props: ['tuser', 'task', 'project', 'showTask'],
  components: {
    Avatar
  },
  computed: {
    ...mapGetters(["authId", "notifications", "allUsersKeyed"]),
    createdBy() {
      return null
    }
  },
	methods: {
    createdAtBeauty(datetime) {
      return this.$moment.utc(datetime).local().calendar()
    },
    createdAt(datetime, format) {
      if (!format) {
        format = 'LLL'
      }
      return this.$moment.utc(datetime).local().format(format);
    },
    startShowingTask() {
      this.$store.dispatch(TASK_SHOW_START, this.task)
      setTimeout(() => {
        this.$store.dispatch(TASK_SHOW_STOP)
      }, 3500);
    },
    openChat() {
      this.$showPanel({component: 'TaskThemes', openOn: 'right', width: 600, props: {'task': this.tuser.task}})
    },
	}
}
</script>

<style scoped>
.stage-wrp {
  display: flex;
  flex-direction: row;
}
.stage-wrp.mine {
  flex-direction: row-reverse;
}

.stage-wrp .actions {
  float: right;
  height: 1.8rem;
}
.stage-wrp .notification.btn {
  display: inline-flex;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 3rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #FFF;
  border-radius: 0;
  background-color: rgba(0,0,0,0.1);
  border-color: transparent;
}

.stage-wrp .notification.btn.btn-destruct {
  background-color: rgba(255, 152, 152, 0.397);
  color: #da4b4b;
}
.stage-wrp .notification.btn.btn-primary {
  color: #ff9900;
  background-color: #EEE;
}

.stage-wrp .notification.btn.who-read {
  width: auto;
  color: #777;
  padding: 0 .25rem;
  font-size: .8rem;
  background-color: transparent;
}
.stage-wrp .notification.btn.who-read:hover {
  color: #000;
  box-shadow: none;
}

.stage {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin-bottom: 1rem;
  font-size: .9rem;
}
.stage .footer {
  background-color: rgba(0,0,0,0.03);
  font-size: .9em;
  padding: .2em .5em;
}
.stage.mine {
  align-items: flex-end;
}

.stage .content {
  position: relative;
  background-color: #F8F7F7;
  border-radius: .5em;
  width: 100%;
}
.stage .content.content-80 {
  width: 80%;
}
.stage:not(.mine) .content {
  border-top-left-radius: 0;
}
.stage.mine .content {
  border-top-right-radius: 0;
}

.stage .header .title {
  align-self: flex-start;
  background-color: #ffe5bd;
  font-size: .8em;
  padding: .25em .5em .25em 0;
  border-radius: .25em;
}

.stage.failed .content {
  background-color: #FFECEC;
}
.stage.failed .header .title {
  background-color: #F9D1D1;
}

.stage.waiting .content {
  background-color: #FCFFC8;
}
.stage.waiting .header .title {
  background-color: #E9ECA6;
}

.stage.done .content {
  background-color: #E7F9E9;
}
.stage.done .header .title {
  background-color: #CFECD2;
}

.stage .progress > span {
  font-weight: 700;
  font-size: .7em;
}
.stage .progress > span.up {
  color: green;
  vertical-align: top;
}
.stage .progress > span.down {
  color: red;
}

.stage .body i {
  display: inline-block;
  width: 1.5em;
  font-size: 1.2em;
  vertical-align: top;
}

.stage .body .content-after-icon {
  display: inline-block;
  padding-left: 0.25em;
}

.stage .body .nl2br {
  display: flex;
  flex-direction: row;
}

.stage .calendar {
  color: #ff9900;
}

.stage .user.btn {
  background-color: transparent;
  position: relative;
  padding: 0em .5em;
  margin-right: 0;
  border: none;
}

.stage .body {
  font-size: .85em;
}

.stage .file.btn {
  display: block;
}
.stage .file.btn .upload-at {
  display: block;
}

.stage .file-wrapper {
  border: 1px solid rgba(0,0,0,.1);
  padding: .25em;
}

.read-unread-title {
  display: block;
  background-color: #EEE;
  color: #888;
  font-size: .8em;
  padding: .2em 1em;
  text-align: left;
}

.read-unread-title.read {
  background-color: #CFECD2;
  color: #00af0f;
}
.read-unread-title.unread {
  background-color: #F9D1D1;
  color: #da4b4b;
}
</style>