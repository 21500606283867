<template>
  <modal name="PopupEditGallery" @before-open="beforeOpen" @before-close="beforeClose" draggable=".popup-header" :adaptive="true" :scrollable="true" height="auto">
    <div class="popup-header flex-row">
      <span class="flex-expand padding-half">
        <span v-if="gallery.id">Изменение галереи</span>
        <span v-else>Создать галерею</span>
      </span>
      <button class="btn" @click="$modal.hide($options.name)"><i class="fal fa-times"></i></button>
    </div>
    <form class="pure-g content with-overflow padding-h padding-half-v" @submit.prevent="onSubmit">
      <vue-element-loading :active="isLoading"/>
      <div class="pure-u-16-24 padding-r form-row">
        <label>Название галереи</label>
        <input v-model="gallery.name" type="text" placeholder="Название галереи"/>
      </div>
      <div class="pure-u-8-24 form-row">
        <label>Дата</label>
        <v-date-picker v-model="gallery.date">
          <template v-slot="{ inputValue, inputEvents }">
            <input type="text" placeholder="Не задана" :value="inputValue" v-on="inputEvents"/>
          </template>
        </v-date-picker>
      </div>
      <div class="pure-u-1 form-row">
        <label>Описание галереи</label>
        <textarea v-model="gallery.description" rows="5" placeholder="Описание галереи"></textarea>
      </div>
      <div class="pure-u-1 txt-r form-row">
        <button type="submit" class="btn btn-primary"><i class="fal fa-check"></i> Сохранить</button>
      </div>
    </form>
  </modal>
</template>

<style scoped>

</style>

<script>
import { mapGetters } from "vuex";
import { GALLERY_CREATED } from "@/store/actions/project"
import VueElementLoading from 'vue-element-loading'
import apiCall from "@/utils/api"


export default {
  name: 'PopupEditGallery',
  components: {
    VueElementLoading,
  },
  data() {
    return {
      isLoading: false,
      gallery: {
        projectId: 0,
        date: "",
        name: "",
        description: "",
      }
    }
  },
  computed: {
    ...mapGetters(["activeProject"])
  },
	methods: {
    beforeClose(event) {
      if (this.$escStack.length && this.$escStack[this.$escStack.length-1] == this.$options.name) {
        this.$escStack.pop()
      } else {
        event.cancel()
      }
    },
    beforeOpen: function(data) {
      this.gallery = data.params

      this.$escStack.push(this.$options.name)
    },
		onSubmit: function () {
      this.isLoading = true
      if (this.gallery.name == "") {
        this.gallery.name = this.activeProject.name
      }
      let action = "projects/" + this.gallery.projectId + "/galleries/edit"
      apiCall({ url: action, method: "POST", data: this.gallery})
      .then(() => {
        this.$modal.hide(this.$options.name)
        this.isLoading = false

        this.$store.dispatch(GALLERY_CREATED)
      })
		}
	}
}
</script>