<template>
	<div class="stage-uploader">

    <div class="pure-g upload-title" v-bind:style="{'background-color': background, 'color': foreground}">
      <div class="pure-u-18-24 padding-h block-vcenter title"><b>{{ title }}</b></div>
      <file-upload
        v-if="uploadUrl"
        class="btn pure-u-6-24"
        name="file"
        :post-action="uploadUrl"
        :multiple="multiple"
        :drop="true"
        :thread="3"
        :headers="headers"
        :input-id="inputName"
        :accept="accept"
        v-model="files"
        ref="upload"
        @input-filter="inputFile">
        <i class="fad fa-upload"></i> Загрузить
      </file-upload>
    </div>
    <!-- Файлы которые ранее уже были загружены (старые файлы) -->
    <template v-for="file in filesBefore">
      <div class="pure-g file uploaded" :key="file.id + 'before'">
        <div class="pure-u-24-24 padding-half-l">
          <file-entry :file="file" />
        </div>
      </div>
    </template>
    <!-- Файлы которые добавляем уже в этот раз -->
    <template v-for="file in files">
      <div class="pure-g file uploading" v-if="!file.success" :key="file.id + 'uploading'">
        <div class="pure-u-20-24 name">
          {{file.name}}
          <span class="upload-at"><i class="fal fa-clock"></i> Загрузка... ({{Math.floor(file.progress)}}%)</span>
        </div>
        <div class="pure-u-4-24 progress block-vcenter">
          <div class="progressbar">
            <div class="bar" v-bind:style="{'width': + file.progress + '%'}"></div>
          </div>
          
        </div>
      </div>

      <div class="pure-g file uploaded" v-else :key="file.id + 'uploaded'">
        <div class="pure-u-24-24 padding-half-l">
          <file-entry :file="file.response" />
        </div>
      </div>
    </template>
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <i class="fal fa-upload"></i>
      <h4>Перетащите файл для загрузки</h4>
    </div>

		<button type="button" class="hidden btn btn-success" v-if="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true">
      <i class="fa fa-arrow-up" aria-hidden="true"></i>
      Start Upload
    </button>
	</div>
</template>


<script>
import FileUpload from 'vue-upload-component'
import FileEntry from '@/components/FileEntry.vue'
//import DoubleClickEditText from '@/components/DoubleClickEditText.vue'

export default {
	name: 'StageFilesUploader',
	components: {
		FileUpload,
    FileEntry,
    //DoubleClickEditText
	},
	props: ['value', 'accept', 'uploadUrl', 'headers', 'title', 'background', 'foreground', 'inputName', 'multiple'],
	data() {
		return {
      filesBefore: this.value,
			files: [],
      interval: null,
      lastTimesUpdatedAt: null,
      // filesDenied: [],
      // filesConfirmed: []
		}
	},
  watch: {
		value: function(newVal) {
      this.filesBefore = newVal
      this.sendFilesUpdated()
		}
	},
  mounted() {
    this.interval = setInterval( () => {
      this.lastTimesUpdatedAt = Date();
    }, 30000)
    this.sendFilesUpdated()
  },
  destroyed() {
    clearInterval(this.interval)
  },
	methods: {
    startUpload(file) {
      this.$refs.upload.update(file, {active: true})
      this.$emit('isUploading', true)
    },
    getExtension(url) {
      return '.' + url.split('.').pop()
    },
    uploadedAt(datetime) {
      return this.$moment.utc(datetime).local().format('LLL');
    },
    uploadedAtAgo(datetime) {
      // lastUpdated - просто передается заменяющая каждую секунду стркоа чтобы функция пересчиталась и стала реактивной
      return this.$moment.utc(datetime).local().calendar().toLowerCase()
    },
    inputFile(newFile, oldFile/*, prevent*/) {
      if (newFile && !oldFile) { // file added
        if (!this.multiple) {
          // remove all selections if only single file allowed
          // this.filesConfirmed.splice(0)
          // this.filesDenied.splice(0)
          this.filesBefore.splice(0)
        }
        // start new upload
        this.$nextTick(() => this.startUpload(newFile))
      }

      if (newFile && oldFile) {  // file updated
        if (newFile.success !== oldFile.success) {  // Uploaded successfully
          this.$nextTick(() => {
            // this.confirmFile(newFile.response.id)
            this.$emit('isUploading', this.files.filter(f => !f.success).length > 0)
            this.sendFilesUpdated()
          })
        }
      }
    },
    // confirmFile(id) {
    //   if (this.filesConfirmed.indexOf(id) !== -1) {
    //     return
    //   }
    //   this.filesConfirmed.push(id)
    //   let toRemoveIndex = this.filesDenied.findIndex(i => i == id)
    //   if (toRemoveIndex !== -1) {
    //     this.filesDenied.splice(toRemoveIndex, 1)
    //   }
    //   this.sendFilesUpdated()
    // },
    // denyFile(id) {
    //   if (this.filesDenied.indexOf(id) !== -1) {
    //     return
    //   }
    //   this.filesDenied.push(id)
    //   let toRemoveIndex = this.filesConfirmed.findIndex(i => i == id)
    //   if (toRemoveIndex !== -1) {
    //     this.filesConfirmed.splice(toRemoveIndex, 1)
    //   }
    //   this.sendFilesUpdated()
    // },
    // confirmAll() {
    //   this.filesDenied.splice(0, this.filesDenied.length)
    //   this.filesConfirmed.splice(0, this.filesConfirmed.length)
    //   this.filesBefore.map(f => this.filesConfirmed.push(f.id))
    //   this.files.filter(f => f.success).map(f => this.filesConfirmed.push(f.response.id))
    //   this.sendFilesUpdated()
    // },
    // denyAll() {
    //   this.filesDenied.splice(0, this.filesDenied.length)
    //   this.filesConfirmed.splice(0, this.filesConfirmed.length)
    //   this.filesBefore.map(f => this.filesDenied.push(f.id))
    //   this.files.filter(f => f.success).map(f => this.filesDenied.push(f.response.id))
    //   this.sendFilesUpdated()
    // },
    sendFilesUpdated() {
      this.$emit("input", 
        this.filesBefore.concat(
          this.files.filter(f => f.success)
            .map(f => f.response)
        )
        //.filter(f => this.filesDenied.findIndex(id => id == f.id) === -1)
      )

      this.$emit("filesChanged", {
        allChecked: true //this.files.filter(f => f.success).length + this.filesBefore.length
        // allChecked: this.filesDenied.length + this.filesConfirmed.length == this.files.length + this.filesBefore.length
      })
    }
	}
}
</script>

<style scoped>
.stage-uploader .drop-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0;

  background-color: #EFF7FE;
  border: 2px dashed #97C1F5;
  color: #97C1F5;
}
.stage-uploader .drop-active h4 {
  margin: 1em;
  font-weight: 500;
}

.upload-title {
  background-color: #FAFAFA;
  min-height: 1.6rem;
  line-height: 1.6rem;
}
.upload-title .title {
  font-size: .8em;
}

.upload-title .actions.btn.confirm {
  color: #C5EDCE;
}
.upload-title .actions.btn.deny {
  color: #ffc0c0;
}

.upload-title .btn {
  cursor: pointer;
  padding: .25em .75em;
  border-radius: 0;
  border: none;
  background-color: rgba(0,0,0,.1);
  color: #FFF;
}

.file .name {
  padding: .5rem;
  font-size: .85rem;
}
.file .name .upload-at {
  display: block;
  font-size: .7rem;
}

.actions > span {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: .8rem;
  border-radius: 50%;
  cursor: pointer;
  opacity: .1;
  transition: all .2s ease-in-out;
}
.actions.confirm > span {
  color: #509E2F;
  background-color: #C5EDCE;
}
.actions.deny > span {
  color: #CD0020;
  background-color: #F6C9D0;
}

.actions > span:hover {
  opacity: .5;
}
.actions > span.active {
  opacity: 1;
}

.btn.btn-transparent i {
  color: #000;
  font-size: 1em;
}
</style>