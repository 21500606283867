export const PROJECT_REQUEST = "PROJECT_REQUEST"
export const PROJECT_SUCCESS = "PROJECT_SUCCESS"
export const PROJECT_ERROR = "PROJECT_ERROR"

export const GROUPS_REQUEST = "GROUPS_REQUEST"
export const GROUPS_SUCCESS = "GROUPS_SUCCESS"
export const GROUPS_ERROR = "GROUPS_ERROR"

export const FILTERS_UPDATED = "FILTERS_UPDATED"

export const LOCAL_TASK_ADDED = "LOCAL_TASK_ADDED"
export const TASK_ADDED = "TASK_ADDED"
export const TASK_UPDATED = "TASK_UPDATED"

export const START_SELECTING_UNASSIGNED = "START_SELECTING_UNASSIGNED"
export const DONE_SELECTING_UNASSIGNED = "DONE_SELECTING_UNASSIGNED"
export const START_LINKING_TASK = "START_LINKING_TASK"
export const DONE_LINKING_TASK = "DONE_LINKING_TASK"
export const START_LINKING_MULTIPLE = "START_LINKING_MULTIPLE"
export const STOP_LINKING_MULTIPLE = "STOP_LINKING_MULTIPLE"
export const STOP_SELECTING = "STOP_SELECTING"

export const SUBSCRIBE_TASK = "SUBSCRIBE_TASK"
export const UNSUBSCRIBE_TASK = "UNSUBSCRIBE_TASK"

export const TASK_CHILDREN_OPEN = "TASK_CHILDREN_OPEN"
export const TASK_CHILDREN_CLOSE = "TASK_CHILDREN_CLOSE"

export const TASK_SHOW_START = "TASK_SHOW_START"
export const TASK_SHOW_STOP = "TASK_SHOW_STOP"

export const TASK_EDIT_NAME_START = "TASK_EDIT_NAME_START"

export const TASK_PENDING_DELETED = "TASK_PENDING_DELETED"

export const CELLS_SELECTION_START = "CELLS_SELECTION_START"
export const CELLS_SELECTION_STOP = "CELLS_SELECTION_STOP"
export const CELLS_SELECTION_ADD = "CELLS_SELECTION_ADD"
export const CELLS_SELECTION_DEL = "CELLS_SELECTION_DEL"

export const GALLERY_CREATED = "GALLERY_CREATED"

export const FORCE_SHOW_ASSIGNEE = "FORCE_SHOW_ASSIGNEE"
export const FORCE_SHOW_DEADLINE = "FORCE_SHOW_DEADLINE"